import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Row, Col, Card, Badge, Modal } from "react-bootstrap";
import Layout from "../Layout/Layout.js";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import swal from "sweetalert";
import baseURL from "../Config/configFile.js";
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'antd';

function AddStaffPage() {
  /* const search = useLocation().search;
  const shop = new URLSearchParams(search).get('shop');
  const code = new URLSearchParams(search).get('code'); */
  //const { shop,code } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [category, setcategory] = useState("All Staff");
  const [showTime, setshowTime] = useState(0);
  const [initialTime, setinitialTime] = useState("");
  const [count, setCount] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [role, setrole] = useState("2");
  const [firstNameEdit, setfirstNameEdit] = useState("");
  const [lastNameEdit, setlastNameEdit] = useState("");
  const [mobileEdit, setmobileEdit] = useState("");
  const [emailEdit, setemailEdit] = useState("");
  const [isDisabled, setisDisabled] = useState(false);

  const [passwordEdit, setpasswordEdit] = useState("");
  const [roleEdit, setroleEdit] = useState("2");
  const [shop, setshop] = useState(localStorage.getItem("shop"));
  const [code, setcode] = useState(localStorage.getItem("code"));
  const [searchInput, setSearchInput] = useState("1");
  const [filteredResults, setFilteredResults] = useState([]);
  const [statechange, setstatechange] = useState(false);
  const [viewStorage,setViewStorage]=useState(['Global Search', 'PickList Generation'])
  const [viewEditStorage,setViewEditStorage]=useState([])

  const onChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
    setViewStorage(checkedValues)
  };
  const plainOptions = ['Global Search', 'PickList Generation','Staff Tab','OrderScan Tab','VideoList Tab','Setting Tab','Assign and Unassign feature'];

  function searchItems(searchValue) {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = count.filter((item) => {
        return Object.values(item).join("").toLowerCase().includes(searchValue);
      });
      console.log(filteredData);

      setFilteredResults(filteredData);
    } else {
      setFilteredResults(count);
    }
  }

  setInterval(() => {
    var d = new Date();
    let minutes = d.getMinutes();
    setshowTime(parseInt(minutes) - parseInt(initialTime));
  }, 1000 * 60);
  useEffect(() => {
    const d = new Date();
    setinitialTime(d.getMinutes());
    setrefresh(false);

    axios
      .post(`${baseURL}/getStaffMember`, {
        shopName: localStorage.getItem("shop"),
        category: category,
      })
      .then((res) => {
        console.log("Response data", res.data);
        setCount(res.data.data);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    setrefresh(false);

    axios
      .post(`${baseURL}/getStaffMember`, {
        shopName: localStorage.getItem("shop"),
        category: category,
      })
      .then((res) => {
        console.log("Response data", res.data);
        setCount(res.data.data);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [category]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditClose = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);
  const handleEnableDisableButton = ()=>{
    swal({
      title: "Are you sure?",
      text: isDisabled==false?"If you disable a staff then all assigned order will get unassigned!":"You are going to enable staff account",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
    setShow(false);
    setrefresh(false);
    axios
      .post(`${baseURL}/enableDisableStaff`, {
        email: emailEdit,
      })
      .then((res) => {
        console.log("Response data", res.data);
        setrefresh(true);
        if (res.data.statusCode == 200) {
          swal({
            title: "Good job!",
            text: "You have enabled/disabled a member",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        setrefresh(true);
        console.log(err);
        swal({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          button: "Done",
        });
      });
    }
  })

  }
  const handleApply = () => {
    setShow(false);
    setrefresh(false);
    setTimeout(() => {
      setrefresh(true);
    }, 500);
  };
  const clickOnRefresh = () => {
    setrefresh(false);
    axios
      .post(`${baseURL}/getStaffMember`, {
        shopName: localStorage.getItem("shop"),
        category: category,
      })
      .then((res) => {
        console.log("Response data", res.data);
        setCount(res.data.data);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clickedOnEditOption = (
    firstName,
    lastName,
    mobile,
    email,
    password,
    is_account_disabled,
    access_type,
    view
  ) => {
    setfirstNameEdit(firstName);
    setlastNameEdit(lastName);
    setmobileEdit(mobile);
    setemailEdit(email);
    setpasswordEdit(password);
    setisDisabled(is_account_disabled)
    setroleEdit(access_type)
    setViewEditStorage(view)
    console.log("edit", firstNameEdit);
  };
  const handleFirstNameChange = (event) => {
    setfirstName(event.target.value);
  };
  const handleCategoryChange = (index) => {
    setcategory(index);
  };
  const handleLastNameChange = (event) => {
    setlastName(event.target.value);
  };
  const handleMobileChange = (event) => {
    setmobile(event.target.value);
  };
  const handleEmailChange = (event) => {
    setemail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setpassword(event.target.value);
  };
  const handleRoleChange = (event) => {
    setrole(event.target.value);
  };
  const handleFirstNameEditChange = (event) => {
    setfirstNameEdit(event.target.value);
  };
  const handleLastNameEditChange = (event) => {
    setlastNameEdit(event.target.value);
  };
  const handleMobileEditChange = (event) => {
    setmobileEdit(event.target.value);
  };
  const handleEmailEditChange = (event) => {
    setemailEdit(event.target.value);
  };
  const handlePasswordEditChange = (event) => {
    setpasswordEdit(event.target.value);
  };
  const handleRoleEditChange = (event) => {
    setroleEdit(event.target.value);
  };
  const AddStaffMember = (event) => {
    event.preventDefault();
    setShow(false);
    setrefresh(false);
    axios
      .post(`${baseURL}/addStaffMember`, {
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        email: email,
        password: password,
        role: role,
        view:viewStorage,
        shopName: shop,
        code: code,
      })
      .then((res) => {
        console.log("Response data", res.data);
        setrefresh(true);
        if (res.data.statusCode == 200) {
          swal({
            title: "Good job!",
            text: "You have added a member",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        setrefresh(true);
        console.log(err);
        swal({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          button: "Done",
        });
      });
  };
  const DeleteStaffMember = (email) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setShow(false);
        setrefresh(false);
        axios
          .post(`${baseURL}/deleteStaffMember`, {
            email: email,
          })
          .then((res) => {
            console.log("Response data", res.data);
            setrefresh(true);
            if (res.data.statusCode == 200) {
              swal({
                title: "Deleted!",
                text: "You have deleted a member",
                icon: "success",
                button: "Done",
              });
              clickOnRefresh();
            } else {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "error",
                button: "Done",
              });
              clickOnRefresh();
            }
          })
          .catch((err) => {
            setrefresh(true);
            console.log(err);
            swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              button: "Done",
            });
          });
      }
    });
  };
  const SubmitEditStaffMember = (event) => {
    event.preventDefault();
    setShowEdit(false);
    setrefresh(false);
    axios
      .post(`${baseURL}/EditStaffMember`, {
        firstName: firstNameEdit,
        lastName: lastNameEdit,
        mobile: mobileEdit,
        email: emailEdit,
        password: passwordEdit,
        role: roleEdit,
        view:viewStorage,
        shopName: shop,
      })
      .then((res) => {
        console.log("Response data", res.data);
        setrefresh(true);
        if (res.data.statusCode == 200) {
          swal({
            title: "Details Saved",
            text: "Staff details have been modified successfully.",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        setrefresh(true);
        console.log(err);
        swal({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          button: "Done",
        });
      });
  };
  console.log(
    "Name",
    localStorage.getItem("shop"),
    localStorage.getItem("code")
  );
  useEffect(()=>{
    axios
      .post(`${baseURL}/verfyToken`, {
       token:localStorage.getItem("token")
      })
      .then((res) => {
        if(res.data.statusCode==200)
        {
        }
        else
        {
          navigate(`/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[])
  return (
    <div className="App">
      <Layout status="3">
        {refresh ? (
          <div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Staff Member</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <Form onSubmit={AddStaffMember}>
                    <div className="d-flex justify-content-start">
                      <Form.Group className="mb-3" controlId="formBasicEmail1">
                        <Form.Label className="text-center fw-bold">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          required
                          value={firstName}
                          onChange={handleFirstNameChange}
                        />
                      </Form.Group>
                      <Form.Group
                        className=" mx-2 mb-3"
                        controlId="formBasicEmail2"
                      >
                        <Form.Label className="text-center fw-bold">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          required
                          value={lastName}
                          onChange={handleLastNameChange}
                        />
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                      <Form.Label className="text-center fw-bold">
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Mobile No."
                        required
                        value={mobile}
                        onChange={handleMobileChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail5">
                      <Form.Label className="text-center fw-bold">
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="EMail"
                        required
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail6">
                      <Form.Label className="text-center fw-bold">
                        Set Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Set Password"
                        required
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </Form.Group>
                    <Form.Label className="text-center fw-bold">
                      Select Member Role
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      onChange={handleRoleChange}
                    >
                      <option value="2">PICKER</option>
                      <option value="3">PACKER</option>
                      <option value="1">MANAGER</option>
                      <option value="0">ADMIN</option>
                    </Form.Select>
                    <br/>
                    <Form.Label className="text-center fw-bold">
                      Below section will be visible to this user
                    </Form.Label>
                    <Checkbox.Group options={plainOptions} defaultValue={['Global Search', 'PickList Generation', 'Order Assign']} onChange={onChange} />

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      {/* <p className="small">
                          <a className="text-primary" href="#!">
                            Forgot password?
                          </a>
                        </p> */}
                    </Form.Group>
                    {/* <div className="d-grid">
                        <Button variant="dark" type="submit">
                          Add Member
                        </Button>
                      </div> */}
                    <Button variant="secondary" onClick={handleClose}>
                      Discard
                    </Button>
                    <input
                      className="btn btn-primary mx-2"
                      value="Add Member"
                      type="submit"
                    />
                  </Form>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
            <Modal show={showEdit} onHide={handleEditClose}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Staff Member</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <Form onSubmit={SubmitEditStaffMember}>
                    <div className="d-flex justify-content-start">
                      <Form.Group className="mb-3" controlId="formBasicEmail1">
                        <Form.Label className="text-center fw-bold">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          required
                          value={firstNameEdit}
                          onChange={handleFirstNameEditChange}
                        />
                      </Form.Group>
                      <Form.Group
                        className=" mx-2 mb-3"
                        controlId="formBasicEmail2"
                      >
                        <Form.Label className="text-center fw-bold">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          required
                          value={lastNameEdit}
                          onChange={handleLastNameEditChange}
                        />
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                      <Form.Label className="text-center fw-bold">
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Mobile No."
                        required
                        value={mobileEdit}
                        onChange={handleMobileEditChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail5">
                      <Form.Label className="text-center fw-bold">
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="EMail"
                        required
                        value={emailEdit}
                        onChange={handleEmailEditChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail6">
                      <Form.Label className="text-center fw-bold">
                        Set Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Set Password"
                        required
                        value={passwordEdit}
                        onChange={handlePasswordEditChange}
                      />
                    </Form.Group>
                    <Form.Label className="text-center fw-bold">
                      Select Member Role
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      onChange={handleRoleEditChange}
                      defaultValue={roleEdit}
                    >
                      <option value="2">PICKER</option>
                      <option value="3">PACKER</option>
                      <option value="1">MANAGER</option>
                      <option value="0">ADMIN</option>
                    </Form.Select>
                    <br/>
                    <Form.Label className="text-center fw-bold">
                      Below section will be visible to this user
                    </Form.Label>
                    <Checkbox.Group options={plainOptions} defaultValue={viewEditStorage} onChange={onChange} />

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      {/* <p className="small">
                          <a className="text-primary" href="#!">
                            Forgot password?
                          </a>
                        </p> */}
                    </Form.Group>
                    {/* <div className="d-grid">
                        <Button variant="dark" type="submit">
                          Add Member
                        </Button>
                      </div> */}
                    <Button variant="secondary" onClick={handleClose}>
                      Discard
                    </Button>
                    <input
                      className="btn btn-primary mx-2"
                      value="Save"
                      type="submit"
                    />
                  </Form>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
            <hr />
            <Row>
              <Col lg={10}>
                <div>
                  <Row>
                    <div className="d-flex justify-content-start">
                      <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        fill="currentColor"
                        className="bi bi-person-fill-add text-dark"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                      </svg>
                      <div className="mx-1">
                        <Row>
                          <Col>My Staff</Col>
                        </Row>
                        <Row>
                          <Col>
                            <SplitButton
                              as={ButtonGroup}
                              key="1"
                              id={`dropdown-button-drop`}
                              size="sm"
                              variant="secondary"
                              title={category}
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={() => handleCategoryChange("All Staff")}
                              >
                                All Staff
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => handleCategoryChange("Manager")}
                              >
                                Manager
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={() => handleCategoryChange("Admin")}
                              >
                                Admin
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => handleCategoryChange("Picker")}
                              >
                                Picker
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => handleCategoryChange("Packer")}
                              >
                                Packer
                              </Dropdown.Item>
                            </SplitButton>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              {/* <Col>
                <div className="my-2">
                  <Button variant="secondary" size="sm" className="mx-1">
                    Sync Products
                  </Button>
                  <Button variant="secondary" size="sm">
                    Export
                  </Button>
                </div>
              </Col> */}
            </Row>
            <Row className="my-2">
              <Col lg={9}>
                <div className="h6" style={{ fontSize: "15px" }}>
                  {count.length} People. Updated {showTime>=2?showTime+" Minutes ago.":"a few seconds ago"} 
                </div>
              </Col>
              <Col>
                <div className="d-flex justify-content-start">
                  <Form className="mx-1">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                      onChange={(e) => searchItems(e.target.value)}
                    />
                  </Form>
                  <Button
                    onClick={clickOnRefresh}
                    variant="light"
                    size="sm"
                    className="mx-1"
                  >
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      fill="currentColor"
                      className="bi bi-arrow-repeat"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                      <path
                        fillRule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                      />
                    </svg>
                  </Button>
                  <Button onClick={clickOnRefresh} variant="light" size="sm">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      fill="currentColor"
                      className="bi bi-gear-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                    </svg>
                  </Button>
                  <Button
                    onClick={clickOnRefresh}
                    variant="light"
                    size="sm"
                    className="mx-1"
                  >
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      fill="currentColor"
                      className="bi bi-filter-square-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm.5 5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1zM4 8.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm2 3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </Button>
                </div>
              </Col>
            </Row>
            <hr />
            <div className="d-flex flex-wrap">
            {searchInput.length > 1? filteredResults.length == 0
                      ? "No data found"
                      : filteredResults.map((item) => {
                return (
                  <div>
                    {item.access_type == 1 ? (
                      <Card
                        style={{
                          width: "18rem",
                          margin: "5px",
                          backgroundColor: "#FFD9D9",
                        }}
                      >
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Subtitle className="mb-2 fs-8 text-muted">
                              MANAGER
                            </Card.Subtitle>
                            {item.is_online == 1 ? (
                              <Badge bg="success">Working Today</Badge>
                            ) : (
                              <Badge bg="danger">On Leave</Badge>
                            )}
                            
                            <DropdownButton
                              key="end"
                              id={`dropdown-button-drop-end-1`}
                              drop="end"
                              variant="secondary"
                              size="small"
                              style={{ height: "10px", width: "17px" }}
                              onClick={() =>
                                clickedOnEditOption(
                                  item.first_name,
                                  item.last_name,
                                  item.mobile_number,
                                  item.email,
                                  item.password,
                                  item.is_account_disabled,
                                  item.access_type,
                                  item.view
                                  
                                )
                              }
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={handleEditShow}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={handleEnableDisableButton}
                              >
                                {isDisabled==true?"Enable Account":"Disable Account"}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => DeleteStaffMember(item.email)}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <Card.Title className="fs-3">
                            {item.first_name} {item.last_name}
                          </Card.Title>
                          <Card.Title className="fs-6">
                            {" "}
                            +91-{item.mobile_number}
                          </Card.Title>

                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-inbox text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                            </svg>
                            <p className="mx-2">{item.total_order} orders assigned</p>
                          </div>
                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <p className="mx-2">63 orders picked</p>
                          </div>
                          <br/>
                            {item.is_account_disabled == false ? (
                              <Badge bg="success">Accound Active</Badge>
                            ) : (
                              <Badge bg="danger">Accound disabled</Badge>
                            )}
                          
                          
                        </Card.Body>
                      </Card>
                    ) : (
                      ""
                    )}
                    {item.access_type == 0 ? (
                      <Card
                        style={{
                          width: "18rem",
                          margin: "5px",
                          backgroundColor: "#EAECEE",
                        }}
                      >
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Subtitle className="mb-2 fs-8 text-muted">
                              ADMIN
                            </Card.Subtitle>
                            {item.is_online == 1 ? (
                              <Badge bg="success">Working Today</Badge>
                            ) : (
                              <Badge bg="danger">On Leave</Badge>
                            )}
                            
                            <DropdownButton
                              key="end"
                              id={`dropdown-button-drop-end-1`}
                              drop="end"
                              variant="secondary"
                              size="small"
                              style={{ height: "10px", width: "17px" }}
                              onClick={() =>
                                clickedOnEditOption(
                                  item.first_name,
                                  item.last_name,
                                  item.mobile_number,
                                  item.email,
                                  item.password,
item.is_account_disabled,
item.access_type,item.view                                  )
                              }
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={handleEditShow}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={handleEnableDisableButton}
                              >
                                {isDisabled==true?"Enable Account":"Disable Account"}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => DeleteStaffMember(item.email)}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <Card.Title className="fs-3">
                            {item.first_name} {item.last_name}
                          </Card.Title>
                          <Card.Title className="fs-6">
                            {" "}
                            +91-{item.mobile_number}
                          </Card.Title>

                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-inbox text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                            </svg>
                            <p className="mx-2">{item.total_order} orders assigned</p>
                          </div>
                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <p className="mx-2">63 orders picked</p>
                          </div>
                          <br/>
                            {item.is_account_disabled == false ? (
                              <Badge bg="success">Accound Active</Badge>
                            ) : (
                              <Badge bg="danger">Accound disabled</Badge>
                            )}
                          
                          
                        </Card.Body>
                      </Card>
                    ) : (
                      ""
                    )}
                    {item.access_type == 2 ? (
                      <Card style={{ width: "18rem", margin: "5px" }}>
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Subtitle className="mb-2 fs-8 text-muted">
                              PICKER
                            </Card.Subtitle>
                            {item.is_online == 1 ? (
                              <Badge bg="success">Working Today</Badge>
                            ) : (
                              <Badge bg="danger">On Leave</Badge>
                            )}
                            <DropdownButton
                              key="end"
                              id={`dropdown-button-drop-end-2`}
                              drop="end"
                              variant="secondary"
                              size="small"
                              style={{ height: "10px", width: "17px" }}
                              onClick={() =>
                                clickedOnEditOption(
                                  item.first_name,
                                  item.last_name,
                                  item.mobile_number,
                                  item.email,
                                  item.password,
item.is_account_disabled,
item.access_type,item.view                                  )
                              }
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={handleEditShow}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={handleEnableDisableButton}
                              >
                                {isDisabled==true?"Enable Account":"Disable Account"}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => DeleteStaffMember(item.email)}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <Card.Title className="fs-3">
                            {item.first_name} {item.last_name}
                          </Card.Title>
                          <Card.Title className="fs-6">
                            {" "}
                            +91-{item.mobile_number}
                          </Card.Title>

                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-inbox text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                            </svg>
                            <p className="mx-2">{item.total_order} orders assigned</p>
                          </div>
                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <p className="mx-2">63 orders picked</p>
                          </div>
                          <br/>
                            {item.is_account_disabled == false ? (
                              <Badge bg="success">Accound Active</Badge>
                            ) : (
                              <Badge bg="danger">Accound disabled</Badge>
                            )}
                          
                          
                        </Card.Body>
                      </Card>
                    ) : (
                      ""
                    )}

                    {item.access_type == 3 ? (
                      <Card
                        style={{
                          width: "18rem",
                          margin: "5px",
                          backgroundColor: "#D5F5E3",
                        }}
                      >
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Subtitle className="mb-2 fs-8 text-muted">
                              PACKER
                            </Card.Subtitle>
                            {item.is_online == 1 ? (
                              <Badge bg="success">Working Today</Badge>
                            ) : (
                              <Badge bg="danger">On Leave</Badge>
                            )}
                            
                            <DropdownButton
                              key="end"
                              id={`dropdown-button-drop-end-3`}
                              drop="end"
                              variant="secondary"
                              size="small"
                              style={{ height: "10px", width: "17px" }}
                              onClick={() =>
                                clickedOnEditOption(
                                  item.first_name,
                                  item.last_name,
                                  item.mobile_number,
                                  item.email,
                                  item.password,
item.is_account_disabled,
item.access_type,item.view                                  )
                              }
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={handleEditShow}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={handleEnableDisableButton}
                              >
                                {isDisabled==true?"Enable Account":"Disable Account"}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => DeleteStaffMember(item.email)}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <Card.Title className="fs-3">
                            {item.first_name} {item.last_name}
                          </Card.Title>
                          <Card.Title className="fs-6">
                            {" "}
                            +91-{item.mobile_number}
                          </Card.Title>

                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-inbox text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                            </svg>
                            <p className="mx-2">{item.total_order} orders assigned</p>
                          </div>
                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <p className="mx-2">63 orders picked</p>
                          </div>
                          <br/>
                            {item.is_account_disabled == false ? (
                              <Badge bg="success">Accound Active</Badge>
                            ) : (
                              <Badge bg="danger">Accound disabled</Badge>
                            )}
                          
                          
                        </Card.Body>
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }):count.map((item) => {
                return (
                  <div>
                    {item.access_type == 1 ? (
                      <Card
                        style={{
                          width: "18rem",
                          margin: "5px",
                          backgroundColor: "#FFD9D9",
                        }}
                      >
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Subtitle className="mb-2 fs-8 text-muted">
                              MANAGER
                            </Card.Subtitle>
                            {item.is_online == 1 ? (
                              <Badge bg="success">Working Today</Badge>
                            ) : (
                              <Badge bg="danger">On Leave</Badge>
                            )}
                            
                            <DropdownButton
                              key="end"
                              id={`dropdown-button-drop-end-1`}
                              drop="end"
                              variant="secondary"
                              size="small"
                              style={{ height: "10px", width: "17px" }}
                              onClick={() =>
                                clickedOnEditOption(
                                  item.first_name,
                                  item.last_name,
                                  item.mobile_number,
                                  item.email,
                                  item.password,
item.is_account_disabled,
item.access_type,item.view                                  )
                              }
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={handleEditShow}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={handleEnableDisableButton}
                              >
                                {isDisabled==true?"Enable Account":"Disable Account"}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => DeleteStaffMember(item.email)}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <Card.Title className="fs-3">
                            {item.first_name} {item.last_name}
                          </Card.Title>
                          <Card.Title className="fs-6">
                            {" "}
                            +91-{item.mobile_number}
                          </Card.Title>

                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-inbox text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                            </svg>
                            <p className="mx-2">{item.total_order} orders assigned</p>
                          </div>
                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <p className="mx-2">63 orders picked</p>
                          </div>
                          <br/>
                            {item.is_account_disabled == false ? (
                              <Badge bg="success">Accound Active</Badge>
                            ) : (
                              <Badge bg="danger">Accound disabled</Badge>
                            )}
                          
                          
                        </Card.Body>
                      </Card>
                    ) : (
                      ""
                    )}
                    {item.access_type == 0 ? (
                      <Card
                        style={{
                          width: "18rem",
                          margin: "5px",
                          backgroundColor: "#EAECEE",
                        }}
                      >
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Subtitle className="mb-2 fs-8 text-muted">
                              ADMIN
                            </Card.Subtitle>
                            {item.is_online == 1 ? (
                              <Badge bg="success">Working Today</Badge>
                            ) : (
                              <Badge bg="danger">On Leave</Badge>
                            )}
                            
                            <DropdownButton
                              key="end"
                              id={`dropdown-button-drop-end-1`}
                              drop="end"
                              variant="secondary"
                              size="small"
                              style={{ height: "10px", width: "17px" }}
                              onClick={() =>
                                clickedOnEditOption(
                                  item.first_name,
                                  item.last_name,
                                  item.mobile_number,
                                  item.email,
                                  item.password,
item.is_account_disabled,
item.access_type,item.view                                  )
                              }
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={handleEditShow}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={handleEnableDisableButton}
                              >
                                {isDisabled==true?"Enable Account":"Disable Account"}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => DeleteStaffMember(item.email)}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <Card.Title className="fs-3">
                            {item.first_name} {item.last_name}
                          </Card.Title>
                          <Card.Title className="fs-6">
                            {" "}
                            +91-{item.mobile_number}
                          </Card.Title>

                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-inbox text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                            </svg>
                            <p className="mx-2">{item.total_order} orders assigned</p>
                          </div>
                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <p className="mx-2">63 orders picked</p>
                          </div>
                          <br/>
                            {item.is_account_disabled == false ? (
                              <Badge bg="success">Accound Active</Badge>
                            ) : (
                              <Badge bg="danger">Accound disabled</Badge>
                            )}
                          
                          
                        </Card.Body>
                      </Card>
                    ) : (
                      ""
                    )}
                    {item.access_type == 2 ? (
                      <Card style={{ width: "18rem", margin: "5px" }}>
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Subtitle className="mb-2 fs-8 text-muted">
                              PICKER
                            </Card.Subtitle>
                            {item.is_online == 1 ? (
                              <Badge bg="success">Working Today</Badge>
                            ) : (
                              <Badge bg="danger">On Leave</Badge>
                            )}
                            
                            <DropdownButton
                              key="end"
                              id={`dropdown-button-drop-end-2`}
                              drop="end"
                              variant="secondary"
                              size="small"
                              style={{ height: "10px", width: "17px" }}
                              onClick={() =>
                                clickedOnEditOption(
                                  item.first_name,
                                  item.last_name,
                                  item.mobile_number,
                                  item.email,
                                  item.password,
item.is_account_disabled,
item.access_type,item.view                                  )
                              }
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={handleEditShow}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={handleEnableDisableButton}
                              >
                                {isDisabled==true?"Enable Account":"Disable Account"}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => DeleteStaffMember(item.email)}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <Card.Title className="fs-3">
                            {item.first_name} {item.last_name}
                          </Card.Title>
                          <Card.Title className="fs-6">
                            {" "}
                            +91-{item.mobile_number}
                          </Card.Title>

                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-inbox text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                            </svg>
                            <p className="mx-2">{item.total_order} orders assigned</p>
                          </div>
                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <p className="mx-2">63 orders picked</p>
                          </div>
                          <br/>
                            {item.is_account_disabled == false ? (
                              <Badge bg="success">Accound Active</Badge>
                            ) : (
                              <Badge bg="danger">Accound disabled</Badge>
                            )}
                          
                          
                        </Card.Body>
                      </Card>
                    ) : (
                      ""
                    )}

                    {item.access_type == 3 ? (
                      <Card
                        style={{
                          width: "18rem",
                          margin: "5px",
                          backgroundColor: "#D5F5E3",
                        }}
                      >
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <Card.Subtitle className="mb-2 fs-8 text-muted">
                              PACKER
                            </Card.Subtitle>
                            {item.is_online == 1 ? (
                              <Badge bg="success">Working Today</Badge>
                            ) : (
                              <Badge bg="danger">On Leave</Badge>
                            )}
                           
                            
                            <DropdownButton
                              key="end"
                              id={`dropdown-button-drop-end-3`}
                              drop="end"
                              variant="secondary"
                              size="small"
                              style={{ height: "10px", width: "17px" }}
                              onClick={() =>
                                clickedOnEditOption(
                                  item.first_name,
                                  item.last_name,
                                  item.mobile_number,
                                  item.email,
                                  item.password,
item.is_account_disabled,
item.access_type,item.view                                  )
                              }
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={handleEditShow}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                onClick={handleEnableDisableButton}
                              >
                                {isDisabled==true?"Enable Account":"Disable Account"}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => DeleteStaffMember(item.email)}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <Card.Title className="fs-3">
                            {item.first_name} {item.last_name}
                          </Card.Title>
                          <Card.Title className="fs-6">
                            {" "}
                            +91-{item.mobile_number}
                          </Card.Title>

                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-inbox text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                            </svg>
                            <p className="mx-2">{item.total_order} orders assigned</p>
                          </div>
                          <div className="d-flex justify-content-start my-0">
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <p className="mx-2">63 orders picked</p>
                          </div>
                          <br/>
                            {item.is_account_disabled == false ? (
                              <Badge bg="success">Accound Active</Badge>
                            ) : (
                              <Badge bg="danger">Accound disabled</Badge>
                            )}
                          
                          
                        </Card.Body>
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
              <Card
                style={{
                  width: "18rem",
                  margin: "5px",
                  backgroundColor: "#EAECEE",
                  cursor: "pointer",
                }}
                onClick={handleShow}
              >
                <Card.Body>
                  <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width="50%"
                    height="50%"
                    style={{ marginLeft: "25%", marginTop: "15%" }}
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                </Card.Body>
              </Card>
            </div>
          </div>
        ) : (
          <center>
            <div className="spinner-border my-5" role="status">
              <span className="sr-only"></span>
            </div>
          </center>
        )}
      </Layout>
    </div>
  );
}

export default AddStaffPage;
