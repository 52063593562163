import React from 'react';
import { useBarcode } from '@createnextapp/react-barcode';
import baseURL from "../Config/configFile.js";

function BarCmp(props) {
  const { inputRef } = useBarcode({
    value: props.code,
    options: {
      format: "CODE128",
      background: '#ffffff',
      displayValue:false,
      height:50,
    }
  });

  return <img ref={inputRef} />;
};

export default BarCmp;