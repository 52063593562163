import React, { useState,useEffect,useRef  }  from 'react';
import {useLocation,useParams} from "react-router-dom";
import { Button, Row,Col } from 'react-bootstrap';
import Layout from "../Layout/Layout.js"
import axios from "axios"
import Form from 'react-bootstrap/Form';
import swal from "sweetalert";
import {Modal } from 'antd';
import {Flex } from 'antd';
import baseURL from "../Config/configFile.js";
import { useNavigate } from 'react-router-dom';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import "./main.css"
import { Image as Image1, Space } from 'antd';



function RecordingDetails() {
  const imageRef = useRef(null);
  const navigate = useNavigate();
    const location = useLocation()
  const [orderDetails, setorderDetails] = useState([]);
  const [productDetails, setproductDetails] = useState([]);
  const [productID, setproductID] = useState("8053628436790");
  const [showAssignNew, setShowAssignNew] = useState(false);
  const [pickerPackerID, setpickerPackerID] = useState("");
  const staffArray1=JSON.parse(localStorage.getItem("staffArray"))
  const [orderExist, setorderExist] = useState(false);
  const [deleted, setdeleted] = useState(false);
  const [videoURL, setvideoURL] = useState([]);
  const [imageURL, setimageURL] = useState([]);
  const [imageURL1,setimageURL1]=useState("")
  const [imageTimeStamp,setimageTimeStamp]=useState("")
  const [imageStaffName,setimageStaffName]=useState("")
  const [count,setcount]=useState(0)
  const [totalCount,settotalCount]=useState(0)
  const [deleteButtonClicked,setdeleteButtonClicked]=useState(false)




  const [checkedOrderArray, setcheckedOrderArray] = useState([]);
  const [checkedAllOrderArray, setcheckedAllOrderArray] = useState([]);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (url,staffName,timeStamp) =>{
    setimageURL1(url)
    const img = new Image();
    img.crossOrigin = "anonymous"
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // Add watermark text
      ctx.font = "bold 40px Arial";
      ctx.fillStyle = 'rgba(255, 255, 255, 1)';
      ctx.fillText("Captured by- "+staffName, 20, canvas.height - 50);
      ctx.fillText("TimeStamp- "+timeStamp, canvas.width - (((canvas.width)/100)*30), canvas.height - 50);

      // Set watermarked image URL
      setimageURL1(canvas.toDataURL());
    };
setimageTimeStamp(timeStamp)
setimageStaffName(staffName)
   setShow(true);
  }
 
  const handleOrderCheckBox = (e, orderNumber) => {
    var newArr = [...checkedOrderArray];
    if (newArr.indexOf(orderNumber) != -1) {
      newArr.splice(newArr.indexOf(orderNumber), 1);
      setcheckedOrderArray(newArr);
    } else {
      newArr.push(orderNumber);
      setcheckedOrderArray(newArr);
    }
    console.log(checkedOrderArray);
  };
  const handleTickAll = () => {
    if (checkedAllOrderArray.length == 0) {
      var newArr = [];
      imageURL.forEach((item) => {
        newArr.push(item.order_number);
      });
      videoURL.forEach((item) => {
        newArr.push(item.order_number);
      });
      setcheckedOrderArray(newArr);
      setcheckedAllOrderArray(newArr);
      
    } else {
      setcheckedOrderArray([]);
      setcheckedAllOrderArray([]);
    }
  };

  const handleDownloadAll = async()=>{
if(checkedOrderArray.length!=0)
{
  for(var i=0;i<checkedOrderArray.length;i++)
  {
    console.log("array",checkedOrderArray[i])
    var name=checkedOrderArray[i]
   
     
      if(name.split(".")[0]=='vi')
      {
        const response = await axios({
          url: `${baseURL}/images/${checkedOrderArray[i]}`,
          method: "GET",
          responseType: "blob" // important
      })
    
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
        console.log("array",name)
      link.setAttribute(
          "download",name.split(".")[2]+"_"+
          new Date().toString()+'.mp4'
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      }
      else{
        const recordingResponceData=await axios.post(`${baseURL}/getRecordingDetailsWithFileName`,{fileName:checkedOrderArray[i]})
        if(recordingResponceData.data.isExist)
        {
        const img = new Image();
        img.crossOrigin = "anonymous"
          img.src = `${baseURL}/images/${checkedOrderArray[i]}`;
          var urlsend=""
          await img.decode();
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
      
            // Add watermark text
            ctx.font = "bold 40px Arial";
            ctx.fillStyle = 'rgba(255, 255, 255, 1)';
            ctx.fillText("By- "+recordingResponceData.data.recordingData.staffName, 20, canvas.height - 50);
            ctx.fillText("Time- "+recordingResponceData.data.recordingData.created_At, canvas.width - (((canvas.width)/100)*40), canvas.height - 50);
      
            // Set watermarked image URL
            
            const link = document.createElement("a");
            link.href = canvas.toDataURL();;
        link.setAttribute(
          "download",name.split(".")[2]+"_"+
          new Date().toString()+'.png'
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
        }
        else
        {
          console.log("Data Not Available")
        }
      }
     
  /* var tempLink = document.createElement('a');
  tempLink.href = `${baseURL}/images/${checkedOrderArray[i]}`
  tempLink.setAttribute('download',new Date().toString()+'.png');
  tempLink.click() */
  }
}
else
  {
    swal({
      title: "No item selected",
      text: "Please select atleast one item",
      icon: "warning",
      button: "Done",
    });
  }

  }
  const onDownload = (src) => {

    const link = document.createElement("a");
    link.href = src
link.setAttribute(
  "download",'Download.png'
);
document.body.appendChild(link);
link.click();

// Clean up and remove the link
link.parentNode.removeChild(link);
  };







  const [refresh, setrefresh] = useState(false);
  useEffect(()=>{
    console.log("checkedAllArray",checkedAllOrderArray," ",checkedOrderArray)
  },[checkedOrderArray])
  useEffect(()=>{
    if(location.state.id==""||location.state.id==" "||location.state.id=="  "||location.state.id=="   ")
    {
      setrefresh(true)
      setorderExist(false)
    }
    else{
    axios.post(`${baseURL}/checkOrderExist`,{orderId:location.state.id+"."+localStorage.getItem("shop")})
    .then(async(res)=>{
      
      if(res.data.isExist==true&&(res.data.video.length>0||res.data.image.length>0))
      {
        settotalCount(res.data.image.length)
        
        setvideoURL(res.data.video)
        //setimageURL(res.data.image)
        var imageArr=res.data.image
        var imageArrayMain=[]
        for(var i=0;i<imageArr.length;i++)
        {
          const img = new Image();
          img.crossOrigin = "anonymous"
          img.src = `${baseURL}/images/${imageArr[i].order_number}`;
          var urlsend=""
          await img.decode();
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
      
            // Add watermark text
            ctx.font = "bold 40px Arial";
            ctx.fillStyle = 'rgba(255, 255, 255, 1)';
            ctx.fillText("By- "+imageArr[i].staffName, 20, canvas.height - 50);
            ctx.fillText("Time- "+imageArr[i].created_At, canvas.width - (((canvas.width)/100)*40), canvas.height - 50);
      
            // Set watermarked image URL
            imageArr[i].completeURL=canvas.toDataURL();
            imageArrayMain.push(imageArr[i])
            setimageURL(imageArrayMain)
            setrefresh(true)
            setorderExist(res.data.isExist)
            setcount(i+1)

        }
        setorderExist(res.data.isExist)
        setrefresh(true)
         
        
      }
      else
      {
        setorderExist(false)

        setrefresh(false)
        setTimeout(()=>{
          setrefresh(true)

        },1000)

      }
    })
    .catch((error)=>{
      console.log("error",error)
    })
  }
  },[deleteButtonClicked])
  useEffect(()=>{
    console.log("called",count)

  },[count])
  const deleteS3Video= (item)=>{

    if(item.length!=0)
    {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if(willDelete)
      {
        setrefresh(false)
    var res=await axios.post(`${baseURL}/deleteS3Video`,{orderId:item,mainOrderId:imageURL.length==0?videoURL[0].mainOrderId:imageURL[0].mainOrderId})
      if(res.data.isDeleted==true)
      {
        setvideoURL([])
        var res1 = await axios.post(`${baseURL}/checkOrderExist`,{orderId:location.state.id+"."+localStorage.getItem("shop")})
      
      if(res1.data.isExist==true&&(res1.data.video.length>0||res1.data.image.length>0))
      {
        settotalCount(res1.data.image.length)
        
        setvideoURL(res1.data.video)
        //setimageURL(res.data.image)
        var imageArr=res1.data.image
        var imageArrayMain=[]
        for(var i=0;i<imageArr.length;i++)
        {
          const img = new Image();
          img.crossOrigin = "anonymous"
          img.src = `${baseURL}/images/${imageArr[i].order_number}`;
          var urlsend=""
          await img.decode();
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
      
            // Add watermark text
            ctx.font = "bold 40px Arial";
            ctx.fillStyle = 'rgba(255, 255, 255, 1)';
            ctx.fillText("By- "+imageArr[i].staffName, 20, canvas.height - 50);
            ctx.fillText("Time- "+imageArr[i].created_At, canvas.width - (((canvas.width)/100)*40), canvas.height - 50);
      
            // Set watermarked image URL
            imageArr[i].completeURL=canvas.toDataURL();
            imageArrayMain.push(imageArr[i])
            setimageURL(imageArrayMain)
            
            setorderExist(true)
            setrefresh(true)
            setcount(i+1)

        }
        setorderExist(res1.data.isExist)
        setrefresh(true)
        setdeleteButtonClicked(!deleteButtonClicked)
        
         
        
      }
      else
      {
        setdeleteButtonClicked(!deleteButtonClicked)
        setorderExist(false)

      }
      }
      else
      {
        setdeleted(false)

      }
  
      }
    })
  }
  else
  {
    swal({
      title: "No item selected",
      text: "Please select atleast one item",
      icon: "warning",
      button: "Done",
    });
  }
    
  }
  useEffect(()=>{
    axios
      .post(`${baseURL}/verfyToken`, {
       token:localStorage.getItem("token")
      })
      .then((res) => {
        if(res.data.statusCode==200)
        {
        }
        else
        {
          navigate(`/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  return (
    <div className="App" style={{marginBottom:"50px"}}> 
      <Layout>
      {refresh ? (
    <div>
   <h5>Order Id: #{location.state.id}</h5>
   <hr/>
  

   
   {(orderExist==true && deleted==false)?(<div>
    {/*<Button variant="danger" onClick={()=>{
    deleteS3Video()
   }}>Delete</Button> */}
   {(videoURL.length!=0||imageURL.length!=0)?<div>
    <Flex wrap="wrap" gap="small"><h6 className='my-2'>Select All</h6>
   <Form.Check
                              type="checkbox"
                              id={`default-checkbox`}
                              className='my-2'
                              label=""
                              onClick={handleTickAll}
                            />

                      <Button variant="primary" className='btn btn-primary' style={{width:"100px"}} onClick={()=>{
    handleDownloadAll()
   }}>Download</Button>    
        <Button variant="danger" className='btn btn-danger' style={{width:"100px"}} onClick={()=>{
    deleteS3Video(checkedOrderArray)
   }}>Delete</Button></Flex>
    <hr/>
    {/* <h5>Video Gallary</h5> */}
{/* <hr/> */}
    </div>:""}
   <br/>
   
   <Flex wrap="wrap" gap="small">
   {videoURL.map((item,i)=>{
    return ( 
    <div key={i}>
     
      <div className='d-flex flex-wrap'>
      <Form.Check
                                        type="checkbox"
                                        id={`default-checkbox-1-${i}`}
                                        label=""
                                        checked={
                                          checkedOrderArray.indexOf(item.order_number) ==
                                          -1
                                            ? false
                                            : true
                                        }
                                        onChange={(e) => {
                                          handleOrderCheckBox(e, item.order_number);
                                        }}
                                      />
        <h6>{item.file_name}</h6>
        </div>
    <video width="500" height="300" src={`${baseURL}/images/${item.order_number}`} type="video/mp4" controls="controls" >
    </video>
    </div>)
   })}
  
  <div>{(imageURL.length!=0)?<div>
   {/*  <hr/> */}
   {/*  <h5>Image Gallary</h5> */}
{/* <hr/> */}
    </div>:""}
   <div className='d-flex flex-wrap'>
   
   {imageURL.map((item,i)=>{
    return ( 
      <div key={i} className='m-2 p-2' style={{boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"}}>
        <div className='d-flex flex-wrap'>
        <Form.Check
                                        type="checkbox"
                                        id={`default-checkbox-1-${i}`}
                                        label=""
                                        checked={
                                          checkedOrderArray.indexOf(item.order_number) ==
                                          -1
                                            ? false
                                            : true
                                        }
                                        onChange={(e) => {
                                          handleOrderCheckBox(e, item.order_number);
                                        }}
                                      />
        <h6>{"Image-"}{i+1}{"_"}{item.file_name}</h6>
        </div>
       
    {/* <img src={`${baseURL}/images/${item.order_number}`} className='m-2' width="280px" height="230px" onClick={()=>handleShow(`${baseURL}/images/${item.order_number}`,item.staffName,item.created_At)} style={{cursor:"pointer"}}/> */}
    
    
    <Image1
      width={200}
      /* src={`${baseURL}/images/${item.order_number}`} */
      src={item.completeURL}
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <DownloadOutlined width="20px" height={50} onClick={()=>onDownload(item.completeURL)} />
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            <h6 className='my-1'>Captured by- {item.staffName}</h6>
            <h6 className='my-1'>TimeStamp- {item.created_At}</h6>
          </Space>
        ),
      }}
    />
    </div>
    )
   })}
   {totalCount>count?(<center>
      <div className="spinner-border" style={{margin:"50px"}} role="status">
        <span className="sr-only"></span>
      </div>
    </center>):""}
   </div>
   </div>
  </Flex>

   {/* <Modal  size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
    
        </Modal.Header>
        <Modal.Body>
          <center><img src={imageURL1} width="400px!important"></img></center>
        </Modal.Body>
      </Modal> */}
      <Modal
        centered
        open={show}
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
        width={1350}
      >
        <div style={{width:"1920px !important",zIndex:99999}}>
        <img src={imageURL1} className='d-block w-100 my-5' crossOrigin="anonymous"/>
        <hr/>
        <h6>TimeStamp- {imageTimeStamp}</h6>
        <h6>Captured by- {imageStaffName}</h6>
        </div>
      </Modal>
   
  </div>):(<h3>Order Id does not exist</h3>)}

  {deleted==true?(<h3>Video has been deleted</h3>):("")}
  
   

  


</div>
  ) : (
    <center>
      <div className="spinner-border my-5" role="status">
        <span className="sr-only"></span>
      </div>
    </center>
  )}
  </Layout>
  </div>
  )
}

export default RecordingDetails
