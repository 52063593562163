import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ProductPage from "./Views/ProductPage.js"
import OrderPage from "./Views/OrderPage.js"
import OrderPageView from "./Views/OrderPageView.js"
import AddStaffPage from "./Views/AddStaffPage.js"
import WelcomePage from "./Views/WelcomePage.js"
import WelcomePageView from "./Views/WelcomePageView.js"
import OrderDetailsPage from "./Views/OrderDetailsPage.js"
import OrderDetailsPageView from "./Views/OrderDetailsPageView.js"
import SearchResults from "./Views/SearchResults.js"
import VideoList from "./Views/VideoList.js"
import VideoListView from "./Views/VideoListView.js"
import Settings from "./Views/Settings.js"
import Br from "./Views/Br.js"
import CameraPage from "./Views/CameraPage.js"
import RecordingDetails from "./Views/RecordingDetails.js"
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./Components/Login.js"
import ForgotPassword from "./Components/ForgotPassword.js"
import ResetPassword from "./Components/ResetPassword.js"
import PickListPage from './Views/PickListPage.js';
import PickListByProduct from './Views/PickListByProduct.js';
import Inventory from './Views/Inventory.js';
import PickListLandingPage from './Views/PickListLandingPage.js';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/dashboard/:shop/:code" exact element={<App />} />
        <Route path="/allOrderList" exact element={<OrderPage />} />
        <Route path="/cameraApp" exact element={<CameraPage />} />
        <Route path="/allOrderListView" exact element={<OrderPageView />} />
        <Route path="/allProductList" exact element={<ProductPage />} />
        <Route path="/addStaff" exact element={<AddStaffPage />} />
        <Route path="/welcomePage" exact element={<WelcomePage />} />
        <Route path="/welcomePageView" exact element={<WelcomePageView />} />
        <Route path="/orderDetails" exact element={<OrderDetailsPage />} />
        <Route path="/RecordingDetails" exact element={<RecordingDetails />} />
        <Route path="/orderDetailsView" exact element={<OrderDetailsPageView />} />
        <Route path="/orderResults" exact element={<SearchResults />} />
        <Route path="/allVideoList" exact element={<VideoList />} />
        <Route path="/allVideoListView" exact element={<VideoListView />} />
        <Route path="/setting" exact element={<Settings />} />
        <Route path="/barcodePage1" exact element={<Br />} />
        <Route path="/forgotPassword" exact element={<ForgotPassword />} />
        <Route path="/resetPassword" exact element={<ResetPassword />} />
        <Route path="/pickListPage" exact element={<PickListPage />} />
        <Route path="/pickListByProductPage" exact element={<PickListByProduct />} />
        <Route path="/PickListLandingPage" exact element={<PickListLandingPage />} />


        <Route path='/inventory' exact element={<Inventory />} />


      </Routes>
    </BrowserRouter>

  </React.StrictMode>
);
reportWebVitals();
