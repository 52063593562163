import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import Layout from "../Layout/LayoutView.js";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import RecordVideo from "../RecordVideo.js"
import baseURL from "../Config/configFile.js";

function VideoListView() {
  const navigate = useNavigate();
  const GotoOrderDetailsPage = (orderID) => {
    navigate("/orderDetailsView", { replace: false, state: { id: orderID } });
  };
  const [orderSerach, setorderSerach] = useState("");
  /* localStorage.setItem("shop",shop)
  localStorage.setItem("code",code) */
  const myFunction = () => {
    // your logic here
    console.log('pressed Enter');
  };
  function searchItems() {
    GotoOrderDetailsPage(orderSerach.replace(/[^a-zA-Z0-9]/g, ''))

  }
  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
  useEffect(() => {
    if (reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);

  /*  useEffect(() => {
     const keyDownHandler = event => {
       //console.log('User pressed: ', event.key);
 
       if (event.key === 'Enter') {
         event.preventDefault();
 
         // 👇️ your logic here
         myFunction();
       }
     }
       document.addEventListener('keydown', keyDownHandler);
 
     return () => {
       document.removeEventListener('keydown', keyDownHandler);
     };
     
    
   }, []); */
  return (
    <div className="App">
      <Layout status="2">
        {/* <RecordVideo shop={shop} /> */}
        <hr />
        <h5>Search your recorded video by giving or scanning order number</h5>
        <hr />
        <div className="d-flex justify-content-start">
          <Form
            className="mx-1"
            onSubmit={(e) => {
              searchItems();
              e.preventDefault();
            }}
          >
            <Form.Control
              autoFocus
              type="text"
              value={orderSerach}
              placeholder="Scan order with barcode"
              className="me-2"
              aria-label="Search"
              onChange={(e) => setorderSerach(e.target.value)}
              style={{ width: "450px" }}
            />
          </Form>
        </div>
        <hr />
      </Layout>
    </div>
  );
}

export default VideoListView;
