import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import LayoutView from "../Layout/LayoutView.js";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import baseURL from "../Config/configFile.js";

function OrderPageView() {
  /* const search = useLocation().search;
  const shop = new URLSearchParams(search).get('shop');
  const code = new URLSearchParams(search).get('code'); */
  //const { shop,code } = useParams();
  const navigate = useNavigate();
  const GotoOrderDetailsPage = (orderID) => {
    navigate("/orderDetailsView", { replace: false, state: { id: orderID,staffArray:staffArray } });
  };
  const [category, setcategory] = useState("0");
  const [categoryName, setcategoryName] = useState("Unassigned");
  const [staffArray, setstaffArray] = useState([]);
  const [orderIndicator, setorderIndicator] = useState(true);
  const [orderPriceIndicator, setorderPriceIndicator] = useState(true);
  const [orderSerach, setorderSerach] = useState("");
  function searchItems() {
    const searchValue = orderSerach;
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = count.filter((item) => {
        return Object.values(item).join("").toLowerCase().includes(searchValue);
      });
      console.log(filteredData);

      setFilteredResults(filteredData);
    } else {
      setFilteredResults(count);
    }
  }
  const [orderFieldsArray, setorderFieldsArray] = useState([
    "order_number",
    "total_price",
    "financial_status",
    "fulfillment_status",
  ]);
  const [checkedOrderArray, setcheckedOrderArray] = useState([]);
  const [magicSwitch, setmagicSwitch] = useState("");

  const [checkedAllOrderArray, setcheckedAllOrderArray] = useState([]);

  const [pickerPackerID, setpickerPackerID] = useState("");
  const [count, setCount] = useState([]);
  const [AssignName, setAssignName] = useState("Unassigned");
  const [tickAll, settickAll] = useState(false);

  const [refresh, setrefresh] = useState(true);
  const [sortByOrderId, setsortByOrderId] = useState(true);
  const [sortByOrderAmount, setsortByOrderAmount] = useState(true);
  const [sortByOrderStatus, setsortByOrderStatus] = useState(true);
  const [taxes_included, settaxes_included] = useState(false);
  const [Total_tax, setTotal_tax] = useState(false);
  const [Total_tip_received, setTotal_tip_received] = useState(false);
  const [Order_Price, setOrder_Price] = useState(true);
  const [Total_discounts, setTotal_discounts] = useState(false);
  const [Tags, setTags] = useState(false);
  const [Order_ID, setOrder_ID] = useState(true);
  const [Order_Number, setOrder_Number] = useState(false);
  const [Gateway, setGateway] = useState(false);
  const [Order_Fulfillment, setOrder_Fulfillment] = useState(true);
  const [Order_Status, setOrder_Status] = useState(true);
  const [Currency, setCurrency] = useState(false);
  const [Contact_email, setContact_email] = useState(false);
  const [Total_weight, setTotal_weight] = useState(false);

  const [show, setShow] = useState(false);
  const [showAssign, setShowAssign] = useState(false);

  const [searchInput, setSearchInput] = useState("1");
  const [filteredResults, setFilteredResults] = useState([]);
  const [initialTime, setinitialTime] = useState("");
  const [showTime, setshowTime] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowAssign = () => setShowAssign(true);
  const handleCloseAssgn = () => setShowAssign(false);
  var d;
  const handleTickAll = () => {
    if (checkedAllOrderArray.length == 0) {
      var newArr = [];
      count.forEach((item) => {
        newArr.push(item.id);
      });
      setcheckedOrderArray(newArr);
      setcheckedAllOrderArray(newArr);
      settickAll(true);
    } else {
      setcheckedOrderArray([]);
      setcheckedAllOrderArray([]);

      settickAll(false);
    }
  };

  setInterval(() => {
    d = new Date();
    let minutes = d.getMinutes();
    setshowTime(parseInt(minutes) - parseInt(initialTime));
  }, 1000 * 60);
  const handleApply = () => {
    setShow(false);
    setrefresh(false);
    setTimeout(() => {
      setrefresh(true);
    }, 500);
  };
  const handleApplyAssign = () => {
    setShowAssign(false);
    setrefresh(false);
    axios
      .post(`${baseURL}/assignOrderToSpecificPerson`, {
        shop: localStorage.getItem("shop"),
        orderIdArray: checkedOrderArray,
        personId: pickerPackerID,
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Good job!",
            text: "You have assigned the orders",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleApplyAutoAssign = () => {
    setrefresh(false);
    if (checkedOrderArray.length > 0) {
      axios
        .post(`${baseURL}/autoAssign`, {
          shop: localStorage.getItem("shop"),
          orderIdArray: checkedOrderArray,
        })
        .then((res) => {
          if (res.data.statusCode == 200) {
            setrefresh(true);
            swal({
              title: "Good job!",
              text: "You have auto assigned the orders",
              icon: "success",
              button: "Done",
            });
            clickOnRefresh();
          } else {
            setrefresh(true);
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              button: "Done",
            });
            clickOnRefresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setrefresh(true);
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleApplyUnAssign = () => {
    setrefresh(false);
    if (checkedOrderArray.length > 0) {
      axios
        .post(`${baseURL}/assignOrderToSpecificPerson`, {
          shop: localStorage.getItem("shop"),
          orderIdArray: checkedOrderArray,
          personId: 0,
        })
        .then((res) => {
          if (res.data.statusCode == 200) {
            setrefresh(true);
            swal({
              title: "Good job!",
              text: "You have unassigned the orders",
              icon: "success",
              button: "Done",
            });
            clickOnRefresh();
          } else {
            setrefresh(true);
            swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              button: "Done",
            });
            clickOnRefresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setrefresh(true);
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleApplyAssignToMe = () => {
    setrefresh(false);
    if (checkedOrderArray.length > 0) {
      axios
        .post(`${baseURL}/assignOrderToSpecificPerson`, {
          shop: localStorage.getItem("shop"),
          orderIdArray: checkedOrderArray,
          personId: localStorage.getItem("staffId"),
        })
        .then((res) => {
          if (res.data.statusCode == 200) {
            setrefresh(true);
            swal({
              title: "Good job!",
              text: "You have unassigned the orders",
              icon: "success",
              button: "Done",
            });
            clickOnRefresh();
          } else {
            setrefresh(true);
            swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              button: "Done",
            });
            clickOnRefresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setrefresh(true);
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleApplyOrderFields = () => {
    setrefresh(false);
    setShow(false);
    axios
      .post(`${baseURL}/updateOrderFields`, {
        shop: localStorage.getItem("shop"),
        orderFields: orderFieldsArray,
        id: localStorage.getItem("staffId"),
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Good job!",
            text: "You have set your order fields",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /* function searchItems(searchValue) {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = count.filter((item) => {
        return Object.values(item).join("").toLowerCase().includes(searchValue);
      });
      console.log(filteredData);

      setFilteredResults(filteredData);
    } else {
      setFilteredResults(count);
    }
  } */
  const handleMagicAssign = () => {
    setrefresh(false);
    axios
      .post(`${baseURL}/workingStatus`, {
        shopName: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        id: localStorage.getItem("staffId"),
        switchOnOff: !magicSwitch ? 1 : 0,
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Good job!",
            text: "Working Status Set",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: res.data.message,
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const d = new Date();
    setinitialTime(d.getMinutes());
    console.log("Updated");
    localStorage.getItem("workingStatus") == 1
      ? setmagicSwitch(true)
      : setmagicSwitch(false);
    setrefresh(false);
    axios
      .get(
        `${baseURL}/orderList?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem("code")}&assignType=${category}`
      )
      .then((res) => {
        console.log("Response data", res.data.orders);
        setCount(res.data.orders);
        setstaffArray(res.data.staffArray);
        //var orderFields = res.data.orderFields.order_fields.split(",");
        //setorderFieldsArray(orderFields);
        setorderFieldsArray(localStorage.getItem("orderFields").split(","));
        setcheckedOrderArray([]);
        setcheckedAllOrderArray([]);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    console.log("Updated");
    setrefresh(false);
    axios
      .get(
        `${baseURL}/orderList?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem("code")}&assignType=${category}`
      )
      .then((res) => {
        console.log("Response data", res.data.orders);
        setCount(res.data.orders);
        setstaffArray(res.data.staffArray);
        // var orderFields = res.data.orderFields.order_fields.split(",");
        //setorderFieldsArray(orderFields);
        setorderFieldsArray(localStorage.getItem("orderFields").split(","));

        setcheckedOrderArray([]);
        setcheckedAllOrderArray([]);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [category]);
  useEffect(() => {
    console.log("checked array");
  }, [checkedOrderArray]);
  const clickOnRefresh = () => {
    console.log("clicked on refresh");
    setrefresh(false);
    axios
      .get(
        `${baseURL}/orderList?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem("code")}&assignType=${category}`
      )
      .then((res) => {
        console.log("Response data", res.data.orders);
        setCount(res.data.orders);
        setcheckedOrderArray([]);
        setcheckedAllOrderArray([]);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clickOnSortByOrderID = (key) => {
    setrefresh(false);
    setsortByOrderId(!sortByOrderId);

    console.log("clickOnSortByOrderID");
    console.log("clickOnSortByOrderIDbefore", count);
    let sortedProducts;
    if (sortByOrderId) {
      sortedProducts = count.sort((p1, p2) =>
        p1[key] > p2[key] ? 1 : p1[key] < p2[key] ? -1 : 0
      );
    } else {
      sortedProducts = count.sort((p1, p2) =>
        p1[key] < p2[key] ? 1 : p1[key] > p2[key] ? -1 : 0
      );
    }
    setCount(sortedProducts);
    console.log("clickOnSortByOrderIDafter", sortedProducts);
    setTimeout(() => {
      setrefresh(true);
    }, 50);
  };
  /* const clickOnSortByOrderID = () => {
    setrefresh(false);
    setsortByOrderId(!sortByOrderId);

    console.log("clickOnSortByOrderID");
    console.log("clickOnSortByOrderIDbefore", count);
    let sortedProducts;
    if (sortByOrderId) {
      sortedProducts = count.sort((p1, p2) =>
        p1.order_number > p2.order_number
          ? 1
          : p1.order_number < p2.order_number
          ? -1
          : 0
      );
    } else {
      sortedProducts = count.sort((p1, p2) =>
        p1.order_number < p2.order_number
          ? 1
          : p1.order_number > p2.order_number
          ? -1
          : 0
      );
    }
    setCount(sortedProducts);
    console.log("clickOnSortByOrderIDafter", sortedProducts);
    setTimeout(() => {
      setrefresh(true);
    }, 50);
  }; */
  const clickOnSortByOrderAmount = () => {
    setrefresh(false);
    setsortByOrderAmount(!sortByOrderAmount);

    console.log("clickOnSortByOrderAmount");
    console.log("clickOnSortByOrderAmountbefore", count);
    let sortedProducts;
    if (sortByOrderAmount) {
      sortedProducts = count.sort((p1, p2) =>
        parseInt(p1.total_price) > parseInt(p2.total_price)
          ? 1
          : parseInt(p1.total_price) < parseInt(p2.total_price)
          ? -1
          : 0
      );
    } else {
      sortedProducts = count.sort((p1, p2) =>
        parseInt(p1.total_price) < parseInt(p2.total_price)
          ? 1
          : parseInt(p1.total_price) > parseInt(p2.total_price)
          ? -1
          : 0
      );
    }
    setCount(sortedProducts);
    console.log("clickOnSortByOrderAmountafter", sortedProducts);
    setTimeout(() => {
      setrefresh(true);
    }, 50);
  };
  const clickOnSortByOrderStatus = () => {
    setrefresh(false);
    setsortByOrderStatus(!sortByOrderStatus);

    console.log("clickOnSortByOrderStatus");
    console.log("clickOnSortByOrderStatusbefore", count);
    let sortedProducts;
    if (sortByOrderStatus) {
      sortedProducts = count.sort((p1, p2) =>
        p1.total_price > p2.total_price
          ? 1
          : p1.total_price < p2.total_price
          ? -1
          : 0
      );
    } else {
      sortedProducts = count.sort((p1, p2) =>
        parseInt(p1.total_price) < parseInt(p2.total_price)
          ? 1
          : parseInt(p1.total_price) > parseInt(p2.total_price)
          ? -1
          : 0
      );
    }
    setCount(sortedProducts);
    console.log("clickOnSortByOrderStatusafter", sortedProducts);
    setTimeout(() => {
      setrefresh(true);
    }, 50);
  };
  const handleCategoryChange = (index) => {
    setcategory(index);
    setcheckedOrderArray([]);
  };
  const handleCategoryNameChange = (index) => {
    setcategoryName(index);
    setAssignName(index);
  };
  const handleOrderCheckBox = (e, orderNumber) => {
    var newArr = [...checkedOrderArray];
    if (newArr.indexOf(orderNumber) != -1) {
      newArr.splice(newArr.indexOf(orderNumber), 1);
      setcheckedOrderArray(newArr);
    } else {
      newArr.push(orderNumber);
      setcheckedOrderArray(newArr);
    }
    console.log(checkedOrderArray);
  };
  const handleOrderFieldsCheckBox = (e, orderfields) => {
    if (orderFieldsArray.indexOf(orderfields) != -1) {
      var order = orderFieldsArray;
      order.splice(orderFieldsArray.indexOf(orderfields), 1);
      setorderFieldsArray(order);
      setrefresh(false);
      setTimeout(() => {
        setrefresh(true);
      }, 5);
    } else {
      var order = orderFieldsArray;
      order.push(orderfields);
      setorderFieldsArray(order);
    }

    console.log(orderFieldsArray);
  };
  /* console.log(
    "Name",
    localStorage.getItem("shop"),
    localStorage.getItem("code")
  ); */
  return (
    <div>
      <LayoutView status="1">
        {refresh ? (
          <div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Customize your Orders</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Add/Remove to customize order fields
                <br />
                {/*  <Form.Check
                 type="checkbox"
                 id={`default-checkbox-1`}
                 label="Contact_email"
                 checked={Contact_email}
                 onChange={() => setContact_email(!Contact_email)}
               /> */}
                {count.length > 0
                  ? Object.keys(count[0]).map(function (key, index) {
                      if (
                        typeof count[0][key] === "object" &&
                        count[0][key] != null
                      ) {
                      } else {
                        if (orderFieldsArray.indexOf(key) != -1) {
                          return (
                            <Form.Check
                              type="checkbox"
                              id={`default-checkbox-2`}
                              label={key.split("_").join(" ").toUpperCase()}
                              checked={orderFieldsArray.indexOf(key) != -1}
                              onChange={(e) => {
                                //e.target.checked=!e.target.checked
                                console.log(e.target.checked);
                                handleOrderFieldsCheckBox(e, key);
                              }}
                            />
                          );
                        } else {
                          return (
                            <Form.Check
                              type="checkbox"
                              id={`default-checkbox-2`}
                              label={key.split("_").join(" ").toUpperCase()}
                              onChange={(e) =>
                                handleOrderFieldsCheckBox(e, key)
                              }
                            />
                          );
                        }
                      }
                    })
                  : ""}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Discard
                </Button>
                <Button variant="primary" onClick={handleApplyOrderFields}>
                  Apply
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showAssign} onHide={handleCloseAssgn}>
              {checkedOrderArray.length > 0 ? (
                <div>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Assign to a specific picker/packer
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Choose to assign order
                    <br />
                    {staffArray.map((item) => {
                      return (
                        <Form.Check
                          type="radio"
                          id="default-radio"
                          name="default-radio"
                          label={item.first_name + " " + item.last_name}
                          onChange={() => {
                            setpickerPackerID(item.id);
                            console.log("id", pickerPackerID);
                          }}
                        />
                      );
                    })}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAssgn}>
                      Discard
                    </Button>
                    <Button variant="primary" onClick={handleApplyAssign}>
                      Apply
                    </Button>
                  </Modal.Footer>
                </div>
              ) : (
                <div>
                  <Modal.Header closeButton>
                    <Modal.Title>Please first select some orders</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <b>
                      Choose one or multiple orders from checkbox in order table
                    </b>
                    <br />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAssgn}>
                      Discard
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Modal>
            <hr />
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="55"
                  height="55"
                  fill="currentColor"
                  className="bi bi-inbox-fill text-dark"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                </svg>
                <div className="mx-1">
                  <Row>
                    <Col>My Orders</Col>
                  </Row>
                  <Row>
                    <Col>
                      <SplitButton
                        as={ButtonGroup}
                        key="1"
                        id={`dropdown-button-drop`}
                        size="sm"
                        variant="secondary"
                        title={category == "0" ? "Unassigned" : categoryName}
                      >
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => {
                            handleCategoryChange("0");
                            handleCategoryNameChange("Unassigned");
                          }}
                        >
                          Unassigned
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => {
                            handleCategoryChange(
                              localStorage.getItem("staffId")
                            );
                            handleCategoryNameChange(
                              localStorage.getItem("staffName")
                            );
                          }}
                        >
                          {localStorage.getItem("staffName")}
                        </Dropdown.Item>
                        {/*  {staffArray.map((item) => {
                                return (
                                  <Dropdown.Item
                                    eventKey="2"
                                    onClick={() => {
                                      handleCategoryChange(item.id);
                                      handleCategoryNameChange(
                                        item.first_name + " " + item.last_name
                                      );
                                    }}
                                  >
                                    {item.first_name + " " + item.last_name}
                                  </Dropdown.Item>
                                );
                              })} */}
                      </SplitButton>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="my-2">
                {category == "0"
                  ? /*  <Button
                      variant="success"
                      size="sm"
                      className="mx-1 my-1"
                      onClick={handleApplyAutoAssign}
                    >
                      Auto Assign
                    </Button> */ ""
                  : /* <Button
                      variant="danger"
                      size="sm"
                      className="mx-1 my-1"
                      onClick={handleApplyUnAssign}
                    >
                      Unassign
                    </Button> */ ""}
                {category == "0" ? (
                  <Button
                    variant="success"
                    size="sm"
                    className="mx-1"
                    onClick={handleApplyAssignToMe}
                  >
                    Assign to your self
                  </Button>
                ) : (
                  /* <Button
                      variant="secondary"
                      size="sm"
                      className="mx-1"
                      onClick={handleShowAssign}
                    >
                      Assign order to another Picker/Packer
                    </Button> */ ""
                )}

                <Button variant="primary" size="sm">
                  Export
                </Button>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="h6" style={{ fontSize: "15px" }}>
                {count.length} Orders. Updated {showTime>=2?showTime+" Minutes ago.":"a few seconds ago"} 
              </div>

              <div className="d-flex justify-content-start">
                <Form
                  className="mx-1"
                  onSubmit={(e) => {
                    searchItems();
                    e.preventDefault();
                  }}
                >
                  <Form.Control
                    autoFocus
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    onChange={(e) => setorderSerach(e.target.value)}
                  />
                </Form>
                <Button
                  style={{ backgroundColor: "#C1FF05", color: "black" }}
                  size="sm"
                >
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Working Today"
                      checked={magicSwitch}
                      onClick={() => {
                        magicSwitch
                          ? setmagicSwitch(false)
                          : setmagicSwitch(true);
                        console.log("magicAssign", magicSwitch);
                        handleMagicAssign();
                      }}
                    />
                  </Form>
                </Button>
                <Button
                  onClick={clickOnRefresh}
                  variant="light"
                  size="sm"
                  className="mx-1"
                >
                  <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="currentColor"
                    className="bi bi-arrow-repeat"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </Button>
                {/* <Button onClick={clickOnRefresh} variant="light" size="sm">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      fill="currentColor"
                      className="bi bi-gear-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                    </svg>
                  </Button> */}
                <Button
                  onClick={handleShow}
                  variant="light"
                  size="sm"
                  className="mx-1"
                >
                  <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="currentColor"
                    className="bi bi-filter-square-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm.5 5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1zM4 8.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm2 3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </Button>
              </div>
            </div>
            <hr />

            <Form>
              <div
                style={{
                  width: "100%",
                  overflowX: "scroll",
                  backgroundColor: "#FDFEFE",
                }}
              >
                {count.length == 0 ? (
                  <center>
                    <h2>No Orders</h2>
                  </center>
                ) : (
                  <Table striped bordered hover responsive="lg">
                    <thead>
                      <tr>
                        <th>
                          <Form.Check
                            type="checkbox"
                            id={`default-checkbox`}
                            label=""
                            onClick={handleTickAll}
                          />
                        </th>
                        <th>
                          <b>#</b>
                        </th>
                        <th
                          onClick={() => {
                            clickOnSortByOrderID("Assigned_To");
                            setorderIndicator(!orderIndicator);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Assigned To{" "}
                          {orderIndicator ? (
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-up text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-down text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                              />
                            </svg>
                          )}
                        </th>
                        {count.length > 0
                          ? Object.keys(count[0]).map(function (key, index) {
                              if (
                                typeof count[0][key] === "object" &&
                                count[0][key] != null
                              ) {
                              } else {
                                if (orderFieldsArray.indexOf(key) != -1) {
                                  if (key == "order_number") {
                                    return (
                                      <th
                                        onClick={() => {
                                          clickOnSortByOrderID(key);
                                          setorderIndicator(!orderIndicator);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {key
                                          .split("_")
                                          .join(" ")
                                          .charAt(0)
                                          .toUpperCase() +
                                          key.split("_").join(" ").slice(1)}
                                        {orderIndicator ? (
                                          <svg
                                            xmlns="https://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-arrow-up text-dark"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="https://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-arrow-down text-dark"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                            />
                                          </svg>
                                        )}
                                      </th>
                                    );
                                  }
                                  if (key == "total_price") {
                                    return (
                                      <th
                                        onClick={() => {
                                          clickOnSortByOrderAmount();
                                          setorderPriceIndicator(
                                            !orderPriceIndicator
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {key
                                          .split("_")
                                          .join(" ")
                                          .charAt(0)
                                          .toUpperCase() +
                                          key
                                            .split("_")
                                            .join(" ")
                                            .slice(1)}{" "}
                                        {orderPriceIndicator ? (
                                          <svg
                                            xmlns="https://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-arrow-up text-dark"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="https://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-arrow-down text-dark"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                            />
                                          </svg>
                                        )}
                                      </th>
                                    );
                                  } else {
                                    return (
                                      <th
                                        onClick={() => {
                                          clickOnSortByOrderID(key);
                                          setorderIndicator(!orderIndicator);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {key
                                          .split("_")
                                          .join(" ")
                                          .charAt(0)
                                          .toUpperCase() +
                                          key.split("_").join(" ").slice(1)}
                                        {orderIndicator ? (
                                          <svg
                                            xmlns="https://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-arrow-up text-dark"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="https://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-arrow-down text-dark"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                            />
                                          </svg>
                                        )}
                                      </th>
                                    );
                                  }
                                }
                              }
                            })
                          : ""}
                        {/* {Order_ID && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Order ID</b>
                       </Button>
                     </th>
                   )}
                   {Order_Price && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderAmount}
                       >
                         <b>Order Amount</b>
                       </Button>
                     </th>
                   )}
                   {Order_Status && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                       >
                         <b>Order Status</b>
                       </Button>
                     </th>
                   )}
                   {Order_Fulfillment && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                       >
                         <b>Order Fullfillment</b>
                       </Button>
                     </th>
                   )}
                   {taxes_included && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>taxes_included</b>
                       </Button>
                     </th>
                   )}
                   {Total_weight && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Total_weight</b>
                       </Button>
                     </th>
                   )}
                   {Total_tip_received && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Total_tip_received</b>
                       </Button>
                     </th>
                   )}
                   {Total_tax && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Total_tax</b>
                       </Button>
                     </th>
                   )}
                   {Total_discounts && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Total_discounts</b>
                       </Button>
                     </th>
                   )}
                   {Tags && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Tags</b>
                       </Button>
                     </th>
                   )}
                   {Order_Number && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Order_Number</b>
                       </Button>
                     </th>
                   )}
                   {Gateway && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Gateway</b>
                       </Button>
                     </th>
                   )}
                   {Currency && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Currency</b>
                       </Button>
                     </th>
                   )}
                   {Contact_email && (
                     <th>
                       <Button
                         variant="dark"
                         style={{
                           width: "100%",
                           textAlign: "left",
                           paddingLeft: "2",
                         }}
                         onClick={clickOnSortByOrderID}
                       >
                         <b>Contact_email</b>
                       </Button>
                     </th>
                   )} */}
                      </tr>
                    </thead>
                    <tbody>
                      {searchInput.length > 1
                        ? filteredResults.length == 0
                          ? "No data found"
                          : filteredResults.map((item, i) => {
                              return (
                                <tr key={i} style={{ cursor: "pointer" }}>
                                  <td>
                                    <Form.Check
                                      type="checkbox"
                                      id={`default-checkbox-1-${i}`}
                                      label=""
                                      checked={
                                        checkedOrderArray.indexOf(item.id) == -1
                                          ? false
                                          : true
                                      }
                                      onChange={(e) => {
                                        handleOrderCheckBox(e, item.id);
                                      }}
                                    />
                                  </td>
                                  <td  onClick={() => {
                                        GotoOrderDetailsPage(item.id);
                                      }}>{i + 1}</td>
                                  <td>{AssignName}</td>
                                  {/* <td>{item.order_number}</td> */}
                                  {count.length > 0
                                    ? Object.keys(item).map(function (
                                        key,
                                        index
                                      ) {
                                        if (
                                          typeof item[key] === "object" &&
                                          item[key] != null
                                        ) {
                                        } else {
                                          if (
                                            orderFieldsArray.indexOf(key) != -1
                                          ) {
                                            if (key == "fulfillment_status") {
                                              return (
                                                <td  onClick={() => {
                                                  GotoOrderDetailsPage(item.id);
                                                }}>
                                                  {item[key] == null
                                                    ? "Unfulfilled"
                                                    : item[key]}
                                                </td>
                                              );
                                            } else {
                                              return <td  onClick={() => {
                                                GotoOrderDetailsPage(item.id);
                                              }}>{item[key]}</td>;
                                            }
                                          }
                                        }
                                      })
                                    : ""}
                                  {/* {orderFieldsArray && <td>{item.order_number}</td>}
                           {Order_Price && <td>{item.total_price}</td>}
                           {Order_Status && <td>{item.financial_status}</td>}
                          
                           {Order_Fulfillment && (
                             <td>
                               {item.fulfillment_status == null
                                 ? "Unfulfilled"
                                 : item.fulfillment_status}
                             </td>
                           )}
                           {taxes_included && <td>{item.taxes_included}</td>}
                           {Total_weight && <td>{item.total_weight}</td>}
                           {Total_tip_received && (
                             <td>{item.total_tip_received}</td>
                           )}
                           {Total_tax && <td>{item.total_tax}</td>}
                           {Total_discounts && <td>{item.total_discounts}</td>}
                           {Tags && <td>{item.tags}</td>}
                           {Order_Number && <td>{item.number}</td>}
                           {Gateway && <td>{item.gateway}</td>}
                           {Currency && <td>{item.currency}</td>}
                           {Contact_email && <td>{item.contact_email}</td>} */}
                                </tr>
                              );
                            })
                        : count.map((item, i) => {
                            return (
                              <tr key={i} style={{ cursor: "pointer" }}>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    id={`default-checkbox-1-${i}`}
                                    label=""
                                    checked={
                                      checkedOrderArray.indexOf(item.id) == -1
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      handleOrderCheckBox(e, item.id);
                                    }}
                                  />
                                </td>
                                <td  onClick={() => {
                                        GotoOrderDetailsPage(item.id);
                                      }}>{i + 1}</td>
                                <td  onClick={() => {
                                        GotoOrderDetailsPage(item.id);
                                      }}>{AssignName}</td>
                                {count.length > 0
                                  ? Object.keys(item).map(function (
                                      key,
                                      index
                                    ) {
                                      if (
                                        typeof item[key] === "object" &&
                                        item[key] != null
                                      ) {
                                      } else {
                                        if (
                                          orderFieldsArray.indexOf(key) != -1
                                        ) {
                                          if (key == "fulfillment_status") {
                                            return (
                                              <td  onClick={() => {
                                                GotoOrderDetailsPage(item.id);
                                              }}>
                                                {item[key] == null
                                                  ? (item[key] = "Unfulfilled")
                                                  : item[key]}
                                              </td>
                                            );
                                          } else {
                                            return <td  onClick={() => {
                                              GotoOrderDetailsPage(item.id);
                                            }}>{item[key]}</td>;
                                          }
                                        }
                                      }
                                    })
                                  : ""}
                                {/* {Order_ID && <td>{item.order_number}</td>}
                   
                         {Order_Price && <td>{item.total_price}</td>}
                    
                         {Order_Status && <td>{item.financial_status}</td>}
                         
                         {Order_Fulfillment && (
                           <td>
                             {item.fulfillment_status == null
                               ? "Unfulfilled"
                               : item.fulfillment_status}
                           </td>
                         )}
                         {taxes_included && <td>{item.taxes_included}</td>}
                         {Total_weight && <td>{item.total_weight}</td>}
                         {Total_tip_received && (
                           <td>{item.total_tip_received}</td>
                         )}
                         {Total_tax && <td>{item.total_tax}</td>}
                         {Total_discounts && <td>{item.total_discounts}</td>}
                         {Tags && <td>{item.tags}</td>}
                         {Order_Number && <td>{item.number}</td>}
                         {Gateway && <td>{item.gateway}</td>}
                         {Currency && <td>{item.currency}</td>}
                         {Contact_email && <td>{item.contact_email}</td>} */}
                              </tr>
                            );
                          })}
                    </tbody>
                  </Table>
                )}
              </div>
            </Form>
          </div>
        ) : (
          <center>
            <div className="spinner-border my-5" role="status">
              <span className="sr-only"></span>
            </div>
          </center>
        )}
      </LayoutView>
    </div>
  );
}

export default OrderPageView;
