import React, { useEffect, useState } from 'react'
import pickpacklogo from ".././pickpacklogo.png"
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import baseURL from "../Config/configFile"
import { message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Space } from 'antd';
function ResetPassword() {
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
      messageApi.open({
        type: 'success',
        content: 'Password has been reset. Redirecting you to welcome page',
        duration: 2,
      });
    };
    const [passwordVisible, setPasswordVisible] = React.useState(false);
  const navigate = useNavigate();
  const [email,setemail]=useState("")
  const [password,setpassword]=useState("")
  const [newPassword,setnewPassword]=useState("a")
  const [confirmPassword,setconfirmPassword]=useState("b")

  const [resetPassword,setResetPassword]=useState(false)
  
  const handleEmailChange = (event) => {
    setemail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setpassword(event.target.value);
  };
  const handleNewPasswordChange = (event) => {
    setnewPassword(event.target.value);
  };
  const handleconfirmPasswordChange = (event) => {
    setconfirmPassword(event.target.value);
  };
  const clickOnLogin = async(event) => {
    event.preventDefault();
    
    axios
      .post(`${baseURL}/login`, {
        email:email,
        password:password
      })
      .then(async(res) => {
      
    
       


        if(res.data.statusCode==200)
        {
            var responseData=await axios
            .post(`${baseURL}/generateToken`, {
              email:email,
              password:password
            })
            
              console.log("Response data", responseData.data);
              localStorage.setItem("token",responseData.data.token)
            console.log("Response data", res.data);
            localStorage.setItem("shop",res.data.shop)
            localStorage.setItem("code",res.data.code)
            localStorage.setItem("orderFields",res.data.orderFields)
            localStorage.setItem("staffId",res.data.staffId)
            localStorage.setItem("staffName",res.data.staffName)
            localStorage.setItem("workingStatus",res.data.workingStatus)  
          //navigate(`/dashboard/${res.data.shop}/${res.data.code}`);
          setResetPassword(true)
        
        }
        else{
        swal({
          title: "Error!",
          text: res.data.message,
          icon: "error",
          button: "Done",
        });
      }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetPasswordClicked = ()=>{
    if((newPassword=="" && confirmPassword==""))
    {
        swal({
            title: "Error!",
            text: "Password not matching",
            icon: "error",
            button: "Done",
          });
    }
    else{
        if(newPassword==confirmPassword && newPassword.length!=0)
        {
    axios
      .post(`${baseURL}/resetPassword`, {
        email:email,
        newPassword:newPassword
      })
      .then((res) => {
        if(res.data.statusCode==200){
            success()
            setTimeout(()=>{
                navigate("/")
            },2000)
        }
        else
        {
            swal({
                title: "Error!",
                text: "Something went wrong",
                icon: "error",
                button: "Done",
              });
        }

      })
      .catch((err)=>{
        console.log(err)
        swal({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
      })
    }
    else{
        swal({
            title: "Error!",
            text: "Password can't be empty",
            icon: "error",
            button: "Done",
          });
    }
    }
    
  }
  return (
    <div>
        {contextHolder}
        <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-5 border-dark"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="d-flex justify-content-center">
                  <img
              alt=""
              src={pickpacklogo}
              width="40"
              height="40"
            />
<h2 className="fw-bold text-uppercase " style={{marginLeft:"5px"}}>Pick PAck Go</h2>
                  </div>
                
                  <Form.Label className="text-center fw-bold">
                  Reset password
                        </Form.Label>
                  <div className="mb-3">
                    {!resetPassword?<Form onSubmit={clickOnLogin}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center fw-bold">
                          Email
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" required onChange={handleEmailChange}/>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="text-center fw-bold">Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" required onChange={handlePasswordChange} />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="dark" type="submit">
                        verify Account
                        </Button>
                      </div>
                    </Form>:<Form>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        {/* <Form.Label className="text-center fw-bold">New Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" required onChange={handleNewPasswordChange} /> */}
                        <Space direction="horizontal">
        <Input.Password
          placeholder="New Password"
          visibilityToggle={{
            visible: passwordVisible,
            onVisibleChange: setPasswordVisible,
          }}
          required onChange={handleNewPasswordChange}
          size="large"
        />
        </Space>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        {/* <Form.Label className="text-center fw-bold">Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" required onChange={handleconfirmPasswordChange} /> */}
                        <Space direction="horizontal">
        <Input.Password
          placeholder="Confirm Password"
          visibilityToggle={{
            visible: passwordVisible,
            onVisibleChange: setPasswordVisible,
          }}
          required onChange={handleconfirmPasswordChange}
          size="large"
        />
        </Space>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        {/* <p className="small">
                          <a className="text-primary" href="#!">
                            Forgot password?
                          </a>
                        </p> */}
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="dark" onClick={resetPasswordClicked}>
                        Reset Password
                        </Button>
                      </div>
                    </Form>}
                    
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        Don't have an account?{" "}
                        
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ResetPassword