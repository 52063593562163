import React from 'react'
import HeaderForView from "./HeaderForView.js"
import SideNavBarView from "./SideNavBarView.js"
import { Col, Row } from 'react-bootstrap';
import UseWindowDimensions from '../dimension/UseWindowDimensions.js'

function LayoutView({children,status}) {
  const { height, width } = UseWindowDimensions();
        var style = {
                marginLeft:"10px",width:width
        }
  return (
    <div style={{backgroundColor:"#F2F3F4",height:"auto"}}>
       
        <div className="container-fluid">
        <div className='d-flex'>
<SideNavBarView status={status} />

<div style={style}>
<HeaderForView />
        <main>{children}</main>
</div>
</div>
        </div>

    </div>
  )
}

export default LayoutView