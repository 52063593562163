import React, { useState,useEffect  }  from 'react';
import {useLocation,useParams} from "react-router-dom";
import { Button, Row,Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Layout from "../Layout/Layout.js"
import axios from "axios"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import Badge from 'react-bootstrap/Badge';
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import Barcode from 'react-barcode';
import BarCmp from './BarCmp.js'
import { useBarcode } from '@createnextapp/react-barcode';
//import printJS from "print-js";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { useMediaQuery } from 'react-responsive'
import baseURL from "../Config/configFile.js";



function Br() {

  /* const isPrinting = useMediaQuery({
    query: 'print'
  }) */
    const location = useLocation()
    console.log(location.state.data.videoCommand)
    /* function printDiv(divId,
      title) {
    
      let mywindow = window.open('', 'PRINT', '_blank','height=650,width=900,top=100,left=150');
    
      mywindow.document.write(`<html><head><title>${title}</title>`);
      mywindow.document.write('</head><body >');
      mywindow.document.write(document.getElementById(divId).innerHTML);
      mywindow.document.write('</body></html>');
    
      mywindow.document.close(); 
      mywindow.focus(); 
    
      mywindow.print();
      mywindow.close();
    
      return true;
    } */

  return (


    <div className="App">
      <PrintProvider>
      <NoPrint>
      <Layout>
      
      <hr/>
        <Print>
        <div id="print" className="d-flex flex-wrap">
          <div style={{margin:"10px"}}>
          <h6 >Record video from both camera</h6>
         
       {/* <Barcode value={location.state.data.videoCommand}/> */}
       <BarCmp code={location.state.data.videoCommand} w="100px" />
       </div>
       <div style={{margin:"10px"}}>
       <h6 >Snap pictures from both camera</h6>
       {/* <Barcode value={location.state.data.imageCommand1} /> */}
       <BarCmp code={location.state.data.imageCommand1} w="100px" />
     </div>
     <div style={{margin:"10px"}}>
       <h6 >Cam one for recording video & Cam two for clicking images</h6>
       {/* <Barcode value={location.state.data.imageCommand1} /> */}
       <BarCmp code={location.state.data.option1} w="100px" />
     </div>
     <div style={{margin:"10px"}}>
       <h6 >Cam one for clicking images & Cam two for recording video</h6>
       {/* <Barcode value={location.state.data.imageCommand1} /> */}
       <BarCmp code={location.state.data.option2} w="100px" />
     </div>
     <div style={{margin:"10px"}}>
       <h6 >Cam 1 for recording</h6>
       {/* <Barcode value={location.state.data.imageCommand1} /> */}
       <BarCmp code={location.state.data.option3} w="100px" />
     </div>
     <div style={{margin:"10px"}}>
       <h6 >Cam 1 for clicking images</h6>
       {/* <Barcode value={location.state.data.imageCommand1} /> */}
       <BarCmp code={location.state.data.option4} w="100px" />
     </div>
     <div style={{margin:"10px"}}>
       <h6 >Cam 2 for recording</h6>
       {/* <Barcode value={location.state.data.imageCommand1} /> */}
       <BarCmp code={location.state.data.option5} w="100px" />
     </div>
     <div style={{margin:"10px"}}>
       <h6 >Cam 2 for clicking images</h6>
       {/* <Barcode value={location.state.data.imageCommand1} /> */}
       <BarCmp code={location.state.data.option6} w="100px" />
     </div>
</div>
</Print>

{/* <Button style={{margin:"10px"}} variant="primary" onClick={()=>{
  printDiv("print","Barcode")
}}>Print</Button> */}
{/* <button onClick={() => printJS("print", "html")}>
          Print
        </button> */}
        <Button style={{margin:"10px"}} variant="primary" onClick={() => window.print()}>
          Print
        </Button>
       
      
  </Layout>
  </NoPrint>
</PrintProvider>
  </div>
  )
}

export default Br
