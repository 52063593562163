import React, { useEffect, useState } from 'react'
import pickpacklogo from ".././pickpacklogo.png"
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import baseURL from "../Config/configFile"
function Login() {
  const navigate = useNavigate();
  const [email,setemail]=useState("")
  const [password,setpassword]=useState("")
  const handleEmailChange = (event) => {
    setemail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setpassword(event.target.value);
  };
  const clickOnLogin = async(event) => {
    event.preventDefault();
    axios
      .post(`${baseURL}/login`, {
        email:email,
        password:password
      })
      .then(async(res) => {
     


        if(res.data.statusCode==200)
        {
          var responseData=await axios
          .post(`${baseURL}/generateToken`, {
            email:email,
            password:password
          })
          
            console.log("Response data", responseData.data);
            localStorage.setItem("token",responseData.data.token)
          
        
              console.log("Response data", res.data);
              localStorage.setItem("shop",res.data.shop)
              localStorage.setItem("code",res.data.code)
              localStorage.setItem("orderFields",res.data.orderFields)
              localStorage.setItem("staffId",res.data.staffId)
              localStorage.setItem("staffName",res.data.staffName)
              localStorage.setItem("workingStatus",res.data.workingStatus)
              localStorage.setItem("view",JSON.stringify(res.data.view))

          //navigate(`/dashboard/${res.data.shop}/${res.data.code}`);
          if(res.data.accessType!=2&&res.data.accessType!=3)
          {
          navigate(`/welcomePage`);
          }
          else{
          

          navigate(`/welcomePageView`);

          }
        
        }
        else{
        swal({
          title: "Error!",
          text: res.data.message,
          icon: "error",
          button: "Done",
        });
      }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const forgotPasswordClicked = ()=>{
    
    navigate(`/forgotPassword`);
  }
  const resetPasswordClicked = ()=>{
    
    navigate(`/resetPassword`);
  }
  useEffect(()=>{
    axios
      .post(`${baseURL}/verfyToken`, {
       token:localStorage.getItem("token")
      })
      .then((res) => {
        if(res.data.statusCode==200)
        {
          navigate(`/welcomePage`);
        }
        else
        {
          navigate(`/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[])
  return (
    <div>
        <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-5 border-dark"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="d-flex justify-content-center">
                  <img
              alt=""
              src={pickpacklogo}
              width="40"
              height="40"
            />
<h2 className="fw-bold text-uppercase " style={{marginLeft:"5px"}}>Pick PAck Go</h2>
                  </div>
                
                 
                  <div className="mb-3">
                    <Form onSubmit={clickOnLogin}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center fw-bold">
                          Email
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" required onChange={handleEmailChange}/>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="text-center fw-bold">Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" required onChange={handlePasswordChange} />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        {/* <p className="small">
                          <a className="text-primary" href="#!">
                            Forgot password?
                          </a>
                        </p> */}
                        {/* <p className="small">
                          <a className="text-primary" onClick={resetPasswordClicked} style={{cursor:"pointer"}}>
                          Reset Password?
                          </a>
                        </p> */}
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="dark" type="submit">
                          Login
                        </Button>
                      </div>
                      <div className="d-grid mt-2">
                        <Button variant="light" onClick={forgotPasswordClicked}>
                          Forgot Password
                        </Button>
                      </div>
                      {/* <div className="d-grid mt-2">
                        <Button variant="light" onClick={resetPasswordClicked}>
                          Reset Password
                        </Button>
                      </div> */}
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        Don't have an account?{" "}
                        
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login