import React, { useState, useEffect } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import Layout from "../Layout/Layout.js";
import axios from "axios";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import baseURL from "../Config/configFile.js";


function Inventory() {
    const navigate = useNavigate();
    const GotoBarcodePage = (jsonData) => {
        navigate("/barcodePage1", { replaceAll: true, state: { data: jsonData } });
    };
    /* const { inputRef } = useBarcode({
      value: 'createnextapp',
      options: {
        background: '#FFFFFF',
      }
    }); */
    const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
    useEffect(() => {
        if (reloadCount < 1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }, []);
    // useEffect(() => {
    //     axios.post(`${baseURL}/getSettingData`, { store_name: localStorage.getItem("shop") })
    //         .then((res) => {
    //             console.log("data", res.data)
    //             if (res.data.data.length == 0) {


    //             }
    //             else {
    //                 console.log(res.data.data)
    //                 setvideoCommand(res.data.data.videoCommand)
    //                 setimageCommand1(res.data.data.imageCommand1)
    //                 setOption1(res.data.data.option1)
    //                 setOption2(res.data.data.option2)
    //                 setOption3(res.data.data.option3)
    //                 setOption4(res.data.data.option4)
    //                 setOption5(res.data.data.option5)
    //                 setOption6(res.data.data.option6)



    //                 /* setimageFilename2(jsonData.imageFilename2) 
    //                 setimageCommand2(jsonData.imageCommand2) 
    //                 setimageFilename3(jsonData.imageFilename3) 
    //                 setimageCommand3(jsonData.imageCommand3) 
    //                 setimageFilename4(jsonData.imageFilename4) 
    //                 setimageCommand4(jsonData.imageCommand4) 
    //                 setimageFilename5(jsonData.imageFilename5) 
    //                 setimageCommand5(jsonData.imageCommand5) 
    //                 setimageFilename6(jsonData.imageFilename6) 
    //                 setimageCommand6(jsonData.imageCommand6) 
    //                 setimageFilename7(jsonData.imageFilename7) 
    //                 setimageCommand7(jsonData.imageCommand7) 
    //                 setimageFilename8(jsonData.imageFilename8) 
    //                 setimageCommand8(jsonData.imageCommand8) 
    //                 setimageFilename9(jsonData.imageFilename9) 
    //                 setimageCommand9(jsonData.imageCommand9)  */
    //                 /* console.log("jsonDate",jsonData) */
    //             }

    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //         })

    // }, [])

    // const onSubmitHandle = () => {
    //     var jsonData = {}

    //     jsonData.videoCommand = videoCommand

    //     jsonData.imageCommand1 = imageCommand1

    //     axios.post(`${baseURL}/settingData`, { store_name: localStorage.getItem("shop"), videoCommand: videoCommand, imageCommand1: imageCommand1, option1: option1, option2: option2, option3: option3, option4: option4, option5: option5, option6: option6 })
    //         .then((res) => {
    //             console.log("data", res.data)
    //             swal({
    //                 title: "Saved",
    //                 text: "File name and commands saved successfully.",
    //                 icon: "success",
    //                 button: "Done",
    //             });
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //         })

    // }

    // useEffect(() => {
    //     axios
    //         .post(`${baseURL}/verfyToken`, {
    //             token: localStorage.getItem("token")
    //         })
    //         .then((res) => {
    //             if (res.data.statusCode == 200) {
    //             }
    //             else {
    //                 navigate(`/`);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, [])
    return (
        <div className="App">
            <Layout status="10" >
                <hr />
                <div className="d-flex justify-content-start">
                    <Form
                        className="mx-1"
                        onSubmit={(e) => {
                            // searchItems();
                            // e.preventDefault();
                        }}
                    >
                        <Form.Control
                            autoFocus
                            type="text"
                            // value={orderSerach}
                            placeholder="Search order with barcode"
                            className="me-2"
                            aria-label="Search"
                            // onChange={(e) => setorderSerach(e.target.value)}
                            style={{ width: "450px" }}
                        />
                    </Form>
                </div>

                <Card className="product-card" style={{ marginTop: "5%" }}>
                    <Card.Body style={{ backgroundColor: " #ffffff", borderRadius: "10px" }}>
                        <Row>
                            <Col xs={2}>
                                <img src="Frame 3.png" alt="Product" className="product-image" />
                            </Col>
                            <Col xs={3}>
                                <div className="product-details" style={{ textAlign: 'left' }}>
                                    <h5>Product Name</h5>
                                    <p>Color: Red</p>
                                    <p style={{
                                        color: "#000000", fontFamily: "DM Sans", fontSize: "18px",
                                        fontWeight: "700"
                                    }}>&#8377; 99.99</p>
                                </div>
                            </Col>
                            <Col xs={2}></Col>
                            <Col xs={2}></Col>
                            <Col xs={2}></Col>
                            <Col xs={1} className="d-flex align-items-center justify-content-center">
                                <div className="product-value" style={{
                                    color: "#000000", fontFamily: "DM Sans", fontSize: "32px",
                                    fontWeight: "700",
                                    lineHeight: "41.66px",
                                    position: "relative"
                                }}  >
                                    <span style={{
                                        color: "#000000", fontFamily: "DM Sans", fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "18px",
                                        position: 'absolute',
                                        top: "10px",
                                        left: "-8px",
                                    }} >x</span>199
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <div className="d-flex justify-content-end my-2" >
                    <input className="me-2 btn btn-primary" type="submit" value="Save" style={{ marginTop: "10%" }} />

                </div>

            </Layout>
        </div >
    );
}

export default Inventory;
