import React, { useState,useEffect,useRef } from 'react';
import axios from 'axios'
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import swal from "sweetalert";
import {Flex,Spin  } from 'antd';
import './App.css'
import { Select } from 'antd';
import baseURL from "./Config/configFile.js";
import cameraClickSound from "./Sound/cameraClick.mp3"
import orderScanSound from "./Sound/orderScanSound.mp3"
import orderNotFound from "./Sound/orderNotFound.wav"
import orderFound from "./Sound/orderFound.mp3"
import startVideo from "./Sound/startVideo.mp3"
import useSound from 'use-sound'
import Marquee from 'react-fast-marquee';
import { Alert } from 'antd';
import { message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Checkbox } from 'antd';
var mediarecorder=""
var mediarecorder1=""

function RecordVideo(props) {
  const [messageApi, contextHolder] = message.useMessage();
  const successCapture = () => {
    messageApi.open({
      type: 'success',
      content: 'Captured, We are processing your image/video',
      duration: 5,
    });
  };
  const [videoStarted,setvideoStarted]=useState(false)
  const [videoStarted1,setvideoStarted1]=useState(false)
  const [playSound] = useSound(cameraClickSound)
  const [playSound1] = useSound(orderScanSound)
  const [playSound2] = useSound(startVideo)
  const [playSound3] = useSound(orderNotFound)
  const [playSound4] = useSound(orderFound)




  const [video,setVideo]=useState(false)
  const [refresh,setrefresh]=useState(true)
  const [orderSerach, setorderSerach] = useState("");
  const [orderId, setorderId] = useState("");
  const [imglist,setimglist]=useState([])
  const [videolist,setvideolist]=useState([])
  const [flag, setflag] = useState(true);
  const [flag2, setflag2] = useState(true);
  const videoRef1 = useRef(null);
  const stopvideoRef1 = useRef(null);
  const stopvideoRef2 = useRef(null);
  const videoRef2 = useRef(null);
  const [mainorderId,setmainorderId]=useState("")
  const [mainorderId1,setmainorderId1]=useState("")
  const [alert,setalert]=useState("")
  const [videoCommand,setvideoCommand]=useState("")
  const [imageFilename1,setimageFilename1]=useState("")
  const [imageCommand1,setimageCommand1]=useState("")
  const [option1,setoption1]=useState("")
  const [option2,setoption2]=useState("")
  const [option3,setoption3]=useState("")
  const [option4,setoption4]=useState("")
  const [option5,setoption5]=useState("")
  const [option6,setoption6]=useState("")
  const [imageURL,setimageURL]=useState("")
  const [show, setShow] = useState(false);
  const [showButton, setshowButton]=useState(true);
  const [showButton1, setshowButton1]=useState(true);
  const [showButton2, setshowButton2]=useState(true);
  const [showButton3, setshowButton3]=useState(true);
  const [showButton4, setshowButton4]=useState(true);

  const [camId,setCamId]=useState("")
  const [camId2,setCamId2]=useState("")
  const [camIdList,setCamIdList]=useState([])
  const [cameraPermission,setCameraPermission]=useState(false)
  const [videoRecordedDate,setVideoRecordedDate]=useState([])
  const [imageRecordedDate,setImageRecordedDate]=useState([])
  const [cam1Video,setcam1Video]=useState(localStorage.getItem("VideoCam1")==undefined?false:(localStorage.getItem("VideoCam1")=='false'?false:true))
  const [cam1Image,setcam1Image]=useState(localStorage.getItem("ImageCam1")==undefined?false:(localStorage.getItem("ImageCam1")=='false'?false:true))
  const [cam2Video,setcam2Video]=useState(localStorage.getItem("VideoCam2")==undefined?false:(localStorage.getItem("VideoCam2")=='false'?false:true))
  const [cam2Image,setcam2Image]=useState(localStorage.getItem("ImageCam2")==undefined?false:(localStorage.getItem("ImageCam2")=='false'?false:true))
  const [NewRecordingStop,setNewRecordingStop]=useState(false)
  const [RecordningByScannerStarted,setRecordningByScannerStarted]=useState(false)
/* setcam1Image(localStorage.getItem("ImageCam1")==undefined?false:localStorage.getItem("ImageCam1"))
setcam1Video(localStorage.getItem("VideoCam1")==undefined?false:localStorage.getItem("VideoCam1"))
setcam2Image(localStorage.getItem("ImageCam2")==undefined?false:localStorage.getItem("ImageCam2"))
setcam2Video(localStorage.getItem("VideoCam2")==undefined?false:localStorage.getItem("VideoCam2")) */


  const onChangeVideoCam1 = (e) => {
    console.log(`checked = ${e.target.checked}`);
setcam1Video(e.target.checked)
localStorage.setItem("VideoCam1",e.target.checked)
  };
  const onChangeImageCam1 = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setcam1Image(e.target.checked)
    localStorage.setItem("ImageCam1",e.target.checked)
  };
  const onChangeVideoCam2 = (e) => {
    console.log(`checked = ${e.target.checked}`);
setcam2Video(e.target.checked)
localStorage.setItem("VideoCam2",e.target.checked)
  };
  const onChangeImageCam2 = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setcam2Image(e.target.checked)
    localStorage.setItem("ImageCam2",e.target.checked)
  };
  const handleShow = (url) =>{
setimageURL(url)
   setShow(true);
  }
  useEffect(() => {
setcam1Image((localStorage.getItem("ImageCam1")==undefined?false:(localStorage.getItem("ImageCam1")=='false'?false:true)))
setcam1Video(localStorage.getItem("VideoCam1")==undefined?false:(localStorage.getItem("VideoCam1")=='false'?false:true))
setcam2Image(localStorage.getItem("ImageCam2")==undefined?false:(localStorage.getItem("ImageCam2")=='false'?false:true))
setcam2Video(localStorage.getItem("VideoCam2")==undefined?false:(localStorage.getItem("VideoCam2")=='false'?false:true))
console.log("ImageCam1",(localStorage.getItem("ImageCam1")==undefined?false:(localStorage.getItem("ImageCam1")=='false'?false:true)))
          var camArray=[{value:"1",label:"Deselect"}]
    navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      devices.forEach((device) => {
        if(device.kind=='videoinput')
        {
        console.log(`${device.label} id = ${device.deviceId}`);
        camArray.push({value:device.deviceId,label:device.label})
        }
      });
      setCamIdList(camArray)
      console.log("List",camIdList)
    })
    .catch((err) => {
      console.error(`${err.name}: ${err.message}`);
    });
    if(localStorage.getItem("camId")!=""&&localStorage.getItem("camId")!="1"&&localStorage.getItem("camId")!=undefined)
    {
      setCamId(localStorage.getItem("camId"))
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: { exact: localStorage.getItem("camId") },width: 1920, height: 1080 }, audio: false})
      .then(stream => {
        let video = videoRef1.current;
        video.srcObject = stream;
        //stream.getTracks().forEach(track => track.stop())
        stopvideoRef1.current=stream

      })
      .catch(err => {
        console.error("error:", err);
      });
    }
    if(localStorage.getItem("camId2")!=""&&localStorage.getItem("camId2")!="1"&&localStorage.getItem("camId2")!=undefined)
    {
      setCamId2(localStorage.getItem("camId2"))
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: { exact: localStorage.getItem("camId2") },width: 1920, height: 1080 }, audio: false})
      .then(stream => {
        let video = videoRef2.current;
        video.srcObject = stream;
        stopvideoRef2.current=stream
        //stream.getTracks().forEach(track => track.stop())

      })
      .catch(err => {
        console.error("error:", err);
      });
    }
    
    
      /* return ()=>{
        console.log("unmount")
        stream1.getTracks().forEach(track => track.stop())

      } */
  }, [videoRef1,camId,cameraPermission,camId2]);
  const saveFilePhoto = async (type,valueOfCamId) => {
    var arr=imglist
    setrefresh(false)
    
    let stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: valueOfCamId },width: 1920, height: 1080 }, audio: false });

    const [track] = stream.getVideoTracks();
          const imageCapture = new ImageCapture(track);
  const blob = await imageCapture.takePhoto()
  .catch(err => console.error(err));
  
  if(blob){
  console.log(blob,"blob")
  const myFile = new File(
    [blob],
    "mypic",
    { type: 'image/png'}
  );
  console.log(myFile)
  var url = URL.createObjectURL(myFile);
  var tempLink = document.createElement('a');
tempLink.href = url;
tempLink.setAttribute('download',type+'.png');
  
  var obj={
    url:url,
    name:type,
    link:tempLink
  }
  arr.push(obj)
  stream.getTracks().forEach(track => track.stop())
  const fd = new FormData();
    fd.append('blob', myFile);
    fd.append('orderId', "img."+Date.now()+"."+mainorderId+"."+props.shop+".png");
    fd.append('shop', props.shop);
    fd.append('mainOrderId', mainorderId1);
    fd.append('fileName', Date.now());
    fd.append('staffName',localStorage.getItem("staffName"));  
try{
    const uploadResponse= await axios.post(`${baseURL}/uploadVideo`,fd,{headers: {
      'Content-Type': 'multipart/form-data'
    }})
      
      console.log(uploadResponse.data)
    const updateTagResponse= await axios.post(`${baseURL}/updateTag`,{
      shopName:localStorage.getItem("shop"),
      shopCode:localStorage.getItem("code"),
      orderId:mainorderId1,
      StaffName:(localStorage.getItem("staffName")).split(" ")[0]+"_"+((localStorage.getItem("staffName")).split(" ")[1]).split("")[0]
    })
      if(updateTagResponse.data.validated)
      {
        console.log("validate"," Data ",updateTagResponse.data.data)
      }
      else{
        console.log("not validated")
        playSound3()
        swal({
          title: "Error",
          text: "Order does not match with shopify",
          icon: "error",
          button: "Try again",
        });
      }
    }
    catch(err){
      console.log("err",err)
      playSound3()
      swal({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        button: "Try again",
      });
    }
    console.log("blob",blob)
  }
console.log("arr",arr)
setimglist(arr)
setrefresh(true)
successCapture()
}

const saveVideoNewCam1 =async(valueOfCamId)=>{
  var recordedChunks=[]
  if(!videoStarted)
  {
    var stream = await navigator.mediaDevices
    .getUserMedia({ video: { deviceId: { exact: valueOfCamId },width: 1920, height: 1080 }, audio: false })
    
      mediarecorder=new MediaRecorder(stream,{ mimeType: "video/webm; codecs=vp9" })
      /* setTimeout(()=>{
        mediarecorder.stop()
      },1000*60) */
    
    console.log("Video recording started")
  mediarecorder.start()
  setvideoStarted(true)
  }
  else
  {
    try{
      const updateTagResponse= await axios.post(`${baseURL}/updateTag`,{
        shopName:localStorage.getItem("shop"),
        shopCode:localStorage.getItem("code"),
        orderId:mainorderId1,
        StaffName:(localStorage.getItem("staffName")).split(" ")[0]+"_"+((localStorage.getItem("staffName")).split(" ")[1]).split("")[0]
      })
        if(updateTagResponse.data.validated)
        {
          console.log("validate"," Data ",updateTagResponse.data.data)
        }
        else{
          console.log("not validated")
          playSound3()
          swal({
            title: "Error",
            text: "Order does not match with shopify",
            icon: "error",
            button: "Try again",
          });
        }
      setVideo(false)
      setrefresh(false)
      console.log("Video recording stopped")
      mediarecorder.stop()
      mediarecorder.ondataavailable = (event)=>{
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
          console.log(event.data)
      }
      };
      mediarecorder.onstop = async ()=>{
        var blob = new Blob(recordedChunks, {
          type: 'video/webm'
      });
      console.log("new blob data",blob)
      setvideoStarted(false)
      const myFile = new File(
        [blob],
        "demo.mp4",
        { type: 'video/mp4' }
    );
      console.log(myFile)
      var url = URL.createObjectURL(myFile);
    setvideolist([url])
    const fd = new FormData();
      fd.append('blob', myFile);
      fd.append('orderId', "vi."+Date.now()+"."+mainorderId+"."+props.shop);
      fd.append('shop', props.shop);
      fd.append('mainOrderId', mainorderId1);
      fd.append('fileName', Date.now());
      fd.append('staffName',localStorage.getItem("staffName"));
      const uploadResponse= await axios.post(`${baseURL}/uploadVideo`,fd,{headers: {
        'Content-Type': 'multipart/form-data'
      }})
        setVideo(true)
      setrefresh(true)
        console.log(uploadResponse.data)
      console.log("blob",blob)
    }
  }
    catch(err){
      console.log("err",err)
      playSound3()
      swal({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        button: "Try again",
      });
    }
  
   
  }
 
}
const saveVideoNewCam2 =async(valueOfCamId)=>{
  var recordedChunks=[]
  if(!videoStarted1)
  {
    var stream = await navigator.mediaDevices
    .getUserMedia({ video: { deviceId: { exact: valueOfCamId },width: 1920, height: 1080 }, audio: false })
      mediarecorder1=new MediaRecorder(stream,{ mimeType: "video/webm; codecs=vp9" }) 
    console.log("Video recording started")
  mediarecorder1.start()
  setvideoStarted1(true)
  }
  else
  {
    console.log("Video recording stopped")

    try{
      const updateTagResponse= await axios.post(`${baseURL}/updateTag`,{
        shopName:localStorage.getItem("shop"),
        shopCode:localStorage.getItem("code"),
        orderId:mainorderId1,
        StaffName:(localStorage.getItem("staffName")).split(" ")[0]+"_"+((localStorage.getItem("staffName")).split(" ")[1]).split("")[0]
      })
        if(updateTagResponse.data.validated)
        {
          console.log("validate"," Data ",updateTagResponse.data.data)
        }
        else{
          console.log("not validated")
          playSound3()
          swal({
            title: "Error",
            text: "Order does not match with shopify",
            icon: "error",
            button: "Try again",
          });
        }
      setVideo(false)
      setrefresh(false)
      console.log("Video recording stopped")
      mediarecorder1.stop()
      mediarecorder1.ondataavailable = (event)=>{
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
          console.log(event.data)
      }
      };
      mediarecorder1.onstop = async ()=>{
        var blob = new Blob(recordedChunks, {
          type: 'video/webm'
      });
      console.log("new blob data",blob)
      setvideoStarted1(false)
      const myFile = new File(
        [blob],
        "demo.mp4",
        { type: 'video/mp4' }
    );
      console.log(myFile)
      var url = URL.createObjectURL(myFile);
    setvideolist([url])
    
    const fd = new FormData();
      fd.append('blob', myFile);
      fd.append('orderId', "vi."+Date.now()+"."+mainorderId+"."+props.shop);
      fd.append('shop', props.shop);
      fd.append('mainOrderId', mainorderId1);
      fd.append('fileName', Date.now());
      fd.append('staffName',localStorage.getItem("staffName"));
      const uploadResponse= await axios.post(`${baseURL}/uploadVideo`,fd,{headers: {
        'Content-Type': 'multipart/form-data'
      }})
        setVideo(true)
      setrefresh(true)
  
        console.log(uploadResponse.data)
      console.log("blob",blob)
    }
  }
    catch(err){
      console.log("err",err)
      playSound3()
      swal({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        button: "Try again",
      });
    }
  
   
  }
 
}
  
  async function searchItems() {
    const mainID=orderSerach
    console.log(orderSerach.replace(/[^a-zA-Z0-9]/g, ''));
    const searchValue = orderSerach.replace(/[^a-zA-Z0-9]/g, '');
   console.log("hello",searchValue)
    if (searchValue !== "") {
      if(videoCommand=="")
      {
        playSound3()
        swal({
          title: "Alert",
          text: "Please ask Admin to save and create barcode settings first.",
          timer: 4000,
        });
      }
      else{
        if(searchValue!=videoCommand&&searchValue!=imageCommand1&&searchValue!=option1&&searchValue!=option2&&searchValue!=option3&&searchValue!=option4)
          {
            /* playSound1() */
            if(flag2)
            {
              setalert("")
            if(flag==false)
            {
            }
            setVideo(false)  
            setmainorderId1(mainID)
            axios.post(`${baseURL}/validateOrderId`,{
              shopName:localStorage.getItem("shop"),
              shopCode:localStorage.getItem("code"),
              orderId:mainID,
            }).then((res)=>{
              if(res.data.validated)
              {

                console.log("validate"," Data ",res.data.data)
                setmainorderId(searchValue)
               /*  playSound4() */
               playSound1()
                setimglist([])
            setrefresh(false)
            setTimeout(()=>{
              setrefresh(true)
            },2000)
            setvideolist([])
            axios.post(`${baseURL}/checkOrderExist`,{
              orderId:mainID.replace(/[^a-zA-Z0-9]/g, ''),
            }).then((res)=>{
              setImageRecordedDate(res.data.image)
              setVideoRecordedDate(res.data.video)
            })
            .catch((err)=>{
              console.log(err)
              playSound3()
              swal({
                title: "Error",
                text: "Order not containing any recorded data",
                icon: "error",
                button: "Try again",
              });
            })
              }
              else{
                playSound3()
                console.log("not validated")
                swal({
                  title: "Error",
                  text: "Order does not match with shopify",
                  icon: "error",
                  button: "Try again",
                });
                setalert(mainID+" Order does not match with shopify")
              }
            })
            .catch((err)=>{
              console.log("error")

            })
            setorderSerach("")
            setflag2(false)
          }
          else{
            setalert("")
            if(flag==false)
            {
            }
            setVideo(false)
            setmainorderId1(mainID)
            axios.post(`${baseURL}/validateOrderId`,{
              shopName:localStorage.getItem("shop"),
              shopCode:localStorage.getItem("code"),
              orderId:mainID,
            }).then((res)=>{
              if(res.data.validated)
              {
              /*   playSound4() */
              playSound1()
                console.log("validate"," Data ",res.data.data)
                setmainorderId(searchValue)
                setimglist([])
                setrefresh(false)
                setTimeout(()=>{
                  setrefresh(true)
                },2000)
                setvideolist([])
                axios.post(`${baseURL}/checkOrderExist`,{
                  orderId:mainID.replace(/[^a-zA-Z0-9]/g, ''),
                }).then((res)=>{
                  setImageRecordedDate(res.data.image)
                  setVideoRecordedDate(res.data.video)
                })
                .catch((err)=>{
                  console.log(err)
                  playSound3()
                  swal({
                    title: "Error",
                    text: "Order not containing any recorded data",
                    icon: "error",
                    button: "Try again",
                  });
                })
              }
              else{
                playSound3()
                console.log("not validated")
                swal({
                  title: "Error",
                  text: "Order does not match with shopify",
                  icon: "error",
                  button: "Try again",
                });
                setalert(mainID+" Order does not match with shopify")
              }
            })
            .catch((err)=>{
              console.log("error")

            })
            setorderSerach("")
            setflag2(true)
          }
          }
          else{
      if(searchValue==videoCommand&&mainorderId!=""&&(camId!=""&&camId!="1")&&(camId2!=""&&camId2!="1"))
      {
      if(flag)
      {
        setflag(false)
        setVideo(false)
        
      //setorderId(videoFileName)
      playSound2()
      setRecordningByScannerStarted(true)
      setshowButton(false)
      setorderSerach("")
       await saveVideoNewCam1(camId)
       await saveVideoNewCam2(camId2)

    
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton(true)
        setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(false)
      await saveVideoNewCam1(camId)
      await saveVideoNewCam2(camId2)


      

      }
    }
    else if(searchValue==imageCommand1&&mainorderId!=""&&(camId!=""&&camId!="1")&&(camId2!=""&&camId2!="1"))
    {
      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
      setorderSerach("")
      playSound()
      await saveFilePhoto(Date.now(),camId)
      await saveFilePhoto(Date.now(),camId2)
    }
    else if(searchValue==option1&&mainorderId!=""&&(camId!=""&&camId!="1")&&(camId2!=""&&camId2!="1"))
    {
      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton1(false)
      setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(true)
      await saveVideoNewCam1(camId)
      playSound()
      await saveFilePhoto(Date.now(),camId2)
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton1(true)
        setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(false)
        await saveVideoNewCam1(camId)
        
      }
    }
    else if(searchValue==option2&&mainorderId!=""&&(camId!=""&&camId!="1")&&(camId2!=""&&camId2!="1"))
    {
      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton2(false)
      setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(true)
      await saveVideoNewCam1(camId2)
      playSound()
      await saveFilePhoto(Date.now(),camId)
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton2(true)
        setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(false)
        await saveVideoNewCam1(camId2)
      }


    }
    else if(searchValue==option3&&mainorderId!=""&&((camId!=""&&camId!="1")))
    {
      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
      if((camId!=""&&camId!="1"))
      {
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton3(false)
      setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(true)
      await saveVideoNewCam1(camId)
    /*   await saveFilePhoto(Date.now(),camId) */
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton3(true)
        setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(false)
        await saveVideoNewCam1(camId)
      }
    }
    /* else
      {
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton(false)
      setorderSerach("")
      await saveVideoNewCam1(camId2)
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton(true)
        setorderSerach("")
        await saveVideoNewCam1(camId2)
      }
    } */
      


    }
    else if(searchValue==option4&&mainorderId!=""&&((camId!=""&&camId!="1")))
    {
      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
     
      if((camId!=""&&camId!="1"))
      {
 
      setorderSerach("")
      playSound()
      await saveFilePhoto(Date.now(),camId)
      }
     /*  else
      {
        setorderSerach("")
        await saveFilePhoto(Date.now(),camId2)
      } */
    }
    else if(searchValue==option5&&mainorderId!=""&&((camId2!=""&&camId2!="1")))
    {
      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
      if((camId2!=""&&camId2!="1"))
      {
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton3(false)
      setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(true)
      await saveVideoNewCam1(camId2)
    /*   await saveFilePhoto(Date.now(),camId) */
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton3(true)
        setorderSerach("")
      playSound2()
      setRecordningByScannerStarted(false)
        await saveVideoNewCam1(camId2)
      }
    }
   /*  else
      {
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton(false)
      setorderSerach("")
      await saveVideoNewCam1(camId2)
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton(true)
        setorderSerach("")
        await saveVideoNewCam1(camId2)
      }
    } */
      


    }
    else if(searchValue==option6&&mainorderId!=""&&((camId2!=""&&camId2!="1")))
    {
      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
     
      if((camId2!=""&&camId2!="1"))
      {
 
      setorderSerach("")
      playSound()
      await saveFilePhoto(Date.now(),camId2)
      }
     /*  else
      {
        setorderSerach("")
        await saveFilePhoto(Date.now(),camId2)
      } */
    }
    else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your first camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else if(camId2==""||camId2=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your second camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else{
      playSound3()
      setalert("No Order Id associated with this scan, Please scan order id first.")
    }
  }
    }
      }
     else {
   
    }
  }
  const Option1Click=async()=>{
    if(mainorderId!=""&&(camId!=""&&camId!="1")&&(camId2!=""&&camId2!="1"))
    {

    console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton1(false)
      setorderSerach("")
      await saveVideoNewCam1(camId)
      await saveFilePhoto(Date.now(),camId2)
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton1(true)
        setorderSerach("")
        await saveVideoNewCam1(camId)
        
      }
    }
    else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your first camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else if(camId2==""||camId2=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your second camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else{
      playSound3()
      setalert("No Order Id associated with this scan, Please scan order id first.")
    }

  }
  const Option2Click=async()=>{
    if(mainorderId!=""&&(camId!=""&&camId!="1")&&(camId2!=""&&camId2!="1"))
    {

      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton2(false)
      setorderSerach("")
      await saveVideoNewCam1(camId2)
      await saveFilePhoto(Date.now(),camId)
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton2(true)
        setorderSerach("")
        await saveVideoNewCam1(camId2)
      }
    }
    else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your first camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else if(camId2==""||camId2=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your second camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else{
      playSound3()
      setalert("No Order Id associated with this scan, Please scan order id first.")
    }
  }
  const Option3Click=async()=>{
    if(mainorderId!=""&&((camId!=""&&camId!="1"))){
      console.log("imageFilename1",imageFilename1)
      console.log("orderid",orderId)
      if((camId!=""&&camId!="1"))
      {
      if(flag)
      {
        setflag(false)
        setVideo(false)
      setshowButton3(false)
      setorderSerach("")
      await saveVideoNewCam1(camId)
    /*   await saveFilePhoto(Date.now(),camId) */
      }
      else
      {
        console.log("hello2")
        setflag(true)
        setVideo(false)
        setshowButton3(true)
        setorderSerach("")
        await saveVideoNewCam1(camId)
      }
    }
    else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your first camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else{
      playSound3()
      setalert("No Order Id associated with this scan, Please scan order id first.")
    }
    
  }
  else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your first camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else{
      playSound3()
      setalert("No Order Id associated with this scan, Please scan order id first.")
    }
}
const Option4Click=async()=>{
  if(mainorderId!=""&&((camId!=""&&camId!="1")))
  {
    console.log("imageFilename1",imageFilename1)
    console.log("orderid",orderId)
   
    if((camId!=""&&camId!="1"))
    {

    setorderSerach("")
    await saveFilePhoto(Date.now(),camId)
    }
   /*  else
    {
      setorderSerach("")
      await saveFilePhoto(Date.now(),camId2)
    } */
  }
  else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your first camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else{
      playSound3()
      setalert("No Order Id associated with this scan, Please scan order id first.")
    }
}
const Option5Click=async()=>{
  if(mainorderId!=""&&((camId2!=""&&camId2!="1"))){
    console.log("imageFilename1",imageFilename1)
    console.log("orderid",orderId)
    if((camId2!=""&&camId2!="1"))
    {
    if(flag)
    {
      setflag(false)
      setVideo(false)
    setshowButton4(false)
    setorderSerach("")
    await saveVideoNewCam1(camId2)
  /*   await saveFilePhoto(Date.now(),camId) */
    }
    else
    {
      console.log("hello2")
      setflag(true)
      setVideo(false)
      setshowButton4(true)
      setorderSerach("")
      await saveVideoNewCam1(camId2)
    }
  }
  else if(camId2==""||camId2=="1")
  {
    playSound3()
    swal({
      title: "Error",
      text: "select your second camera first",
      icon: "error",
      button: "Try again",
    });
  }
  /* else if(camId2==""||camId2=="1")
  {
    swal({
      title: "Error",
      text: "select your second camera first",
      icon: "error",
      button: "Try again",
    });
  } */
  else{
    playSound3()
    setalert("No Order Id associated with this scan, Please scan order id first.")
  }
  
}
/* else if(camId=="")
  {
    swal({
      title: "Error",
      text: "select your first camera first",
      icon: "error",
      button: "Try again",
    });
  } */
  else if(camId2==""||camId2=="1")
  {
    playSound3()
    swal({
      title: "Error",
      text: "select your second camera first",
      icon: "error",
      button: "Try again",
    });
  }
  else{
    playSound3()
    setalert("No Order Id associated with this scan, Please scan order id first.")
  }
}
const Option6Click=async()=>{
if(mainorderId!=""&&((camId2!=""&&camId2!="1")))
{
  console.log("imageFilename1",imageFilename1)
  console.log("orderid",orderId)
 
  if((camId2!=""&&camId2!="1"))
  {

  setorderSerach("")
  await saveFilePhoto(Date.now(),camId2)
  }
 /*  else
  {
    setorderSerach("")
    await saveFilePhoto(Date.now(),camId2)
  } */
}
/* else if(camId=="")
  {
    swal({
      title: "Error",
      text: "select your first camera first",
      icon: "error",
      button: "Try again",
    });
  } */
  else if(camId2==""||camId2=="1")
  {
    playSound3()
    swal({
      title: "Error",
      text: "select your second camera first",
      icon: "error",
      button: "Try again",
    });
  }
  else{
    playSound3()
    setalert("No Order Id associated with this scan, Please scan order id first.")
  }
}
  useEffect(()=>{
    axios.post(`${baseURL}/getSettingData`,{store_name:localStorage.getItem("shop")})
    .then((res)=>{
        console.log("data",res.data)
        if(res.data.data.length==0)
        {
          playSound3()
          swal({
            title: "Alert",
            text: "Please ask Admin to save settings first.",
            timer: 4000,
          });
        }
        else{
           /*  var jsonData=JSON.parse(res.data.data.json_data) */
           /*  setvideoFileName(jsonData.videoFilename) */
            setvideoCommand(res.data.data.videoCommand)
            /* setimageFilename1(jsonData.imageFilename1)  */
            setimageCommand1(res.data.data.imageCommand1)
            setoption1(res.data.data.option1)
            setoption2(res.data.data.option2)
            setoption3(res.data.data.option3)
            setoption4(res.data.data.option4)
            setoption5(res.data.data.option5)
            setoption6(res.data.data.option6)
        }

    })
    .catch((err)=>{
        console.log("err",err)
    })

  },[])
  useEffect(()=>{

  },[imglist])
  const StartRecording = async ()=>{
    if(mainorderId!="" && mainorderId!=" " && mainorderId!="  "&&(camId!=""&&camId!="1")&&(camId2!=""&&camId2!="1"))
    {
    setshowButton(false)
      await saveVideoNewCam1(camId)
      await saveVideoNewCam2(camId2)

    }
    else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else if(camId2==""||camId2=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your second camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else{
      playSound3()
      swal({
        title: "Error",
        text: "Please provide a valid order number",
        icon: "error",
        button: "Try again",
      });
    }
  }
  const StopRecording = async ()=>{
    if(mainorderId!="" && mainorderId!=" " && mainorderId!="  ")
    {
    setshowButton(true)
    await saveVideoNewCam1(camId)
    await saveVideoNewCam2(camId2)
  }
  else
  {
    playSound3()
    swal({
      title: "Error",
      text: "Please provide a valid order number",
      icon: "error",
      button: "Try again",
    });
  }
  }
  const CaptureImage = async ()=>{
    if(mainorderId!="" && mainorderId!=" " && mainorderId!="  "&&(camId!=""&&camId!="1")&&(camId2!=""&&camId2!="1"))
    {
      await saveFilePhoto(Date.now(),camId)
      await saveFilePhoto(Date.now(),camId2)
  }
  else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else if(camId2==""||camId2=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your second camera first",
        icon: "error",
        button: "Try again",
      });
    }
  else
  {
    playSound3()
    swal({
      title: "Error",
      text: "Please provide a valid order number",
      icon: "error",
      button: "Try again",
    });
  }
  }
  const CaptureImageNewCheckBoxFunctionality = async ()=>{
    if(mainorderId!="" && mainorderId!=" " && mainorderId!="  "&&((camId!=""&&camId!="1")||(camId2!=""&&camId2!="1")))
    {
      if((cam1Image&&(camId!=""&&camId!="1"))||(cam2Image&&(camId2!=""&&camId2!="1")))
      {
        playSound()
      }
      if(cam1Image&&(camId!=""&&camId!="1"))
      {
        await saveFilePhoto(Date.now(),camId)
      }
     if(cam2Image&&(camId2!=""&&camId2!="1"))
     {
      await saveFilePhoto(Date.now(),camId2)
     }
      
  }
  else if (mainorderId=="" || mainorderId==" " || mainorderId=="  ")
  {
    playSound3()
    swal({
      title: "Error",
      text: "Please provide a valid order number",
      icon: "error",
      button: "Try again",
    });
  }
  else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else if(camId2==""||camId2=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your second camera first",
        icon: "error",
        button: "Try again",
      });
    }
  
  }
  const RecordVideoNewCheckBoxFunctionality = async ()=>{
    if(mainorderId!="" && mainorderId!=" " && mainorderId!="  "&&((camId!=""&&camId!="1")||(camId2!=""&&camId2!="1")))
    {
      /* if((cam1Video&&(camId!=""&&camId!="1"))||(cam2Video&&(camId2!=""&&camId2!="1")))
      {
        playSound()
      } */
      playSound2()
      if(cam1Video&&(camId!=""&&camId!="1"))
      {
        await saveVideoNewCam1(camId)
        setNewRecordingStop(true)
      }
     if(cam2Video&&(camId2!=""&&camId2!="1"))
     {
      await saveVideoNewCam2(camId2)
      setNewRecordingStop(true)
     }
      
  }
  else if (mainorderId=="" || mainorderId==" " || mainorderId=="  ")
  {
    playSound3()
    swal({
      title: "Error",
      text: "Please provide a valid order number",
      icon: "error",
      button: "Try again",
    });
  }
  else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else if(camId2==""||camId2=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your second camera first",
        icon: "error",
        button: "Try again",
      });
    }
  
  }
  const StopVideoNewCheckBoxFunctionality = async ()=>{
    if(mainorderId!="" && mainorderId!=" " && mainorderId!="  "&&((camId!=""&&camId!="1")||(camId2!=""&&camId2!="1")))
    {
      /* if((cam1Video&&(camId!=""&&camId!="1"))||(cam2Video&&(camId2!=""&&camId2!="1")))
      {
        playSound()
      } */
      playSound2()
      if(cam1Video&&(camId!=""&&camId!="1"))
      {
        await saveVideoNewCam1(camId)
        setNewRecordingStop(false)
      }
     if(cam2Video&&(camId2!=""&&camId2!="1"))
     {
      await saveVideoNewCam2(camId2)
      setNewRecordingStop(false)
     }
      
  }
  else if (mainorderId=="" || mainorderId==" " || mainorderId=="  ")
  {
    playSound3()
    swal({
      title: "Error",
      text: "Please provide a valid order number",
      icon: "error",
      button: "Try again",
    });
  }
  else if(camId==""||camId=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your camera first",
        icon: "error",
        button: "Try again",
      });
    }
    else if(camId2==""||camId2=="1")
    {
      playSound3()
      swal({
        title: "Error",
        text: "select your second camera first",
        icon: "error",
        button: "Try again",
      });
    }
  
  }
  const handleChange = (value,array) => {
    console.log(`selected ${value}`);
    console.log(`selected array ${array}`);
    localStorage.setItem("camIdLabel",array.label)
    if(value=="1")
    {
      if(camId!="1")
      {
        stopvideoRef1.current.getTracks().forEach(track => track.stop())
        localStorage.setItem("camId","1")
      }
      
      setCamId("1")
      localStorage.setItem("camId","1")
    }
    else
    {
    setCamId(value)
    localStorage.setItem("camId",value)
    }
  };
  const handleChangeCam2 = (value,array) => {
    console.log(`selected ${value}`);
    console.log(`selected array ${array.label}`);
    localStorage.setItem("camId2Label",array.label)
    if(value=="1")
    {
      if(camId2!="1")
      {
        stopvideoRef2.current.getTracks().forEach(track => track.stop())
        localStorage.setItem("camId2","1")
      }
      
      setCamId2("1")
      localStorage.setItem("camId2","1")
    }
    else{
    setCamId2(value)
    localStorage.setItem("camId2",value)
    }
  };
  return (
    <div>
      {contextHolder}
    
    <div>
    <h6 >Current OrderID: {mainorderId==""?"None":mainorderId}</h6>
      <hr/>
      <div className="d-flex justify-content-start">
                <Form
                  className="mx-1"
                  onSubmit={(e) => {
                    e.preventDefault();
                    
                    searchItems();
                    
                  }}
                >
                  <Form.Control
                    autoFocus
                    type="text"
                    value={orderSerach}
                    placeholder="Scan order with barcode"
                    className="me-2"
                    aria-label="Scan your order"
                    onChange={(e) => setorderSerach(e.target.value)}
                    style={{width:"450px"}}
                  />
                </Form>
                <div>
                <Select
      defaultValue={localStorage.getItem("camIdLabel")==undefined?"Select Cam 1":localStorage.getItem("camIdLabel")}
      style={{
        width: 320,
      }}
      onChange={handleChange}
      options={camIdList}
    />
    <br/>
    {  localStorage.getItem("camId")=="1"||localStorage.getItem("camId")==undefined? (<div></div>):(<div>
    <Checkbox onChange={onChangeVideoCam1} checked={cam1Video}>Video</Checkbox>
    <br/>
    <Checkbox onChange={onChangeImageCam1} checked={cam1Image}>Image</Checkbox>
    </div>)}
    </div>
    <div>
    <Select
      defaultValue={localStorage.getItem("camId2Label")==undefined?"Select Cam 2":localStorage.getItem("camId2Label")}
      style={{
        width: 320,
      }}
      onChange={handleChangeCam2}
      options={camIdList}
    /> 
     <br/>
     {  localStorage.getItem("camId2")=="1"||localStorage.getItem("camId2")==undefined? (<div></div>):(<div>
    <Checkbox onChange={onChangeVideoCam2} checked={cam2Video}>Video</Checkbox>
    <br/>
    <Checkbox onChange={onChangeImageCam2} checked={cam2Image}>Image</Checkbox>
    </div>)}
    </div> 
    <Button type='primary' className='mx-1' block onClick={CaptureImageNewCheckBoxFunctionality} >Capture</Button>
    {!RecordningByScannerStarted?
    (NewRecordingStop==false?<Button type='primary' className='mx-1' block onClick={RecordVideoNewCheckBoxFunctionality} >Record</Button>:<Button type='primary' danger className='mx-1' block onClick={StopVideoNewCheckBoxFunctionality}>Stop</Button>)
  :<Button type='primary' danger className='mx-1' block>Recording</Button>}
              </div>
          

              <hr/>
              <h6 style={{color:"red"}}>{alert}</h6>
              {/* <div className='d-flex justify-content-between'>
                {(showButton&&(showButton1&& showButton2 && showButton3&&showButton4))?(
                <Button type="primary" className='mx-1' block onClick={StartRecording}>
                Recording from both cam
              </Button>):("")}
              {(!showButton&&showButton1&&showButton2&&showButton3&&showButton4)?(
              <Button type="primary" danger className='mx-1' block onClick={StopRecording}>
              Stop Recording
            </Button>):""}
            <Button type="primary" className='mx-1' block onClick={CaptureImage}>
            Capture Image from both cam
            </Button>
            {(showButton1&&showButton&& showButton2 && showButton3&&showButton4)?(
                <Button type="primary" className='mx-1' block onClick={Option1Click}>
                Cam 1 for video and Cam 2 for image
              </Button>):("")}
              {((!showButton1)&&showButton&&showButton2&&showButton3&&showButton4)?(
              <Button type="primary" danger className='mx-1' block onClick={Option1Click}>
              Stop Recording
            </Button>):""}
            {(showButton2&&showButton1&& showButton && showButton3&&showButton4)?(
                <Button type="primary" className='mx-1' block onClick={Option2Click}>
                Cam 2 for video and Cam 2 for image
              </Button>):("")}
              {(!showButton2&&showButton1&&showButton&&showButton3&&showButton4)?(
              <Button type="primary" danger className='mx-1' block onClick={Option2Click}>
              Stop Recording
            </Button>):""}
            {showButton3&&showButton1&& showButton2 && showButton&&showButton4?(
                <Button type="primary" className='mx-1' block onClick={Option3Click}>
                Capture video from cam one
              </Button>):("")}
              {!showButton3&&showButton1&&showButton2&&showButton&&showButton4?(
              <Button type="primary" danger className='mx-1' block onClick={Option3Click}>
              Stop Recording
            </Button>):""}
            <Button type="primary" className='mx-1' block onClick={Option4Click}>
            Capture image from cam one
            </Button>
                </div>
                <div className='d-flex'>
            {showButton4&&showButton1&& showButton2 && showButton&&showButton3?(
                <Button type="primary" className='mx-1 my-1' style={{width:"210px"}} block onClick={Option5Click}>
                Capture video from cam two
              </Button>):("")}
              {!showButton4&&showButton1&&showButton2&&showButton&&showButton3?(
              <Button type="primary" danger className='mx-1 my-1' style={{width:"210px"}} block onClick={Option5Click}>
              Stop Recording
            </Button>):""}
            <Button type="primary" className='mx-1 my-1' style={{width:"210px"}} block onClick={Option6Click}>
            Capture image from cam two
            </Button>
                </div> */}

    <hr/>
              <Flex wrap="wrap" gap="small">
  <div>
   {  localStorage.getItem("camId")=="1"||localStorage.getItem("camId")==undefined?<div></div>:<video autoPlay ref={videoRef1} width="600px" />} 
  </div>
  <div>
  {localStorage.getItem("camId2")=="1"||localStorage.getItem("camId2")==undefined?<div></div>:<video autoPlay ref={videoRef2} width="600px" />}
  </div>
</Flex>
              <Row>
              <Flex wrap="wrap" gap="small">            
      <div>
      {videolist.map((item)=>{
        return (
        <video width="500" height="300" controls>
    <source src={item} type="video/mp4" />
    </video> )
      })}
      </div>
      </Flex>
</Row>
<Flex wrap="wrap" gap="small">
  <div>
<hr/>
      {refresh==true?(
      <Flex wrap="wrap" gap="small">
      {imglist.map((item,index)=>{
        return (
          <div className='me-2'>
        <img src={item.url} width="200px" height="150px" onClick={()=>handleShow(item.url)}></img>
        <h5>{item.name}</h5>
        <button onClick={()=>item.link.click()}>Download</button>
        </div>
        )
      })}
     </Flex>
      ): <center><Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
      }
    /></center>}
      </div>
      </Flex>
    </div>
    <div>
      {(videoRecordedDate.length>0 || imageRecordedDate.length>0)?<Alert message="Data fetched" type="success" showIcon />:""}
  {/* <Alert
    banner
    message={
      <Marquee pauseOnHover gradient={false}>
        Please scan order to see Previously Recorded Data.
      </Marquee>
    }
  /> */}
  <br/>
      <Flex wrap="wrap" gap="small">
   {videoRecordedDate.map((item,i)=>{
    return ( 
    <div>
      <div className='d-flex flex-wrap'>
        <h6>{item.file_name}</h6>
        </div>
    <video width="500" height="300" controls>
    <source src={`${baseURL}/images/${item.order_number}`} type="video/mp4" />
    </video>
    </div>)
   })}
   <div className='d-flex flex-wrap'>
   {imageRecordedDate.map((item,i)=>{
    return ( 
      <div className='m-2 p-2' style={{boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"}}>
        <div className='d-flex flex-wrap'>
        <h6>{"Image-"}{i+1}{"_"}{item.file_name}</h6>
        </div>
    <img src={`${baseURL}/images/${item.order_number}`} className='m-2' width="280px" height="230px" style={{cursor:"pointer"}}/>
    </div>
    )
   })}
   </div>
  </Flex>
    </div>
    </div>
  );
}


export default RecordVideo;