import React from 'react'
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Label, Row } from "react-bootstrap";
import Layout from "../Layout/Layout.js";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Pagination } from "antd";
import { Drawer, Flex, Button } from "antd";
import { Radio, Space } from "antd";
import { DatePicker } from "antd";
import baseURL from "../Config/configFile.js";
import { Select } from "antd";
import { Tag } from "antd";
import { Modal as AntModalCustomFilter } from "antd";
import { Input } from "antd";
import { Typography } from "antd";
import EditFilled from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { Alert, Spin } from 'antd';
const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };
  const content = <div style={contentStyle} />;
function PickListLandingPage() {
    const location = useLocation()
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const jsonData1 = searchParams.get('checkedOrderArray');
  const checkedOrderArray = jsonData1 ? JSON.parse(decodeURIComponent(jsonData1)) : [];
  const jsonData3 = searchParams.get('staffArrayPopup');
  const staffArrayPopup = jsonData3 ? JSON.parse(decodeURIComponent(jsonData3)) : [];

  const [pickListAssignUserID, setpickListAssignUserID] = useState("");
  const [pickListAssignUserName, setpickListAssignUserName] = useState("");
  const [
    refreshModal,
    setrefreshModal,
  ] = useState(true);

  const handlePickListAssignOrder=(value,label)=>{
    console.log("value",value,label)
    
    setpickListAssignUserName(label.label)
    setpickListAssignUserID(label.value)
  }
    const handleGeneratePickListPopUp = async () => {
        //setShowAssign(true);
        if (checkedOrderArray.length > 0) {
        setrefreshModal(false);
          var res = await axios.post(`${baseURL}/getProductPickList`, {
            shop: localStorage.getItem("shop"),
            shopCode: localStorage.getItem("code"),
            orderIdArray: checkedOrderArray,
            assignBy: localStorage.getItem("staffName").split(" ")[0],
          }); 
          console.log("Pick List Data", res.data);
          const uniqueArray = res.data.data.filter((obj, index, self) =>
            index === self.findIndex((o) => o.id === obj.id)
          );
          console.log("Unique Pick List Data", uniqueArray);
          swal({
            title: "Pick List",
            text: "Pick list generation process initiated successfully",
            icon: "success",
            button: "Done",
          });
        setrefreshModal(true);

          navigate("/pickListPage", {
            replace: false,
            state: { completeOrderList: uniqueArray,checkedOrderArray:checkedOrderArray,type:"onlyPicklist" },
          });
        } else {
          swal({
            title: "Warning!",
            text: "Please select one or multiple orders",
            icon: "warning",
            button: "Done",
          });
        }
      };
      const generatePickListAndAssign=()=>{
        setrefreshModal(false);
         axios
           .post(`${baseURL}/assignOrderToSpecificPerson`, {
             shop: localStorage.getItem("shop"),
             shopCode: localStorage.getItem("code"),
             orderIdArray: checkedOrderArray,
             personId: pickListAssignUserID,
             assignToName: pickListAssignUserName.split(" ")[0],
             assignBy: localStorage.getItem("staffName").split(" ")[0],
           })
           .then(async(res) => {
             if (res.data.statusCode == 200) {
               var res1 = await axios.post(`${baseURL}/getProductPickList`, {
                 shop: localStorage.getItem("shop"),
                 shopCode: localStorage.getItem("code"),
                 orderIdArray: checkedOrderArray,
                 assignBy: localStorage.getItem("staffName").split(" ")[0],
               });
               console.log("Pick List Data", res1.data);
               const uniqueArray = res1.data.data.filter((obj, index, self) =>
                index === self.findIndex((o) => o.id === obj.id)
              );
              console.log("Unique Pick List Data", uniqueArray);
              /*  const jsonData = encodeURIComponent(JSON.stringify(checkedOrderArray));
               const url = `/pickListPage?data=${jsonData}`;
               const newTab = window.open(url, '_blank');
               if (newTab) {
                 newTab.focus();
               } else {
                 console.error('Failed to open new tab');
               } */
               setrefreshModal(true);
     
               /* swal({
                 title: "Pick List",
                 text: "PickList generated successfully",
                 icon: "success",
                 button: "Done",
               }); */
               navigate("/pickListPage", {
                 replace: false,
                 state: { completeOrderList: uniqueArray,checkedOrderArray:checkedOrderArray,type:"assignAndPicklist",pickListAssignUserID:pickListAssignUserID,pickListAssignUserName:pickListAssignUserName }
               });
               swal({
                 title: "Pick List",
                 text: "Orders Assigned and Pick list Generation process initiated successfully.",
                 icon: "success",
                 button: "Done",
               });
             } else {
               swal({
                 title: "Error",
                 text: "Something went wrong",
                 icon: "error",
                 button: "Done",
               });
             }
           })
           .catch((err) => {
             console.log(err);
           });
       }
  return (
    <div>
        <Layout>
        
      {refreshModal?(
      <div>
        <h5>Selected Orders</h5>
        <div className='d-flex'>
        {checkedOrderArray.map((item,index)=>{
            return <b>{item},&nbsp;</b>
        })}
        </div>
                <hr />
                <h5>Only generate Picklist PDF</h5>
                <p>Picklist will be generated for selected orders without changing order assignment.</p>
                <Button type="primary" onClick={handleGeneratePickListPopUp}>Generate Picklist PDF</Button>
                <br/>
                <br/>
                
                <h5>Generate picklist PDF and assign orders</h5>
                <p>Picklist will be generated for selected orders and selected orders can be assigned to a specific user.</p>
                <Select
                  allowClear
                  style={{
                    width: "30%",
                  }}
                  placeholder="Please Select"
                  onChange={handlePickListAssignOrder}
                  options={staffArrayPopup}
                />
                <br/>
                <br/>
                {pickListAssignUserID!=""?<Button type="primary" onClick={generatePickListAndAssign}>Assign & Generate Picklist PDF</Button>:""}
  
  
               
                </div>
      ):(<Spin tip="Pick List generation in progress" size="large">
      {content}
    </Spin>)}
                </Layout>
                
    </div>
  )
}

export default PickListLandingPage