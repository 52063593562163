import React, { useState,useEffect  }  from 'react';
import {useLocation,useParams} from "react-router-dom";
import { Button, Row,Col } from 'react-bootstrap';
import Layout from "../Layout/Layout.js"
import axios from "axios"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import baseURL from "../Config/configFile.js";

function ProductPage() {
  /* const search = useLocation().search;
  const shop = new URLSearchParams(search).get('shop');
  const code = new URLSearchParams(search).get('code'); */
  //const { shop,code } = useParams();
  
  const [count, setCount] = useState([]);
  const [refresh,setrefresh] = useState(true)
  useEffect(() => {
    setrefresh(false)

    axios.get(`${baseURL}/productList?shop=${localStorage.getItem("shop")}&code=${localStorage.getItem("code")}`).then((res)=>{
      console.log("Response data",res.data.products)
      setCount(res.data.products)
      setrefresh(true)
    })
    .catch((err)=>{
      console.log(err)
    })
    
  },[]); 
  const clickOnRefresh = ()=>{
    setrefresh(false)
    axios.get(`${baseURL}/productList?shop=${localStorage.getItem("shop")}&code=${localStorage.getItem("code")}`).then((res)=>{
      console.log("Response data",res.data.products)
      setCount(res.data.products)
      setrefresh(true)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  console.log("Name",localStorage.getItem("shop"),localStorage.getItem("code"))
  return (
  
    <div className="App"> 
      <Layout status="2">
        {refresh ? (
        <div>
      <hr/>
      <Row>
        <Col lg={10}>
      <div>
        <Row>
            <div className='d-flex justify-content-start'><svg xmlns="https://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" className="bi bi-tag-fill text-dark" viewBox="0 0 16 16">
  <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
</svg>
            <div className='mx-1'>
            <Row>
                <Col>My Products</Col>
            </Row>
            <Row>
                <Col>
                <SplitButton
            as={ButtonGroup}
            key="1"
            id={`dropdown-button-drop`}
            size="sm"
            variant="secondary"
            title="Active"
          >
            <Dropdown.Item eventKey="1">Active</Dropdown.Item>
            <Dropdown.Item eventKey="2">Disable</Dropdown.Item>
          </SplitButton>
          </Col>
            </Row>
            </div>
            </div>
        </Row>
      </div>
      </Col>
      <Col>
        <div className='my-2'>
      
      <Button variant="secondary" size="sm" className='mx-1'>
          Sync Products
        </Button>
        <Button variant="secondary" size="sm">
          Export
        </Button>
        </div>
     
      </Col>
      </Row>
      <Row className="my-2">
        <Col lg={9}><div className='h6' style={{fontSize:"15px"}}>223 Products. Updated 20 Minutes ago.</div></Col>
        <Col><div className='d-flex justify-content-start'>
        <Form className='mx-1'>
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
          </Form>
            <Button onClick={clickOnRefresh} variant="light" size="sm" className='mx-1'><svg xmlns="https://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
  <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
</svg></Button><Button onClick={clickOnRefresh} variant="light" size="sm"><svg xmlns="https://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
</svg></Button><Button onClick={clickOnRefresh} variant="light" size="sm" className='mx-1'><svg xmlns="https://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-filter-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm.5 5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1zM4 8.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm2 3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5z"/>
</svg></Button></div></Col>
      </Row>
      <hr/>
      <Form>
        <div style={{backgroundColor:"#FDFEFE"}}>
      <Table striped bordered hover >
      <thead>
        <tr>
          <th><Form.Check 
            type="checkbox"
            id={`default-checkbox`}
            label=""
          /></th>
          <th>#</th>
          <th></th>
          <th>Product</th>
          <th>Product ID</th>
          <th>Status</th>
          <th>Type</th>
          <th>Vendor</th>

        </tr>
      </thead>
      <tbody>
   
        {  count.map((item,i)=>{
          return (
        <tr key={i}>
          <td><Form.Check 
            type="checkbox"
            id={`default-checkbox-${i}`}
            label=""
          /></td>
          <td>{i+1}</td>
          <td><center><img
              alt=""
              src={item.image.src}
              width="30"
              height="30"
              
            /></center></td>
          <td>{item.title}</td>
          <td>{item.id}</td>
          <td>{item.status}</td>
          <td>{item.product_type==""?"Not Specified":item.product_type}</td>
          <td>{item.vendor}</td>





        </tr>
          )
        })
      }
         
      </tbody>
    </Table>
    </div>
    </Form>
    </div>
      ):(<center><div className="spinner-border my-5" role="status">
      <span className="sr-only"></span>
    </div></center>)}
        
   
   



      
      
    </Layout>
    </div>
   
  );
}

export default ProductPage;
