import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Layout from "../Layout/Layout.js";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import RecordVideo from "../RecordVideo.js"
import { Pagination } from 'antd';
import { Drawer, Button } from 'antd';
import { Input, Radio, Space } from 'antd';
import { DatePicker } from 'antd';
import baseURL from "../Config/configFile.js";
import { Flex } from 'antd';
import { Checkbox } from 'antd';
import { Select } from 'antd';
import { Tag } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;

const color = ["#f50", "#2db7f5", "#87d068", "#108ee9"]


function VideoList() {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([])
  const [spinner, setspinner] = useState(true)
  const [page, setpage] = useState(1)
  const [limit, setlimit] = useState(10)
  const [total, setTotal] = useState(0)
  const [checkedOrderArray, setcheckedOrderArray] = useState([]);
  const [checkedAllOrderArray, setcheckedAllOrderArray] = useState([]);
  const [sortByOrderId, setsortByOrderId] = useState(-1);
  const [sortByOrderIdValueToAPI, setsortByOrderIdValueToAPI] = useState("created_at");
  const [orderIndicator, setorderIndicator] = useState(true);
  const [orderIndicator2, setorderIndicator2] = useState(true);
  const [orderIndicator3, setorderIndicator3] = useState(true);
  const [orderIndicator4, setorderIndicator4] = useState(true);
  const [orderIndicator5, setorderIndicator5] = useState(true);


  const [filterFulfillment, setfilterFulfillment] = useState("")
  const [filterTags, setfilterTags] = useState([])

  const [valueFilterByFromDate, setvalueFilterByFromDate] = useState("");
  const [valueFilterByToDate, setvalueFilterByToDate] = useState("");
  const [valueFilterByDate, setvalueFilterByDate] = useState(0);
  const [open, setOpen] = useState(false);
  const [clickOnDateFilter, setclickOnDateFilter] = useState(false);
  const [clickOnFulfimentFilter, setclickOnFulfilmentFilter] = useState(false);
  const [clickOnTags, setclickOnTags] = useState(false);
  localStorage.setItem("TagsValue", JSON.stringify(filterTags))




  const onChangeTags = (checkedValues) => {
    console.log('tags value = ', checkedValues);
    setfilterTags(checkedValues)
    localStorage.setItem("TagsValue", JSON.stringify(filterTags))
  };
  const optionsTags = [
    {
      label: 'WhatsApp_COD_Confirmed',
      value: 'WhatsApp_COD_Confirmed',
    },
    {
      label: 'WhatsApp_COD_Cancelled',
      value: 'WhatsApp_COD_Cancelled',
    },
    {
      label: 'WhatsApp_COD_ContactSupport',
      value: 'WhatsApp_COD_ContactSupport',
    },
    {
      label: 'vajro_mobile_app',
      value: 'vajro_mobile_app',
    },
    {
      label: 'android',
      value: 'android',
    },
  ];
  const onChangeSortFullfilled = (e) => {
    console.log(`value- ${e.target.value}`);
    setfilterFulfillment(e.target.value);
    localStorage.setItem("fulfillmentStatusFilter", filterFulfillment)
  };
  const onChangeTagsState = (e) => {
    console.log(`value- ${e.target.value}`);
    setfilterFulfillment(e.target.value);
    localStorage.setItem("fulfillmentStatusFilter", filterFulfillment)
  };
  const onChangeCustomDate = (x, y) => {
    console.log("x", x, " y", y)
    console.log("x ", x[0].toISOString())
    setvalueFilterByToDate(x[1].toISOString())
    setvalueFilterByFromDate(x[0].toISOString())
    localStorage.setItem("fromDate", valueFilterByFromDate)
    localStorage.setItem("ToDate", valueFilterByToDate)

  }
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setvalueFilterByDate(e.target.value)
    if (e.target.value == 1) {
      let d = new Date();
      console.log('Today is: ' + d.toISOString());
      setvalueFilterByToDate(d.toISOString())
      d.setDate(d.getDate() - 1);

      console.log('1 days ago was: ' + d.toISOString());
      setvalueFilterByFromDate(d.toISOString())
      localStorage.setItem("fromDate", valueFilterByFromDate)
      localStorage.setItem("ToDate", valueFilterByToDate)

    }
    else if (e.target.value == 2) {
      let d = new Date();
      console.log('Today is: ' + d.toISOString());
      setvalueFilterByToDate(d.toISOString())

      d.setDate(d.getDate() - 7);
      console.log('7 days ago was: ' + d.toISOString());
      setvalueFilterByFromDate(d.toISOString())
      localStorage.setItem("fromDate", valueFilterByFromDate)
      localStorage.setItem("ToDate", valueFilterByToDate)

    }
    else if (e.target.value == 3) {
      let d = new Date();
      console.log('Today is: ' + d.toISOString());
      setvalueFilterByToDate(d.toISOString())

      d.setDate(d.getDate() - 30);
      console.log('30 days ago was: ' + d.toISOString());
      setvalueFilterByFromDate(d.toISOString())
      localStorage.setItem("fromDate", valueFilterByFromDate)
      localStorage.setItem("ToDate", valueFilterByToDate)

    }
    else if (e.target.value == 4) {
      let d = new Date();
      console.log('Today is: ' + d.toISOString());
      setvalueFilterByToDate(d.toISOString())

      d.setDate(d.getDate() - 90);
      console.log('90 days ago was: ' + d.toISOString());
      setvalueFilterByFromDate(d.toISOString())
      localStorage.setItem("fromDate", valueFilterByFromDate)
      localStorage.setItem("ToDate", valueFilterByToDate)

    }
    else if (e.target.value == 5) {
      let d = new Date();
      console.log('Today is: ' + d.toISOString());
      setvalueFilterByToDate(d.toISOString())

      d.setDate(d.getDate() - 365);
      setvalueFilterByFromDate(d.toISOString())

      console.log('365 days ago was: ' + d.toISOString());
      localStorage.setItem("fromDate", valueFilterByFromDate)
      localStorage.setItem("ToDate", valueFilterByToDate)
    }

  };
  const onOk = (value) => {
    console.log('onOk: ', value);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [openFullfillment, setopenFullfillment] = useState(false);
  const [openTags, setopenTags] = useState(false);

  const showDrawerFullfillment = () => {
    setopenFullfillment(true);
  };
  const showDrawerTags = () => {
    setopenTags(true);
  };
  const onCloseFullfillment = () => {
    setopenFullfillment(false);
  };
  const onCloseTags = () => {
    setopenTags(false);
  };
  useEffect(() => {
    console.log("checked array");
    console.log(checkedOrderArray)
  }, [checkedOrderArray]);
  const clickOnSortByOrderID = (key) => {
    setsortByOrderIdValueToAPI(key);
    if (sortByOrderId == -1) {
      setsortByOrderId(1);
    }
    else {
      setsortByOrderId(-1);
    }
  };
  const handleOrderCheckBox = (e, orderNumber) => {
    var newArr = [...checkedOrderArray];
    if (newArr.indexOf(orderNumber) != -1) {
      newArr.splice(newArr.indexOf(orderNumber), 1);
      setcheckedOrderArray(newArr);
    } else {
      newArr.push(orderNumber);
      setcheckedOrderArray(newArr);
    }
    console.log(checkedOrderArray);
  };
  const handleTickAll = () => {
    if (checkedAllOrderArray.length == 0) {
      var newArr = [];
      orderList.forEach((item) => {
        newArr.push(item.orderDetail.name);
      });
      setcheckedOrderArray(newArr);
      setcheckedAllOrderArray(newArr);

    } else {
      setcheckedOrderArray([]);
      setcheckedAllOrderArray([]);

    }
  };
  const onPaginationChange = (page, limit) => {
    setpage(page)
    setlimit(limit)


    console.log("Page-", page, " Limit-", limit)
  }
  const GotoOrderDetailsPage = (orderID) => {
    navigate("/RecordingDetails", { replace: false, state: { id: orderID } });
  };
  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
  useEffect(() => {
    if (reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);
  const onCLickOnOrderList = (item) => {
    GotoOrderDetailsPage(item.replace(/[^a-zA-Z0-9]/g, ''))
  }
  useEffect(() => {
    console.log("Updated");
    setspinner(true)
    axios
      .get(
        `${baseURL}/getRecorderOrderList?shop=${localStorage.getItem(
          "shop"
        )}&page=${page}&limit=${limit}&sort=orderDetail.${sortByOrderIdValueToAPI}&sortType=${sortByOrderId}&fulfillmentFilter=${filterFulfillment}&fromDate=${valueFilterByFromDate}&ToDate=${valueFilterByToDate}&tags=${localStorage.getItem("TagsValue")}`
      )
      .then((res) => {
        console.log("Response data", res.data);
        setOrderList(res.data.orderNumber)
        setspinner(false)
        setTotal(res.data.total)
        //setproductID(res.data.staff)
        /*   productDetailsfun(res.data.orders) */
      })
      .catch((err) => {
        console.log("err", err)
        setspinner(false)
      })



  }, [page, limit, sortByOrderId, sortByOrderIdValueToAPI, clickOnDateFilter, clickOnFulfimentFilter, clickOnTags])
  const [orderSerach, setorderSerach] = useState("");
  /* localStorage.setItem("shop",shop)
  localStorage.setItem("code",code) */
  const myFunction = () => {
    // your logic here
    console.log('pressed Enter');
  };
  function searchItems() {
    if (orderSerach != "" || orderSerach != " " || orderSerach != "  " || orderSerach != "   ") {
      GotoOrderDetailsPage(orderSerach.replace(/[^a-zA-Z0-9]/g, ''))
    }

  }
  const handleDownloadAll = async () => {
    if (checkedOrderArray.length != 0) {
      const responseOrder_number = await axios.post(
        `${baseURL}/downloadAllS3Documents`, { orderId: checkedOrderArray }
      )
      console.log("OrderNumber", responseOrder_number.data.recordingDetails)

      for (var i = 0; i < responseOrder_number.data.recordingDetails.length; i++) {
        console.log("array", responseOrder_number.data.recordingDetails[i])
        var name = responseOrder_number.data.recordingDetails[i]


        if (name.split(".")[0] == 'vi') {
          const response = await axios({
            url: `${baseURL}/images/${responseOrder_number.data.recordingDetails[i]}`,
            method: "GET",
            responseType: "blob" // important
          })

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          console.log("array", name)
          link.setAttribute(
            "download", name.split(".")[2] + "_" +
            new Date().toString() + '.mp4'
          );
          document.body.appendChild(link);
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
        else {
          const recordingResponceData = await axios.post(`${baseURL}/getRecordingDetailsWithFileName`, { fileName: responseOrder_number.data.recordingDetails[i] })
          if (recordingResponceData.data.isExist) {
            const img = new Image();
            img.src = `${baseURL}/images/${responseOrder_number.data.recordingDetails[i]}`;
            var urlsend = ""
            await img.decode();
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            // Add watermark text
            ctx.font = "bold 40px Arial";
            ctx.fillStyle = 'rgba(255, 255, 255, 1)';
            ctx.fillText("By- " + recordingResponceData.data.recordingData.staffName, 20, canvas.height - 50);
            ctx.fillText("Time- " + recordingResponceData.data.recordingData.created_At, canvas.width - (((canvas.width) / 100) * 40), canvas.height - 50);

            // Set watermarked image URL

            const link = document.createElement("a");
            link.href = canvas.toDataURL();;
            link.setAttribute(
              "download", name.split(".")[2] + "_" +
              new Date().toString() + '.png'
            );
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          }
          else {
            console.log("Data Not Available")
          }
        }

        /* var tempLink = document.createElement('a');
        tempLink.href = `${baseURL}/images/${checkedOrderArray[i]}`
        tempLink.setAttribute('download',new Date().toString()+'.png');
        tempLink.click() */
      }
    }
    else {
      swal({
        title: "No item selected",
        text: "Please select atleast one item",
        icon: "warning",
        button: "Done",
      });
    }

  }
  const deleteS3Video = (item) => {

    if (item.length != 0) {
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios.post(`${baseURL}/deleteAllS3Video`, { orderId: item })
            .then((res) => {
              if (res.data.isDeleted == true) {
                setspinner(true)
                axios
                  .get(
                    `${baseURL}/getRecorderOrderList?shop=${localStorage.getItem(
                      "shop"
                    )}&page=${page}&limit=${limit}&sort=orderDetail.${sortByOrderIdValueToAPI}&sortType=${sortByOrderId}`
                  )
                  .then((res) => {
                    console.log("Response data", res.data);
                    setOrderList(res.data.orderNumber)
                    setspinner(false)
                    setTotal(res.data.total)
                    //setproductID(res.data.staff)
                    /*   productDetailsfun(res.data.orders) */
                  })
                  .catch((err) => {
                    console.log("err", err)
                    setspinner(false)
                  })
              }
              else {

              }
            })
            .catch((error) => {
              console.log("error", error)
            })
        }
      })
    }
    else {
      swal({
        title: "No item selected",
        text: "Please select atleast one item",
        icon: "warning",
        button: "Done",
      });
    }

  }
  /*  useEffect(() => {
     const keyDownHandler = event => {
       //console.log('User pressed: ', event.key);
 
       if (event.key === 'Enter') {
         event.preventDefault();
 
         // 👇️ your logic here
         myFunction();
       }
     }
       document.addEventListener('keydown', keyDownHandler);
 
     return () => {
       document.removeEventListener('keydown', keyDownHandler);
     };
     
    
   }, []); */
  useEffect(() => {
    axios
      .post(`${baseURL}/verfyToken`, {
        token: localStorage.getItem("token")
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
        }
        else {
          navigate(`/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])
  const clearAllFilters = () => {
    setfilterFulfillment("")
    localStorage.setItem("fulfillmentStatusFilter", "")
    setvalueFilterByToDate("")
    setvalueFilterByFromDate("")
    localStorage.setItem("fromDate", "")
    localStorage.setItem("ToDate", "")
    setfilterTags([])
    localStorage.setItem("TagsValue", JSON.stringify([]))
    setclickOnTags(!clickOnTags)
    console.log("cleared")

  }
  return (
    <div className="App">
      <Layout status="8">
        {/* <RecordVideo shop={shop} /> */}
        {/*  <hr/>
       <h5>Search your recorded video by giving or scanning order number</h5> */}
        <hr />
        {spinner ? (<center><div className="spinner-border m-3" role="status">
          <span className="visually-hidden">Loading...</span>
        </div></center>) : (
          <div>
            <div className="d-flex justify-content-between">
              <Form
                className="mx-1"
                onSubmit={(e) => {
                  searchItems();
                  e.preventDefault();
                }}
              >
                <Form.Control
                  autoFocus
                  type="text"
                  value={orderSerach}
                  placeholder="Scan order with barcode"
                  className="me-2"
                  aria-label="Search"
                  onChange={(e) => setorderSerach(e.target.value)}
                  style={{ width: "450px" }}
                />
              </Form>
              <Flex wrap="wrap" gap="small">
                <Button onClick={showDrawer} style={{ padding: "5px", marginBottom: "10px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                  </svg> by Date
                </Button>
                <Button onClick={showDrawerFullfillment} style={{ padding: "5px", marginBottom: "10px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                  </svg> by Fullfilment Status
                </Button>
                <Button onClick={showDrawerTags} style={{ padding: "5px", marginBottom: "10px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                  </svg> by Tags
                </Button>
                <Button onClick={clearAllFilters} danger style={{ padding: "5px", marginBottom: "10px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                  </svg> Clear All Filters
                </Button>
              </Flex>
            </div>
            {/*  <hr/> */}
            {checkedOrderArray.length != 0 ? (
              <Flex wrap="wrap" gap="small">
                <Button variant="primary" className='btn btn-primary my-2' style={{ width: "100px", height: "38px" }} onClick={() => {
                  handleDownloadAll()
                }}>Download</Button>
                <Button variant="danger" className='btn btn-danger my-2' style={{ width: "100px", height: "38px" }} onClick={() => {
                  deleteS3Video(checkedOrderArray)
                }}>Delete</Button>
              </Flex>) : ""}
            <hr />

            <Drawer title="Filter by Date" placement="right" onClose={onClose} open={open}>
              <div>
                <Radio.Group onChange={onChange} value={valueFilterByDate}>
                  <Space direction="vertical">
                    <Radio value={1}>Today</Radio>
                    <Radio value={2}>Last 7 days</Radio>
                    <Radio value={3}>Last 30 days</Radio>
                    <Radio value={4}>Last 90 days</Radio>
                    <Radio value={5}>Last 365 days</Radio>

                    <Radio value={6}>
                      Custom
                      {valueFilterByDate === 6 ? (
                        <Space direction="vertical" size={12}>

                          <RangePicker
                            showTime={{
                              format: 'HH:mm',
                            }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={onChangeCustomDate}
                            onOk={onOk}
                          />
                        </Space>
                      ) : null}
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
              <br />
              <Button type="primary" onClick={() => { setclickOnDateFilter(!clickOnDateFilter); onClose() }}>Apply</Button>
            </Drawer>
            <Drawer title="Filter by Fullfilent Status" placement="right" onClose={onCloseFullfillment} open={openFullfillment}>
              <div>
                <Radio.Group onChange={onChangeSortFullfilled} value={filterFulfillment}>
                  <Space direction="vertical">
                    <Radio value={"fulfilled"}>Fulfilled</Radio>
                    <Radio value={"unfulfilled"}>Unfulfilled</Radio>
                    <Radio value={"Partially Fulfilled"}>Partially Fulfilled</Radio>
                    <Radio value={"Scheduled"}>Scheduled</Radio>
                    <Radio value={"On Hold"}>On Hold</Radio>
                    <Radio value={"Request Declined"}>Request Declined</Radio>
                  </Space>
                </Radio.Group>


              </div>
              <br />
              <Button type="primary" onClick={() => { setclickOnFulfilmentFilter(!clickOnFulfimentFilter); onCloseFullfillment() }}>Apply</Button>
            </Drawer>
            <Drawer title="Filter by Tags" placement="right" onClose={onCloseTags} open={openTags}>
              <div>
                {/*  <Checkbox.Group options={optionsTags} onChange={onChangeTags} /> */}
                <Select
                  mode="tags"
                  style={{
                    width: '100%',
                  }}
                  value={filterTags}
                  placeholder="Tags Mode"
                  onChange={onChangeTags}
                  options={optionsTags}
                />
              </div>
              <br />
              <Button type="primary" onClick={() => { setclickOnTags(!clickOnTags); onCloseTags() }}>Apply</Button>
            </Drawer>
            <br></br>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col"><Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    label=""
                    onClick={handleTickAll}
                  /></th>
                  <th scope="col">#</th>
                  <th scope="col" onClick={() => {
                    clickOnSortByOrderID("name");
                    setorderIndicator(!orderIndicator);
                  }}>Order List {orderIndicator ? (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-up text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-down text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                      />
                    </svg>
                  )}</th>
                  <th scope="col" onClick={() => {
                    clickOnSortByOrderID("financial_status");
                    setorderIndicator2(!orderIndicator2);
                  }}>Financial Status {orderIndicator2 ? (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-up text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-down text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                      />
                    </svg>
                  )}</th>
                  <th scope="col" onClick={() => {
                    clickOnSortByOrderID("fulfillment_status");
                    setorderIndicator3(!orderIndicator3);
                  }}>Fulfillment Status {orderIndicator3 ? (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-up text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-down text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                      />
                    </svg>
                  )}</th>
                  <th scope="col" onClick={() => {
                    clickOnSortByOrderID("tags");
                    setorderIndicator5(!orderIndicator5);
                  }}>Tags {orderIndicator5 ? (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-up text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-down text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                      />
                    </svg>
                  )}</th>
                  <th scope="col" onClick={() => {
                    clickOnSortByOrderID("created_at");
                    setorderIndicator4(!orderIndicator4);
                  }}>Created At {orderIndicator4 ? (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-up text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-down text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                      />
                    </svg>
                  )}</th>
                </tr>
              </thead>
              <tbody>
                {orderList.map((item, index) => {
                  return (
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          id={`default-checkbox-1-${index}`}
                          label=""
                          checked={
                            checkedOrderArray.indexOf(item.orderDetail.name) ==
                              -1
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            handleOrderCheckBox(e, item.orderDetail.name);
                          }}
                        />
                      </td>
                      <th scope="row" onClick={() => onCLickOnOrderList(item.orderDetail.name)}>{((parseInt(page) - 1) * parseInt(limit)) + index + 1}</th>
                      <td onClick={() => onCLickOnOrderList(item.orderDetail.name)}>{item.orderDetail.name}</td>
                      <td onClick={() => onCLickOnOrderList(item.orderDetail.name)}>{item.orderDetail.financial_status}</td>
                      <td onClick={() => onCLickOnOrderList(item.orderDetail.name)}>{item.orderDetail.fulfillment_status == null ? "Unfulfilled" : item.orderDetail.fulfillment_status}</td>
                      <td onClick={() => onCLickOnOrderList(item.orderDetail.name)}>
                        {item.orderDetail.tags.split(",").map((item, index) => {
                          return (
                            <Tag key={index} color={color[index % 4]}>{item}</Tag>
                          )
                        })}
                        {/* {item.orderDetail.tags} */}</td>
                      <td onClick={() => onCLickOnOrderList(item.orderDetail.name)}>{moment(item.orderDetail.created_at).format("DD MMM YYYY, hh:mm A")}</td>
                    </tr>
                  )
                })}


              </tbody>

            </table>
            <div className="my-2">
              <Pagination
                total={parseInt(total)}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                defaultPageSize={limit}
                defaultCurrent={page}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        )}

      </Layout>
    </div>
  );
}

export default VideoList;
