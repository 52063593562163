import React, { useState,useEffect  }  from 'react';
import {useLocation,useParams} from "react-router-dom";
import { Button, Row,Col } from 'react-bootstrap';
import Layout from "./Layout/Layout.js"
import axios from "axios"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import pickpackgowelcome from "./pickpackgowelcome.png"
import baseURL from "./Config/configFile.js";
import { useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate();
  const { shop,code } = useParams();
  const [refresh, setrefresh] = useState(false);
  useEffect(()=>{
    navigator.permissions.query({ name: "camera" }).then(res => {
      if(res.state == "granted"){
      }
      else
      {
        navigator.mediaDevices
      .getUserMedia({ video: {width: 1920, height: 1080 }, audio: true})
      console.log("Permission Granted")
      }
    }
    
    )
  },[])
  useEffect(()=>{

    async function GetToken(){
      setrefresh(false);
    var responseData=await axios
          .post(`${baseURL}/generateToken`, {
            email:shop,
            password:code
          })
          
            console.log("Response data", responseData.data);
            localStorage.setItem("token",responseData.data.token)
            localStorage.setItem("shop",shop)
              localStorage.setItem("code",code)
              localStorage.setItem("orderFields","order_number,total_price,financial_status,fulfillment_status,created_at,number,name,tags,phone,id,app_id")
              localStorage.setItem("staffId","ADMIN_ID")
              localStorage.setItem("staffName","ADMIN CONTROL")
              localStorage.setItem("workingStatus",1)
              setrefresh(true);
        }
        GetToken()
  },[])
  return (
    <div className="App"> 
     {refresh ? (
      <Layout>
    <div>
    <h1>Welcome to PickPackGo!</h1>
    <img
              alt=""
              src={pickpackgowelcome}
              width="350px"
              height="250px"
              className="d-inline-block align-top"
            />
    <p className='my-2'>
      We are here to manage your store in effective and smart way, Our app with provide you different different functionality like auto assignment,
      Magic Assignment, Assign order to your picker,packer, Add location in products and lots of services.
    </p>
    <p>
      <Button bsStyle="primary">Thanks for joining</Button>
    </p>
</div>
  </Layout>
  ) : (
          <center>
            <div className="spinner-border my-5" role="status">
              <span className="sr-only"></span>
            </div>
          </center>
        )}
  </div>
  )
}

export default App
