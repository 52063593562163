import React, { useState,useEffect  }  from 'react';
import {useLocation,useParams} from "react-router-dom";
import { Button, Row,Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import Layout from "../Layout/Layout.js"
import axios from "axios"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import Badge from 'react-bootstrap/Badge';
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import baseURL from "../Config/configFile.js";
import "./main.css"
import moment from "moment"
import { Tag } from 'antd';
import BarCmp from './BarCmp.js'
const color = ["#f50","#2db7f5","#87d068","#108ee9"]


function PickListByProduct() {
    const location = useLocation()
  const [orderDetails, setorderDetails] = useState([]);
  const [completeOrderList, setcompleteOrderList] = useState(location.state.completeOrderList);
  const [productDetails, setproductDetails] = useState([]);
  const [refresh, setrefresh] = useState(true);

  const productDetailsfun = async(productID) => {
        console.log("Updated");

        var res= await axios.get(
            `${baseURL}/getOrdersProductDetails?shop=${localStorage.getItem(
              "shop"
            )}&code=${localStorage.getItem("code")}&productID=${productID}`
          )
          
          var resMetaField= await axios.get(
            `${baseURL}/getProductMetaField?shop=${localStorage.getItem(
              "shop"
            )}&code=${localStorage.getItem("code")}&productID=${productID}`
          )
            console.log("product",res.data.product[0].image.src)
            console.log("resMetaField",resMetaField.data)
            return {
              product_location:resMetaField.data.location,
              src:res.data.product[0].image.src
            }
          
      }
      const countCustomerOrderInPast = async(customerID) => {
        console.log("Updated");

        var res= await axios.get(
            `${baseURL}/getCountOfOrdersBySpecificCustomer?shop=${localStorage.getItem(
              "shop"
            )}&customerID=${customerID}`
          )
          console.log("data",res.data)
          if(res.data.statusCode==200)
          {
            return res.data.count
          }
          else
          {
            return 0
          }
            
          
      }
      useEffect(() => {
        console.log("Updated");
      async function myfunction(){
        setrefresh(false)
        var allData=location.state.completeOrderList
       for(var i=0;i<allData.length;i++){
          
          var data=await productDetailsfun(allData[i].product_id)
          allData[i].src=data.src
          allData[i].product_location=data.product_location
       }
       console.log(allData)
       setcompleteOrderList(allData)
       setrefresh(true)
      }
      myfunction()
          
      }, [])
  return (
    <PrintProvider>
     <NoPrint>
     {refresh ? (
    <div className="App"> 
     
      {/* <Layout> */}
      <Button style={{margin:"10px"}} variant="primary" onClick={() => window.print()}>
          Print PickList
        </Button>
      {refresh ? (
          <Print>
    <div>


       
                  
                    <Card style={{ width: 'auto',margin:"10px"}} className="article">
      <Card.Body>
<center><img src="https://cdn.shopify.com/s/files/1/0088/0056/7392/files/MakerBazar_Dark_b70a2c68-fb46-427d-b0e3-d549e98ff8a0.png?9330" style={{width:"190px",height:"40px",marginBottom:"20px"}}></img></center>
<div className='d-flex justify-content-between'> 
<div>
<div className='d-flex content-justify-start my-0'><p className='my-0'><b>Printed on {moment().format('YYYY-MM-DD, h:mm:ss a')}</b></p></div>
<br/>
</div>
</div>
        


    <Card style={{ width: 'auto' }}>
      <Card.Body>
        <Card.Title></Card.Title>
          <Table bordered hover>
    <thead>
      <tr>
        <th style={{width:"20px"}}>
          <b>Qty</b>
        </th>
        <th>
          <b>Image</b>
        </th>
        <th style={{width:"250px"}}>
          <b>SKU</b>
        </th>
        <th>
          <b>Order No.</b>
        </th>
        <th>
          <b>Item</b>
        </th>
        <th>
          <b>Weight</b>
        </th>
        <th>
          <b>Price</b>
        </th>
        </tr>
    </thead>
    <tbody>
    {
            completeOrderList.map((item,index)=>{
                return(
                    
        <tr key={index} style={{ cursor: "pointer" }}>
          <td style={{width:"20px"}}><b>{item.quantity} ×</b></td>
          <td><img src={item.src}  width="50px" height="50px"></img></td>
          <td style={{width:"250px"}}><div>
            <div className='d-flex flex-wrap'>
            <h6>{item.sku}</h6>
            <h6>~ &nbsp;{item.variant_title}</h6>
            
            </div>
            
        
        {/* <h6 className='barcode-one-two-eight my-0 p-0'>{item.sku}</h6> */}
        <BarCmp code={item.sku} />

        <h6 className='fs-6'>&nbsp;Location: {item.product_location}</h6>
        </div></td>
        <td className='fs-6'>{item.order.map((order,i)=>{ return (<b>{order}{item.order.length>i+1?",":""}&nbsp;</b>) })}</td>

        <td className='fs-6'><b>{item.name}</b></td>
        <td className='fs-6'><b>{parseFloat((item.grams*item.quantity)/1000)} kg</b></td>
        <td className='fs-6'><b>{item.price}</b></td>
        </tr>
                    )
                  })}
                  </tbody>
                  </Table>
      </Card.Body>
    </Card>
    </Card.Body>
    </Card>
                


  


</div>
</Print>
  ) : (
    <center>
      <div className="spinner-border my-5" role="status">
        <span className="sr-only"></span>
      </div>
    </center>
  )}
 {/*  </Layout> */}
  </div>
   ) : (
    <center>
      <div className="spinner-border my-5" role="status">
        <span className="sr-only"></span>
      </div>
    </center>
  )}
  </NoPrint>
  </PrintProvider>
  
  )
}

export default PickListByProduct
