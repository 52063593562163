import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import pickpacklogo from "../pickpacklogo.png"
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button, Flex } from 'antd';

function HeaderForView() {
  return (
    <div style={{position: "sticky",
    top: "0",backgroundColor:"#F2F3F4",zIndex:1000}}>
      <Flex wrap="wrap" gap="small" justify="space-between">

        <Navbar collapseOnSelect expand="lg" variant="dark" className="mb-2" style={{backgroundColor:"#F2F3F4", marginLeft:"0px !important"}}>
        <Container fluid>
        {/* <Navbar.Brand href="#home"> */}
           {/*  <img
              alt=""
              src={pickpacklogo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '} */}
                     <h3 className='text-dark'>Welcome, Pick Pack Go</h3>
         {/*  </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav className="me-auto">
          <Nav.Link href="#features">*/}{/* <Form className="d-flex"> 
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="m-2"
                    aria-label="Search"
                    style={{minWidth:"450px"}}
                  />
                </Form> */}
           {/* </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
      </Navbar>
      <Button type="primary" danger className='my-3'>
      Logout
    </Button>
      </Flex>
    </div>
  )
}

export default HeaderForView