import React, { useState,useEffect  }  from 'react';
import {useLocation,useParams} from "react-router-dom";
import { Button, Row,Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import LayoutView from "../Layout/LayoutView.js"
import axios from "axios"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import Badge from 'react-bootstrap/Badge';
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import baseURL from "../Config/configFile.js";



function OrderDetailsPageView() {
    const location = useLocation()
  const [orderDetails, setorderDetails] = useState([]);
  const [staffArray, setstaffArray] = useState(location.state.staffArray);
  const [productDetails, setproductDetails] = useState([]);
  const [productID, setproductID] = useState("8053628436790");
  const [showAssignNew, setShowAssignNew] = useState(false);
  const [pickerPackerID, setpickerPackerID] = useState("");
  const staffArray1=JSON.parse(localStorage.getItem("staffArray"))


  const [refresh, setrefresh] = useState(false);

  const productDetailsfun = (productID) => {
        console.log("Updated");
        setrefresh(false)

            axios
          .get(
            `${baseURL}/getOrdersProductDetails?shop=${localStorage.getItem(
              "shop"
            )}&code=${localStorage.getItem("code")}&productID=${productID}`
          )
          .then((res) => {
            setproductDetails(res.data.product)
            console.log("product",res.data.product)
        setrefresh(true)
    })
          .catch((err)=>{
            console.log("err",err)
          })
          
      }
      useEffect(() => {
        console.log("Updated");
        setrefresh(false)
        axios
          .get(
            `${baseURL}/getOrderDetails?shop=${localStorage.getItem(
              "shop"
            )}&code=${localStorage.getItem("code")}&orderID=${location.state.id}`
          )
          .then((res) => {
            console.log("Response data", res.data.orders);
            setproductID(res.data.orders.line_items[0].product_id)
            productDetailsfun(res.data.orders.line_items[0].product_id)
            setorderDetails(res.data.orders)
    })
          .catch((err)=>{
            console.log("err",err)
          })
          
      }, [])
      const handleShowAssignNew = () => {
  
          setShowAssignNew(true);
      
      };
      const handleCloseAssgnNew = () => setShowAssignNew(false);
      const handleAllUnassign =()=>{
     /*    setrefresh(false); */
        axios
          .post(`${baseURL}/changeinorderAll`, {
            shopName: localStorage.getItem("shop"),
            shopCode: localStorage.getItem("code"),
            type:"unassigned",
            checkedOrderArray:[location.state.id]
          })
          .then((res) => {
            if (res.data.statusCode == 200) {
            /*   setrefresh(true); */
              swal({
                title: "Order Unassigned",
                text: "Selected order unassigned",
                icon: "success",
                button: "Done",
              });
             /*  clickOnRefresh(); */
            } else {
           /*    setrefresh(true); */
              swal({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                button: "Done",
              });
             /*  clickOnRefresh(); */
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const handleAllToSpec =()=>{
    /*     setrefresh(false); */
        setShowAssignNew(false);
        axios
          .post(`${baseURL}/changeinorderAll`, {
            shopName: localStorage.getItem("shop"),
            shopCode: localStorage.getItem("code"),
            type:"toOther",
            checkedOrderArray:[location.state.id],
            toAssign:pickerPackerID
          })
          .then((res) => {
            if (res.data.statusCode == 200) {
          /*     setrefresh(true); */
              swal({
                title: "Order Assigned",
                text: "Selected order assigned",
                icon: "success",
                button: "Done",
              });
           /*    clickOnRefresh(); */
            } else {
              /* setrefresh(true); */
              swal({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                button: "Done",
              });
             /*  clickOnRefresh(); */
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
  return (
    <div className="App"> 
      <LayoutView>
      {refresh ? (
    <div>
       <Modal show={showAssignNew} onHide={handleCloseAssgnNew}>
            
                <div>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Assign to a specific picker/packer
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Choose to assign order
                    <br />
                    {staffArray1.map((item, i) => {
                      return (
                        <Form.Check
                          type="radio"
                          id={i + 1}
                          name="picker"
                          label={item.first_name + " " + item.last_name}
                          onChange={() => {
                            setpickerPackerID(item.id);
                            console.log("id", pickerPackerID);
                          }}
                        />
                      );
                    })}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAssgnNew}>
                      Discard
                    </Button>
                    <Button variant="primary" onClick={handleAllToSpec}>
                      Apply
                    </Button>
                  </Modal.Footer>
                </div>
            </Modal>
 <div className="my-2">
                
                  {/* <Button
                    variant="danger"
                    size="sm"
                    className="mx-1 my-1"
                    onClick={handleAllUnassign}
                  >
                    Unassign
                  </Button>
            
                  <Button
                    variant="success"
                    size="sm"
                    className="mx-1"
                    onClick={handleShowAssignNew}
                  >
                    Assign to Specific Picker/Packer
                  </Button> */}

                <Button variant="primary" size="sm">
                  Export
                </Button>
              </div>
<Row>
    <Col className='Col-6'><div className='my-2 d-flex'>
<h4>#{orderDetails.order_number}</h4>
<Badge bg="primary mx-2 my-1" style={{height:"20px"}}>
{orderDetails.financial_status.charAt(0).toUpperCase()+orderDetails.financial_status.slice(1)}
      </Badge>
      {orderDetails.fulfillment_status==null?(<Badge bg="warning my-1" style={{height:"20px"}}>
      {"Unfulfilled"}
      </Badge>):( <Badge bg="success my-1" style={{height:"20px"}}>
      {orderDetails.fulfillment_status.charAt(0).toUpperCase()+orderDetails.fulfillment_status.slice(1)}
      </Badge>)}
     
</div>
<div><p>{Date(orderDetails.created_at)}</p></div>
    <Card style={{ width: 'auto' }}>
      <Card.Body>
        <Card.Title></Card.Title>
        <div className="d-flex">
        {orderDetails.fulfillment_status==null?(
        <div><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-reverse text-warning" viewBox="0 0 16 16">
  <path d="M9.854 5.146a.5.5 0 0 1 0 .708L7.707 8l2.147 2.146a.5.5 0 0 1-.708.708L7 8.707l-2.146 2.147a.5.5 0 0 1-.708-.708L6.293 8 4.146 5.854a.5.5 0 1 1 .708-.708L7 7.293l2.146-2.147a.5.5 0 0 1 .708 0z"/>
  <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7.08a2 2 0 0 0 1.519-.698l4.843-5.651a1 1 0 0 0 0-1.302L10.6 1.7A2 2 0 0 0 9.08 1H2zm7.08 1a1 1 0 0 1 .76.35L14.682 8l-4.844 5.65a1 1 0 0 1-.759.35H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h7.08z"/>
</svg></div>):(<div><svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-patch-check-fill my-1 text-success" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
</svg> </div>)}

{orderDetails.fulfillment_status==null?(<Card.Title className="mx-2">
      {"Unfulfilled"}
      </Card.Title>):(<Card.Title className="mx-2">
      {orderDetails.fulfillment_status.charAt(0).toUpperCase()+orderDetails.fulfillment_status.slice(1)}
      </Card.Title>)}
         {/* 
          */}
          </div>
        {/* <Card.Subtitle className="mb-2 text-muted">Fullfillment Status: {orderDetails.fulfillment_status}</Card.Subtitle> */}
      

        <Card.Subtitle className="mb-2 text-muted">Tags : {orderDetails.tags}</Card.Subtitle>
        <hr/>
        <h6>Product Id: {productDetails.id}</h6>
        <div className="d-flex justify-content-between">
            <div className='d-flex '>
                <img src={productDetails.image.src} width="50px" height="50px"></img>
                <div className='mx-3'>{productDetails.title}</div>
            </div>
            <div className='mx-2'><div><b>Qantity</b></div><div className='mx-4'>{orderDetails.line_items[0].quantity}</div></div>
            <div className='mx-2'>{orderDetails.line_items[0].quantity} * {orderDetails.line_items[0].price}₹</div>
            <div className='mx-2'>{parseInt(orderDetails.line_items[0].quantity)*parseInt(orderDetails.line_items[0].price)}₹</div>
            
        </div>
       {/*  <Card.Title>Total Price: {orderDetails.total_price} INR</Card.Title> */}
        {/* <Card.Subtitle className="mb-2 text-muted">Total Discount: {orderDetails.total_discounts} INR</Card.Subtitle> */}
       {/*  <Card.Text>
          Total Products: {orderDetails.line_items.length}
        </Card.Text> */}

      {/*   <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link> */}
      </Card.Body>
    </Card>

    <Card style={{ width: 'auto',marginTop:"20px" }}>
      <Card.Body>
      {/*   <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link> */}
        <div className="d-flex">
        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-patch-check-fill my-1 text-success" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
</svg>
          <Card.Title className="mx-2">{orderDetails.financial_status.charAt(0).toUpperCase()+orderDetails.financial_status.slice(1)}</Card.Title>
          </div>
          <div className="d-flex justify-content-between mt-3">
          <Card.Subtitle className="mb-2 text-muted">Subtotal</Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">{orderDetails.line_items[0].quantity} items</Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">₹{orderDetails.subtotal_price}</Card.Subtitle>
          </div>
          <div className="d-flex justify-content-between mt-1">
          <Card.Subtitle className="mb-2 text-bold">Total</Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
          <Card.Subtitle className="mb-2 text-bold" style={{fontSize:"17px"}}>₹{orderDetails.total_price}</Card.Subtitle>
          </div>
          <hr/>
          <div className="d-flex justify-content-between mt-1">
          <Card.Subtitle className="mb-2 text-bold">Paid by customer</Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted" style={{fontSize:"17px"}}>₹{orderDetails.total_price}</Card.Subtitle>
          </div>


      </Card.Body>
    </Card></Col>
    <Col className='Col-6'> 
    <Row>
        <Col>
        <Card style={{ width: 'auto',marginTop:"20px" }}>
      <Card.Body>
      {/*   <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link> */}
        <div className="d-flex">
        <svg xmlns="https://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-richtext text-success" viewBox="0 0 16 16">
  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
  <path d="M4.5 12.5A.5.5 0 0 1 5 12h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm0-2A.5.5 0 0 1 5 10h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm1.639-3.708 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V8.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V8s1.54-1.274 1.639-1.208zM6.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z"/>
</svg>
          <Card.Title className="mx-2">Notes</Card.Title>
          </div>
          <hr/>
          <div>
            <p>
                {orderDetails.note==null?("No notes from customer"):(orderDetails.note)}
            </p>
          </div>


      </Card.Body>
    </Card>
        </Col>
    </Row>

    <Row>
        <Col>
        <Card style={{ width: 'auto',marginTop:"20px" }}>
      <Card.Body>
      {/*   <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link> */}
        <div className="d-flex">
        <svg xmlns="https://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-circle text-success" viewBox="0 0 16 16">
  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
  <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
</svg>
          <Card.Title className="mx-2">Cutomer</Card.Title>
          </div>
          <hr/>
          <div>
            <h6>
            Contact information
            </h6>
          </div>
          <div>
            <p>
                {orderDetails.contact_email==null?("No email provided"):(orderDetails.contact_email)}
            </p>
          </div>
          <div>
            <p>
                {orderDetails.phone==null?("No phone number"):(orderDetails.phone)}
            </p>
          </div>
          <hr/>
          <div>
            <h6>
            Shipping address
            </h6>
          </div>
          <div>
            <p>
                {orderDetails.shipping_lines.length==0?("No shipping address provided"):(orderDetails.shipping_lines)}
            </p>
          </div>



      </Card.Body>
    </Card>
        </Col>
    </Row>

    
    </Col>

    </Row>

  


</div>
  ) : (
    <center>
      <div className="spinner-border my-5" role="status">
        <span className="sr-only"></span>
      </div>
    </center>
  )}
  </LayoutView>
  </div>
  )
}

export default OrderDetailsPageView
