import React, { useState,useEffect  }  from 'react';
import {useLocation,useParams} from "react-router-dom";
import { Button, Row,Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Layout from "../Layout/Layout.js"
import axios from "axios"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import Badge from 'react-bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import baseURL from "../Config/configFile.js";



function SearchResults() {
    const location = useLocation()
  const [orderDetails, setorderDetails] = useState([]);
  const [staffDetails, setstaffDetails] = useState([]);

  const [productDetails, setproductDetails] = useState([]);
  const [productID, setproductID] = useState("8053628436790");


  const [refresh, setrefresh] = useState(false);
  const navigate = useNavigate();
  const GotoOrderDetailsPage=(orderID)=>{
    navigate('/orderDetails', { replace: false,state:{id:orderID} });
  }
  const GotoAddStaffPage=()=>{
    navigate('/addStaff', { replace: false });
  }

  const productDetailsfun = (productID) => {
        console.log("Updated");
        setrefresh(false)

            axios
          .get(
            `${baseURL}/getOrdersProductDetails?shop=${localStorage.getItem(
              "shop"
            )}&code=${localStorage.getItem("code")}&productID=${productID}`
          )
          .then((res) => {
            setproductDetails(res.data.product)
            console.log("product",res.data.product)
        setrefresh(true)
    })
          .catch((err)=>{
            console.log("err",err)
          })
          
      }
      useEffect(() => {
        console.log("Updated");
        setrefresh(false)
        axios
          .get(
            `${baseURL}/getSearchResultsOrder?shop=${localStorage.getItem(
              "shop"
            )}&code=${localStorage.getItem("code")}&orderID=${location.state.id}`
          )
          .then((res) => {
            console.log("Response data", res.data.orders);
            setproductID(res.data.orders)
          /*   productDetailsfun(res.data.orders) */
            setorderDetails(res.data.orders)
        setrefresh(true)

    })
          .catch((err)=>{
            console.log("err",err)
          })

          
      }, [location.state.id])
      useEffect(() => {
        console.log("Updated");
        setrefresh(false)
        axios
          .get(
            `${baseURL}/getSearchResultsStaff?shop=${localStorage.getItem(
              "shop"
            )}&code=${localStorage.getItem("code")}&staffName=${location.state.id}`
          )
          .then((res) => {
            console.log("Response data", res.data.staff);
            //setproductID(res.data.staff)
          /*   productDetailsfun(res.data.orders) */
          setstaffDetails(res.data.staff)
        setrefresh(true)

    })
          .catch((err)=>{
            console.log("err",err)
          })

          
      }, [location.state.id])
  return (
    <div className="App"> 
      <Layout>
      {refresh ? (
    <div>
<h4>#Search Results</h4>
<Row>
    <Col>
    <Card style={{ width: 'auto' }}>
      <Card.Body>
        <Card.Title><h5>Orders Result</h5></Card.Title>

{orderDetails.length==0?("No Order Found"):(<div className='my-2'>
<Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            No.
                          </th>
                          <th>
                            Order Number
                          </th>
                          </tr>
                          </thead>
                          <tbody>
{orderDetails.map((item,i)=>{
   return ( <tr onClick={()=>{GotoOrderDetailsPage(item.orderDetail.name)}} style={{cursor:"pointer"}}>
<td>{i+1}</td>
<td>{item.orderDetail.name}</td>
   </tr>
   )

})}
</tbody>
</Table>
</div>)}
</Card.Body>
</Card>


    </Col>

    </Row>
   {/*  <Row>
    <Col>
Product Details
{location.state.id}
    </Col>

    </Row> */}

    <Row>
    <Col>
    <Card style={{ width: 'auto' }} className="my-2">
      <Card.Body>
        <Card.Title><h5>Staff Details</h5></Card.Title>
        {staffDetails.length==0?("No Staff Found"):(<div className='my-2'>
<Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            No.
                          </th>
                          <th>
                            Staff Name
                          </th>
                          </tr>
                          </thead>
                          <tbody>
{staffDetails.map((item,i)=>{
   return ( <tr onClick={()=>{GotoAddStaffPage()}} style={{cursor:"pointer"}}>
<td>{i+1}</td>
<td>{item.first_name+" "+item.last_name}</td>
   </tr>
   )

})}
</tbody>
</Table>
</div>)}
        </Card.Body>
        </Card>


    </Col>

    </Row>


  


</div>
  ) : (
    <center>
      <div className="spinner-border my-5" role="status">
        <span className="sr-only"></span>
      </div>
    </center>
  )}
  </Layout>
  </div>
  )
}

export default SearchResults
