import React, { useState, useEffect,useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import Layout from "../Layout/Layout.js";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import RecordVideo from "../RecordVideo.js"
import baseURL from "../Config/configFile.js";


function CameraPage() {
  const navigate = useNavigate();
  const GotoOrderDetailsPage = (orderID) => {
    navigate("/orderDetails", { replace: false, state: { id: orderID } });
  };



  
 /*  localStorage.setItem("shop",shop)
  localStorage.setItem("code",code) */
  const myFunction = () => {
    // your logic here
    console.log('pressed Enter');
  };
  
 /*  useEffect(() => {
    const keyDownHandler = event => {
      //console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ your logic here
        myFunction();
      }
    }
      document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    
   
  }, []); */
  useEffect(()=>{
    axios
      .post(`${baseURL}/verfyToken`, {
       token:localStorage.getItem("token")
      })
      .then((res) => {
        if(res.data.statusCode==200)
        {
        }
        else
        {
          navigate(`/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[])
 
  return (
    <div className="App">
      <Layout status="7">
        <div style={{width:"1000px!important"}}>
        <RecordVideo shop={localStorage.getItem("shop")} />
        </div>
       
      
      </Layout>
    </div>
  );
}

export default CameraPage;
