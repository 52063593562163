import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import pickpacklogo from "../pickpacklogo.png"
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';
import { Button, Flex } from 'antd';
import {Link} from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space } from 'antd';
import { Popover } from 'antd';
const text = <h5>Hi, {localStorage.getItem("staffName")}</h5>;
function Header() {
  const navigate = useNavigate();
  const GotoOrderDetailsPage=(orderID)=>{
    navigate('/orderResults', { replace: false,state:{id:orderID} });
  }
  const logout=()=>{
    localStorage.setItem("token","")
    navigate('/');
  }
  const content = (
    <div>
      <hr/>
      <Button type="primary" style={{width:"100%"}} onClick={()=>{
    navigate(`/resetPassword`);
  }}>
        Reset Password
      </Button><br></br>
      <Button type="primary" danger style={{width:"100%"}} className='my-1' onClick={()=>{
    logout()
  }}>
        Logout
      </Button>
    </div>
  );
const [search,setsearch]=useState("")
const [View,setView]=useState(localStorage.getItem("view")==null?"[]":localStorage.getItem("view"))

  return (
    <div style={{position: "sticky",
      top: "0",backgroundColor:"#F2F3F4",zIndex:1000}}>
      <Flex wrap="wrap" gap="small" justify="space-between">
        <Navbar collapseOnSelect expand="lg" variant="dark" className="mb-2" style={{backgroundColor:"#F2F3F4", marginLeft:"0px !important"}}>
        <Container fluid>
        {/* <Navbar.Brand href="#home" style={{paddingLeft:"0px !important"}}> */}
            {/* <img
              alt=""
              src={pickpacklogo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '} */}
           <h3 className='text-dark'>Welcome, Pick Pack Go</h3>
         
        {/*   </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav className="me-auto">
          <Nav.Link href="#features">*/}
          {JSON.parse(View!="undefined"?View:"[]").indexOf('Global Search')==-1?<div></div>:(<div className="d-flex">
          <Form className="d-flex" onSubmit={(e)=>{
                    e.preventDefault()
                    GotoOrderDetailsPage(search)
                  }}> 
                  <Form.Control 
                    type="search"
                    placeholder="Order Id / Product Id / Staff Name"
                    className="m-2"
                    aria-label="Search"
                    style={{minWidth:"450px"}}
                    onChange={(e)=>{
                      setsearch(e.target.value)
                      console.log("type ",e.target.value)
                    }}
                  />
                </Form>
                <Button type="primary" className="my-3" onClick={()=>{
GotoOrderDetailsPage(search)
                }}
                >Search</Button>
         </div> )}
           {/* </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
      </Navbar>
      <div>
      <div className='d-flex content-justify-start'>
        <Button style={{marginRight:"10px",marginTop:"20px"
      }} danger>{localStorage.getItem("staffName")}</Button>
      
      <Popover placement="bottom" title={text} content={content} trigger="click">
      <Avatar
      style={{
        backgroundColor: '#fde3cf',
        color: '#f56a00',cursor:"pointer",marginRight:"10px",marginTop:"15px",marginLeft:"15px"
      }}
     /*  icon={<UserOutlined />} */ size="large"
    >{(localStorage.getItem("staffName").split(" ")[0]).split("")[0]}
      </Avatar>
        </Popover>
        </div>
        
        </div>
      </Flex>
    </div>
  )
}

export default Header