import React from 'react'
import Header from "./Header.js"
import SideNavBar from "./SideNavBar.js"
import { Col, Row } from 'react-bootstrap';
import UseWindowDimensions from '../dimension/UseWindowDimensions.js'
function Layout({children,status}) {
        const { height, width } = UseWindowDimensions();
        var style = {
                marginLeft:"10px",width:width
        }
  return (
    <div style={{backgroundColor:"#F2F3F4",height:"auto"}}>
       
        <div className="container-fluid">
{/*   <Row>
    <Col className='col-lg-auto col-md-auto col-xl-auto col-sm-auto col-xs-auto'>
        <SideNavBar status={status} />
     
        </Col>
        <Col className='col-lg-11 col-md-11 col-xl-11 col-sm-11 col-xs-11' style={{backgroundColor:"#F2F3F4"}}>
        <Header />
        <main>{children}</main>
        </Col>
        </Row> */}
        <div className='d-flex'>
<SideNavBar status={status} />
<div style={style}>
<Header />
        <main>{children}</main>
</div>
</div>
        </div>

    </div>
  )
}

export default Layout