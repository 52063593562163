import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Layout from "../Layout/Layout.js";
import axios from "axios";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import baseURL from "../Config/configFile.js";

function Settings() {
  const navigate = useNavigate();
  const GotoBarcodePage = (jsonData) => {
    navigate("/barcodePage1", { replaceAll: true, state: { data: jsonData } });
  };
  /* const { inputRef } = useBarcode({
    value: 'createnextapp',
    options: {
      background: '#FFFFFF',
    }
  }); */
  const [videoFileName,setvideoFileName]=useState("")
  const [videoCommand,setvideoCommand]=useState("")

  const [imageFilename1,setimageFilename1]=useState("")
  const [imageCommand1,setimageCommand1]=useState("")
  const [option1,setOption1]=useState("")
  const [option2,setOption2]=useState("")
  const [option3,setOption3]=useState("")
  const [option4,setOption4]=useState("")
  const [option5,setOption5]=useState("")
  const [option6,setOption6]=useState("")
  const [customFilterValue, setcustomFilterValue] = useState("");
  const [pickListGenerationType, setpickListGenerationType] = useState("");

  const [refresh, setrefresh] = useState(false);






  const [imageFilename2,setimageFilename2]=useState("")
  const [imageCommand2,setimageCommand2]=useState("")
  const [imageFilename3,setimageFilename3]=useState("")
  const [imageCommand3,setimageCommand3]=useState("")
  const [imageFilename4,setimageFilename4]=useState("")
  const [imageCommand4,setimageCommand4]=useState("")
  const [imageFilename5,setimageFilename5]=useState("")
  const [imageCommand5,setimageCommand5]=useState("")
  const [imageFilename6,setimageFilename6]=useState("")
  const [imageCommand6,setimageCommand6]=useState("")
  const [imageFilename7,setimageFilename7]=useState("")
  const [imageCommand7,setimageCommand7]=useState("")
  const [imageFilename8,setimageFilename8]=useState("")
  const [imageCommand8,setimageCommand8]=useState("")
  const [imageFilename9,setimageFilename9]=useState("")
  const [imageCommand9,setimageCommand9]=useState("")

 /*  localStorage.setItem("shop",shop)
  localStorage.setItem("code",code) */
  const myFunction = () => {
    // your logic here
    console.log('pressed Enter');
  };
  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
  useEffect(() => {
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);
  useEffect(()=>{
    setrefresh(false)
    axios.post(`${baseURL}/getSettingData`,{store_name:localStorage.getItem("shop")})
    .then((res)=>{
        console.log("data",res.data)
        if(res.data.data.length==0)
        {
    setrefresh(true)
           

        }
        else{
            console.log(res.data.data)
            setvideoCommand(res.data.data.videoCommand)
            setimageCommand1(res.data.data.imageCommand1)
            setOption1(res.data.data.option1)
            setOption2(res.data.data.option2)
            setOption3(res.data.data.option3)
            setOption4(res.data.data.option4)
            setOption5(res.data.data.option5)
            setOption6(res.data.data.option6)
            setcustomFilterValue(res.data.data.globalOrderSearch)
            setpickListGenerationType(res.data.data.pickListGenerationType)
            setrefresh(true)



            /* setimageFilename2(jsonData.imageFilename2) 
            setimageCommand2(jsonData.imageCommand2) 
            setimageFilename3(jsonData.imageFilename3) 
            setimageCommand3(jsonData.imageCommand3) 
            setimageFilename4(jsonData.imageFilename4) 
            setimageCommand4(jsonData.imageCommand4) 
            setimageFilename5(jsonData.imageFilename5) 
            setimageCommand5(jsonData.imageCommand5) 
            setimageFilename6(jsonData.imageFilename6) 
            setimageCommand6(jsonData.imageCommand6) 
            setimageFilename7(jsonData.imageFilename7) 
            setimageCommand7(jsonData.imageCommand7) 
            setimageFilename8(jsonData.imageFilename8) 
            setimageCommand8(jsonData.imageCommand8) 
            setimageFilename9(jsonData.imageFilename9) 
            setimageCommand9(jsonData.imageCommand9)  */
    /* console.log("jsonDate",jsonData) */
        }

    })
    .catch((err)=>{
        console.log("err",err)
    })

  },[])
  const onClickBarcodeButton=()=>{
   /*  onSubmitHandle() */
    var jsonData={}
    jsonData.videoCommand=videoCommand
    jsonData.imageCommand1=imageCommand1
    jsonData.option1=option1
    jsonData.option2=option2
    jsonData.option3=option3
    jsonData.option4=option4
    jsonData.option5=option5
    jsonData.option6=option6


    GotoBarcodePage(jsonData)

  }
  const onSubmitHandle =()=>{
    var jsonData={}

    jsonData.videoCommand=videoCommand

    jsonData.imageCommand1=imageCommand1

    axios.post(`${baseURL}/settingData`,{store_name:localStorage.getItem("shop"),videoCommand:videoCommand,imageCommand1:imageCommand1,option1:option1,option2:option2,option3:option3,option4:option4,option5:option5,option6:option6,globalOrderSearch:customFilterValue,pickListGenerationType:pickListGenerationType})
    .then((res)=>{
        console.log("data",res.data)
        swal({
            title: "Saved",
            text: "File name and commands saved successfully.",
            icon: "success",
            button: "Done",
          });
    })
    .catch((err)=>{
        console.log("err",err)
    })

  }
 
 /*  useEffect(() => {
    const keyDownHandler = event => {
      //console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ your logic here
        myFunction();
      }
    }
      document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    
   
  }, []); */
 

  useEffect(()=>{
    axios
      .post(`${baseURL}/verfyToken`, {
       token:localStorage.getItem("token")
      })
      .then((res) => {
        if(res.data.statusCode==200)
        {
        }
        else
        {
          navigate(`/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[])
  const customFilterDataArraySelectChange = (value) => {
    console.log("Selected Custom filter value", value);
    setcustomFilterValue(value);
  };
  const customPickGenerationSelectChange = (value) => {
    console.log("Selected Custom filter value", value);
    setpickListGenerationType(value);
  };
  const options = [
    {
      label: "Name",
      value: "name",
    },
    {
      label: "ID",
      value: "id",
    },
    {
      label: "Order Number",
      value: "order_number",
    }
  ]
  const optionsPickList = [
    {
      label: "ORDER",
      value: "ORDER",
    },
    {
      label: "PRODUCT",
      value: "PRODUCT",
    },
  ]
  return (
    <div className="App">
      <Layout status="9">
        {refresh?(
       <Form
                  className="mx-1"
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitHandle();
                    
                  }}
                >
                  <div className="d-flex">
                  <h6>Global Order Search field</h6>
                  </div>
               
                  <div className="d-flex">
                  <Select
                defaultValue={customFilterValue}
                style={{
                  width: 250,
                }}
                onChange={customFilterDataArraySelectChange}
                options={options}
              />
              </div>
              <br/>
              <div className="d-flex">
                  <h6>Pick List Generation Type</h6>
                  </div>
               
                  <div className="d-flex">
                  <Select
                defaultValue={pickListGenerationType}
                style={{
                  width: 250,
                }}
                onChange={customPickGenerationSelectChange}
                options={optionsPickList}
              />
              </div>
              <br/>
             
     
       <div className="d-flex">
       <h6>Record video from both camera</h6>

       </div>
       <div className="d-flex">
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setvideoCommand((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={videoCommand}
                    required
                  />

       </div>
       <br/>
      
       <div className="d-flex">
       <h6>Snap pictures from both camera</h6>

       </div>
       <div className="d-flex my-2">
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand1((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand1}
                    required
                  />

       </div>
       {/* Cam 1 recording & Cam 2 clicking */}
       <div className="d-flex">
       <h6>Cam one for recording video & Cam two for clicking images</h6>

       </div>
       <div className="d-flex my-2">
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setOption1((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={option1}
                    required
                  />

       </div>
        {/* Cam 1 clicking & Cam 2 recording */}
        <div className="d-flex">
       <h6>Cam one for clicking images & Cam two for recording video</h6>

       </div>
       <div className="d-flex my-2">
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setOption2((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={option2}
                    required
                  />

       </div>
        {/* only one can for recording and clicking images*/}
        <div className="d-flex">
       <h6>Cam 1 for recording</h6>

       </div>
       <div className="d-flex my-2">
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setOption3((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={option3}
                    required
                  />

       </div>
       <div className="d-flex">
       <h6>Cam 1 for clicking images</h6>

       </div>
       <div className="d-flex my-2">
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setOption4((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={option4}
                    required
                  />

       </div>
       <div className="d-flex">
       <h6>Cam 2 for recording</h6>

       </div>
       <div className="d-flex my-2">
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setOption5((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={option5}
                    required
                  />

       </div>
       <div className="d-flex">
       <h6>Cam 2 clicking images</h6>

       </div>
       <div className="d-flex my-2">
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setOption6((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={option6}
                    required
                  />

       </div>
       
       {/* <div className="d-flex my-2">
       <Form.Control
                    type="text"
                    placeholder="Package_Length"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageFilename2((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageFilename2}
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand2((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand2}
                    required
                  />

       </div>
       <div className="d-flex my-2">
       <Form.Control
                    type="text"
                    placeholder="Package_Width"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageFilename3((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageFilename3}
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand3((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand3}
                    required
                  />

       </div>
       <div className="d-flex my-2">
       <Form.Control
                    type="text"
                    placeholder="Package_Height"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageFilename4((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageFilename4}
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand4((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand4}
                    required
                  />

       </div>
       <div className="d-flex my-2">
       <Form.Control
                    type="text"
                    placeholder="Package_Image_1"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageFilename5((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageFilename5}
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand5((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand5}
                    required
                  />

       </div>
       <div className="d-flex my-2">
       <Form.Control
                    type="text"
                    placeholder="Package_Image_2"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageFilename6((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageFilename6}
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand6((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand6}
                    required
                  />

       </div>
       <div className="d-flex my-2">
       <Form.Control
                    type="text"
                    placeholder="Package_Image_3"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageFilename7((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageFilename7}
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand7((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand7}
                    required
                  />

       </div>
       <div className="d-flex my-2">
       <Form.Control
                    type="text"
                    placeholder="Package_Image_4"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageFilename8((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageFilename8}
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand8((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand8}
                    required
                  />

       </div>
       <div className="d-flex my-2">
       <Form.Control
                    type="text"
                    placeholder="Package_Image_5"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageFilename9((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageFilename9}
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder="Digits"
                    className="me-2"
                    aria-label="Search"
                    style={{width:"250px"}}
                    onChange={(e) => setimageCommand9((e.target.value).replace(/[^a-zA-Z0-9]/g, '_'))}
                    defaultValue={imageCommand9}
                    required
                  />

       </div> */}
       <hr/>
       <div className="d-flex my-2">
       <input className="me-2 btn btn-primary" type="submit" value="Save"/>
       <Button variant="success" className="me-2" onClick={()=>{
        onClickBarcodeButton()
       }}>Generate Barcode</Button>
       </div>
       </Form>
       ) : (
          <center>
            <div className="spinner-border my-5" role="status">
              <span className="sr-only"></span>
            </div>
          </center>
        )}
      </Layout>
    </div>
  );
}

export default Settings;
